import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Button from 'components/ui/Button/Button';

import SaveFormButton from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import taxYearsClient from 'api/taxYearsClient';
import useTaxDocument from './useTaxDocument';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { LoadingSpinner } from 'components/ui/Loading';

const TaxYearConfirm = ({ taxYear, setTaxYear }) => {
  const { id, isManual } = taxYear;

  // const [checked, setChecked] = useState(false);

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxYearsClient.documents({ id });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  const { onListUpdate } = useArrayUpdate(setDocuments);

  const onSave = async () => {
    try {
      const response = await taxYearsClient.confirm({ id });
      const data = response.data;
      setTaxYear(data);
      return true;
    } catch (e) {
      const errors = getServerResponseErrors(e);
      console.log(errors);
    }
  };

  if (!documents) {
    return <LoadingSpinner />;
  }

  return (
    <Box textAlign="center">
      <Box fontSize={20}>
        Please {isManual ? 'upload' : 'review'} your completed Federal and State
        tax documents listed below
      </Box>
      <Box marginTop={2}>
        {documents &&
          documents.map((doc) => (
            <Box key={doc.id} marginTop={2}>
              <TaxDocument
                {...{
                  data: doc,
                  onChange: onListUpdate,
                }}
              />
            </Box>
          ))}
      </Box>

      <Box textAlign="center" marginTop={2}>
        {/* <Box>
          <CustomCheckBox
            name="useBusiness"
            label={
              <Box>
                <Typography variant="subtitle1">
                  I have reviewed the form(s) listed above and they are accurate
                  and complete in their entirety.
                </Typography>
              </Box>
            }
            checked={checked}
            onChange={() => setChecked((x) => (x ? null : new Date()))}
          />
        </Box> */}
        <Box>
          <SaveFormButton
            name="Continue"
            onSave={onSave}
            variant="contained"
            disabled={documents.some((x) => !x.dateApproved)}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TaxDocument = ({ data, onChange }) => {
  const { id, formName, document } = data || {};

  const { modal, showModal, openLink } = useTaxDocument({ id, onChange });
  return (
    <Box>
      {modal}
      <Box margin="auto" maxWidth="30rem">
        <Grid container justifyContent="space-between">
          <Grid xs={6}>
            <Typography variant="h6">{formName}</Typography>
          </Grid>
          {document ? (
            <Grid xs={6}>
              <Button
                style={{ marginRight: '1rem' }}
                variant="contained"
                color="primary"
                onClick={openLink}
              >
                Review
              </Button>
              <Button onClick={() => showModal(document)}>Change</Button>
            </Grid>
          ) : (
            <Grid xs={6}>
              <Button
                onClick={() => showModal({})}
                variant="contained"
                color="primary"
              >
                Upload
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TaxYearConfirm;
