import React, { useEffect, useRef, useState } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingArea } from 'components/ui/Loading';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import moment from 'moment';
import groupsClient from 'api/groups/groupsClient';

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const EmailLog = ({ user, userId, groupInvestmentId, groupId }) => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const { name, email } = user;
  console.log('user', user);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.getTrackedEmails({
          userId,
          groupInvestmentId,
          groupId,
        });
        setEmails(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (userId) {
      fetchData();
    }
  }, [email, groupId, groupInvestmentId, userId]);
  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth', block: 'end' });
    }
  }, [emails]);

  if (!emails) {
    return (
      <Box
        padding={3}
        border="1px solid lightgray"
        bgcolor="white"
        width="100%"
        height="15rem"
        position="relative"
      >
        <LoadingArea open />
      </Box>
    );
  }

  return (
    <Box minHeight="15rem">
      <Box>
        <Typography>Email sent to {name}</Typography>
      </Box>
      <Box marginTop={2} maxHeight="20rem" overflow="auto">
        {emails.map((email) => {
          const { id, subject, text, templateData, createdAt } = email;
          const { html } = templateData || {};

          return (
            <Accordion key={id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {moment(createdAt).format('MM/DD/YYYY hh:mm a')} - {subject}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {text ? <div>{text}</div> : null}
                  {html ? (
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  ) : null}
                </Typography>
              </AccordionDetails>
            </Accordion>
            // <Box key={id} marginBottom={1}>
            //   <Box padding={1}>
            //     <Grid container spacing={2}>
            //       <Grid item>
            //         <Avatar src={photo} className={classes.userAvatar}>
            //           {initials}
            //         </Avatar>
            //       </Grid>
            //       <Grid item xs>
            //         <Typography display="inline">{creator}</Typography>
            //         <Box display="inline" marginLeft={2}>
            //           {moment(createdAt).fromNow()}
            //         </Box>
            //         <Box>{subject}</Box>
            //       </Grid>
            //       <Grid item>
            //         <Box display="inline-block" marginLeft={2}>
            //           <Button>View</Button>
            //         </Box>
            //       </Grid>
            //     </Grid>
            //   </Box>
            // </Box>
          );
        })}
        <Box ref={scrollRef}></Box>
      </Box>
    </Box>
  );
};

export default EmailLog;
