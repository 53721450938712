import React from 'react';
import { Typography, Button, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useProfile } from 'hooks/useAppState';

const ManagersCard = ({
  dateFiledReviewed,
  groupManager,
  groupManagerPerson,
  editClick,
}) => {
  const isAdmin = useProfile((state) => state.isAdmin);
  const { firstName, lastName } = groupManagerPerson || {};
  const { name } = groupManager || {};

  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justifyContent="space-between">
        <Grid>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Management:
          </Typography>
          {groupManager && <Typography variant="subtitle1">{name}</Typography>}
          <Typography variant={groupManager ? 'subtitle2' : 'subtitle1'}>
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid>
          <Button
            color="primary"
            size="small"
            onClick={editClick}
            disabled={!!dateFiledReviewed && !isAdmin}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ManagersCard;
