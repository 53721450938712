import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingSpinner } from 'components/ui/Loading';

import useSyndicate from 'hooks/useSyndicate';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import useSyndicateFiling from 'views/GroupInvestment/SyndicateTaxes/useSyndicateFiling';
import { useArrayUpdate } from 'hooks/useListUpdate';
import moment from 'moment';
import SaveFormButton from 'components/Form/SaveFormButton';
import LimitedLiabilityCompanyAgreements from './LimitedLiabilityCompanyAgreements';

const SyndicateAgreements = ({ investment, setInvestment }) => {
  const { syndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  if (!syndicate) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <SyndicateAgreementsHeader {...{ investment, syndicate }} />
      <LimitedLiabilityCompanyAgreements {...{ investment, syndicate }} />
    </Box>
  );
};

const SyndicateAgreementsHeader = ({ investment, syndicate }) => {
  const { roundName, syndicateName } = investment;
  const { id } = syndicate || {};

  const [filings, setFilings] = useState();
  const { onListUpdate } = useArrayUpdate(setFilings);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicateFilingsClient.index({
          syndicateId: id,
        });
        setFilings(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const { modal, showModal } = useSyndicateFiling({
    onChange: onListUpdate,
    createParams: {
      syndicateId: id,
      form: 'side_letter',
      filedOn: moment().format('YYYY-MM-DD'),
    },
  });

  const onAdd = useCallback(
    async (onError) => {
      try {
        const response = await syndicateFilingsClient.create({
          syndicateId: id,
          data: {
            form: 'side_letter',
            filedOn: moment().format('YYYY-MM-DD'),
          },
        });
        const data = response.data;
        onListUpdate(data);
        showModal(data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [id, showModal, onListUpdate],
  );

  if (!filings) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <Box>
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid xs={12} md={4}>
            <Box>
              {syndicateName ? (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {syndicateName}
                </Typography>
              ) : (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {roundName}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid>
            {modal}
            <SaveFormButton variant="contained" onSave={onAdd}>
              Add Side Letter Agreement
            </SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SyndicateAgreements;
