import { Box } from '@mui/material';
import React from 'react';

const DisplayAddress = ({ address }) => {
  const { name, city, country, postal, secondary, state, street } =
    address || {};
  return (
    <Box>
      <Box>{name}</Box>
      <Box>{street}</Box>
      {secondary && <Box>{secondary}</Box>}
      <Box>
        {city} {state} {postal}
      </Box>
      {country && country != 'US' && <Box>{country}</Box>}
    </Box>
  );
};

export default DisplayAddress;
