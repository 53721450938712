import React from 'react';
import { Box, Typography, Paper, Container, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import useEmailHelp from 'hooks/useEmailHelp';

const useStyles = makeStyles((theme) => {
  console.log('theme', theme);
  return {
    root: {
      height: '100%',
      bgcolor: theme.palette.grey[100],
      // background: theme.palette.primary.gradients.main,
    },

    link: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },

    formHeader: {
      padding: theme.spacing(3),
      background: theme.palette.primary.gradients.main,
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    inputContainer: {
      padding: theme.spacing(6),
    },

    container: {
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      // background: theme.palette.white,
      width: '100%',
      padding: theme.spacing(2),
      // color: theme.palette.grey[600],
      background: theme.palette.primary.gradients.main,
      color: 'white',
    },
    copyrightCell: {
      order: 0,
      [theme.breakpoints.down('md')]: {
        order: 99,
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(2),
      },
    },
  };
});

const GenericPortal = ({ title, children, maxWidth = 'sm' }) => {
  const classes = useStyles();
  const { helpComponent, toggleSupport } = useEmailHelp();

  return (
    <Box className={classes.root}>
      <CenteredContentBox>
        <Paper>
          <Container disableGutters maxWidth={maxWidth}>
            <Box component="header" className={classes.formHeader}>
              <Typography variant="h5">{title}</Typography>
            </Box>
            <Box className={classes.inputContainer}>{children}</Box>
            <Box className={classes.root}>
              <Container className={classes.container}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid>
                    <Link
                      className={classes.link}
                      href="https://app.termly.io/document/privacy-policy/81e205e7-1566-4c74-aa5b-2dd9da3da987"
                      target="_blank"
                    >
                      Privacy
                    </Link>
                  </Grid>
                  <Grid>
                    <Link
                      className={classes.link}
                      href="https://app.termly.io/document/terms-of-use-for-saas/911d39a6-76b6-405d-af36-4e40f5ab5af8"
                      target="_blank"
                    >
                      Terms of Use
                    </Link>
                  </Grid>
                  <Grid>
                    <Link
                      className={classes.link}
                      href="https://app.termly.io/document/disclaimer/4a69fd78-5df1-4b7f-8735-d3a0dc56b755"
                      target="_blank"
                    >
                      Disclaimer
                    </Link>
                  </Grid>
                  <Grid>
                    <Link
                      className={classes.link}
                      href="#"
                      onClick={() => window.displayPreferenceModal()}
                    >
                      Cookie Preferences
                    </Link>
                  </Grid>
                  <Grid>
                    <Link
                      className={classes.link}
                      onClick={toggleSupport}
                      style={{ cursor: 'pointer' }}
                      type="button"
                    >
                      Contact
                    </Link>
                    {helpComponent}
                  </Grid>
                </Grid>
                <Box marginTop={2}>© 2023 SmartCapital</Box>
              </Container>
            </Box>
          </Container>
        </Paper>
      </CenteredContentBox>
    </Box>
  );
};

export default GenericPortal;
