import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';

import DialogWithTitle from 'components/ui/DialogWithTitle';

import useStateNotification from 'hooks/useStateNotification';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';
import SyndicateFormationFlow from 'views/Syndicate/SyndicateFormationFlow';
import SyndicateOfferingFlow from 'views/Syndicate/SyndicateOfferingFlow';
import isEqual from 'lodash/isEqual';
import { LoadingSpinner } from 'components/ui/Loading';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import { useHistory } from 'react-router-dom';
import { useSelectedGroup } from 'hooks/useAppState';

const useSyndicate = ({ investment, setInvestment }) => {
  const { syndicateId } = investment || {};
  const [syndicate, updateSyndicate] = useStateNotification('syndicates');

  const setSyndicate = useCallback(
    (val) => {
      updateSyndicate(val);
      if (!isEqual(val.groupInvestment, investment)) {
        setInvestment(val.groupInvestment);
      }
    },
    [investment, setInvestment, updateSyndicate],
  );
  const fetchData = useCallback(async () => {
    try {
      const response = await syndicatesClient.get({
        id: syndicateId,
      });
      setSyndicate(response.data);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  }, [setSyndicate, syndicateId]);
  useEffect(() => {
    if (syndicateId && !syndicate) {
      fetchData();
    }
  }, [fetchData, syndicate, syndicateId]);

  return { syndicate, setSyndicate, refreshSyndicate: fetchData };
};

export const useSyndicateActions = ({
  opportunityId,
  investmentId,
  startOpen = false,
  hideSyndicateButton = false,
}) => {
  const [investment, setInvestment] = useState();
  const history = useHistory();
  const { slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.get({
          id: investmentId,
        });
        setInvestment(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (investmentId) {
      fetchData();
    }
  }, [investmentId]);
  useEffect(() => {
    window.addEventListener('SC_CreateSyndicate', () => showWizard(true));
    return () => {
      window.removeEventListener('SC_CreateSyndicate', () => showWizard(true));
    };
  }, []);
  const { syndicateId } = investment || {};
  const { syndicate, setSyndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  const [wizard, showWizard] = useState(startOpen);
  const [edit, showEdit] = useState(false);

  const hideWizard = () => {
    showWizard(false);
  };
  const { syndicateButton } = useMemo(() => {
    const {
      dateFiledFormationService,
      dateFiledFormationFilings,
      dateFiledFormationAuthorization,
      dateFiledOfferingAuthorization,
      dateFiledOfferingReview,
      dateFiledOfferingAgreements,
    } = syndicate || {};

    const isInFormation =
      !dateFiledFormationService ||
      !dateFiledFormationFilings ||
      !dateFiledFormationAuthorization;
    const syndicateForm =
      (investmentId && !investment) || (syndicateId && !syndicate) ? (
        <LoadingSpinner />
      ) : (
        <>
          {!edit && syndicate && !isInFormation ? (
            <SyndicateOfferingFlow
              {...{
                syndicate,
                investment,
                setInvestment,
                opportunityId: investment?.opportunityId || opportunityId,
                setSyndicate,
                onClose: () => {
                  hideWizard(false);
                },
                onBack:
                  syndicate && edit
                    ? () => {
                        showEdit(false);
                      }
                    : null,
                onComplete: (data) => {
                  setSyndicate(data);
                  if (data.dateFiledOfferingAgreements) {
                    hideWizard(false);
                    // redirect to syndicate page
                    console.log('data', data);
                    history.push(
                      `/${slug}/syndicates/${data?.groupInvestment?.id}`,
                    );
                  }
                },
              }}
            />
          ) : (
            <SyndicateFormationFlow
              {...{
                syndicate,
                investment,
                setInvestment,
                opportunityId: investment?.opportunityId || opportunityId,
                setSyndicate,
                onClose: () => {
                  hideWizard(false);
                },
                onBack:
                  syndicate && edit
                    ? () => {
                        showEdit(false);
                      }
                    : null,
                onComplete: (data) => {
                  setSyndicate(data);
                },
              }}
            />
          )}
        </>
      );

    const syndicateActionName = !syndicateId
      ? 'Create Syndicate'
      : syndicate && !dateFiledFormationFilings
      ? 'Complete Syndicate'
      : syndicate && !dateFiledFormationAuthorization
      ? 'Complete Syndicate'
      : syndicate && !dateFiledOfferingAuthorization
      ? 'Complete Syndicate'
      : syndicate && !dateFiledOfferingReview
      ? 'Complete Syndicate'
      : syndicate && !dateFiledOfferingAgreements
      ? 'Complete Syndicate'
      : 'Edit Syndicate';

    const syndicateButton =
      syndicateId && !syndicate ? null : (
        <>
          {wizard && (
            <DialogWithTitle
              open
              onClose={() => {
                hideWizard(false);
              }}
              title={`Syndication ${
                !edit && syndicate && !isInFormation ? 'Offering' : 'Formation'
              } Guide`}
              fullWidth
              maxWidth="lg"
            >
              {syndicateForm}
            </DialogWithTitle>
          )}

          <Button
            color="primary"
            variant="contained"
            style={{ display: hideSyndicateButton ? 'none' : '' }}
            onClick={() => showWizard(true)}
          >
            {syndicateActionName}
          </Button>
        </>
      );
    return {
      syndicateButton,
    };
  }, [
    syndicate,
    investmentId,
    investment,
    syndicateId,
    edit,
    opportunityId,
    setSyndicate,
    wizard,
    hideSyndicateButton,
    history,
    slug,
  ]);

  return {
    syndicate,
    investment,
    setInvestment,
    setSyndicate,
    syndicateButton,
  };
};

export default useSyndicate;
