import React, { useEffect, useMemo, useState } from 'react';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';
import { SiWebpack } from 'react-icons/si';
import { createStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import { LoadingSpinner } from 'components/ui/Loading';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import syndicatesClient from 'api/syndicatesClient';
import { useArrayUpdate } from 'hooks/useListUpdate';
import getServerResponseErrors from 'api/getServerResponseErrors';
import InvestorTaxes from './InvestorTaxes';
import StatusCell from './StatusCell';
import InvestorMiniProfile from './InvestorMiniProfile';
import taxDocumentsClient from 'api/taxDocumentsClient';

export const FiledStatuses = {
  NOT_FILED: 'Not Started',
  SKIPPED: 'N/A',
  IN_PROGRESS: 'In Progress',
  FILED: 'Filed',
};

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);
const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const RowButtons = ({
  syndicateId,
  syndicateName,
  row,
  onChange,
  unitPrice,
  finalClosingYear,
}) => {
  const [modal, showModal] = useState(false);
  return (
    <>
      {modal && (
        <DialogWithActions
          title={<Typography variant="h6">{syndicateName}</Typography>}
          titleOverrides={{ style: { padding: 24, paddingBottom: 12 } }}
          bodyOverrides={{ paddingTop: 0 }}
          {...{
            onClose: () => showModal(false),
          }}
        >
          <InvestorTaxes
            {...{
              data: row,
              syndicateId,
              onChange,
              unitPrice,
              finalClosingYear,
            }}
          />
        </DialogWithActions>
      )}
      <Button variant="outlined" size="sm" onClick={() => showModal(true)}>
        View Taxes
      </Button>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  moneyColor: {
    color: theme.palette.money.main,
  },
}));

const getK1Status = (documents, year, taxYears) => {
  const taxYear = taxYears.find((taxYear) => taxYear.year === year);
  if (!taxYear) return FiledStatuses.NOT_FILED;
  if (!taxYear.dateStarted && !taxYear.dateCompleted)
    return FiledStatuses.IN_PROGRESS;
  if (!taxYear.filingRequired) return FiledStatuses.SKIPPED;

  const inProgress = documents.some(
    (x) => x.year == year && (!x.document || !x.document.dateCompleted),
  );
  if (inProgress || !taxYear.dateCompleted) {
    return FiledStatuses.IN_PROGRESS;
  }
  return FiledStatuses.FILED;
};

const InvestorTaxesList = ({
  unitPrice,
  syndicateId,
  syndicateName,
  finalClosingYear,
  taxYears,
}) => {
  const [investments, setInvestments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.investments(syndicateId);
        setInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData(syndicateId);
    }
  }, [syndicateId]);

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxDocumentsClient.index({ syndicateId });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData(syndicateId);
    }
  }, [syndicateId]);

  const { onListUpdate } = useArrayUpdate(setInvestments);

  const classes = useStyles();
  const columns = useMemo(() => {
    const year = moment().year();
    const currentTaxYear = year - 1;
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        valueGetter: ({ row }) => row?.userInfo?.name,
        renderCell: ({ row }) => {
          return InvestorMiniProfile({ data: row });
        },
      },
      {
        field: 'acceptedUnits',
        headerName: 'Invested',
        width: 150,
        valueFormatter: ({ value }) => {
          return (parseFloat(value) * parseFloat(unitPrice)).toLocaleString(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          );
        },
        cellClassName: () => classes.moneyColor,
      },
      {
        field: 'previousYear',
        headerName: currentTaxYear - 1,
        width: 150,
        valueGetter: ({ row }) => {
          const taxDocuments = documents.filter(
            (x) => x.year == currentTaxYear - 1 && x.userInvestmentId == row.id,
          );
          return currentTaxYear - 1 >= finalClosingYear
            ? getK1Status(taxDocuments, currentTaxYear - 1, taxYears)
            : null;
        },
        renderCell: ({ value }) => {
          return StatusCell({ value });
        },
      },
      {
        field: 'currentYear',
        headerName: currentTaxYear,
        width: 150,
        valueGetter: ({ row }) => {
          const taxDocuments = documents.filter(
            (x) => x.year == currentTaxYear && x.userInvestmentId == row.id,
          );
          return currentTaxYear >= finalClosingYear
            ? getK1Status(taxDocuments, currentTaxYear, taxYears)
            : null;
        },
        renderCell: ({ value }) => {
          return StatusCell({ value });
        },
      },
      {
        field: 'buttons',
        headerName: ' ',
        flex: 1,
        align: 'right',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row }) => {
          return RowButtons({
            syndicateId,
            syndicateName,
            unitPrice,
            row,
            onChange: onListUpdate,
            finalClosingYear,
          });
        },
      },
    ];
  }, [
    classes.moneyColor,
    finalClosingYear,
    onListUpdate,
    syndicateId,
    syndicateName,
    taxYears,
    unitPrice,
    documents,
  ]);
  return (
    <Box>
      <Typography
        style={{ fontSize: 20, fontWeight: 700, padding: '15px 0px' }}
      >
        Investor Tax Dashboard
      </Typography>
      {investments && documents ? (
        <DataGridPro
          className={classes.root}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rows={investments || []}
          columns={columns.filter((x) => !!x)}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          autoHeight
          disableColumnSelector
          disableColumnPinning
          rowHeight={65}
        />
      ) : (
        <Box
          marginTop={2}
          padding={3}
          height="10vh"
          bgcolor="grey.300"
          borderRadius={1}
          textAlign="center"
        >
          <CenteredContentBox>
            <Box marginBottom={2}>
              <LoadingSpinner />
            </Box>
          </CenteredContentBox>
        </Box>
      )}
    </Box>
  );
};

export default InvestorTaxesList;
