import React from 'react';
import { Box, Tooltip, Typography, Avatar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import imageAccredited from 'images/investor-accredited-1.svg';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const UserInvestmentProfile = ({ userInvestment }) => {
  const classes = useStyles();
  const { user } = userInvestment || {};
  const { email, photo, isAccredited, entityName, name, initials } = user || {};
  return (
    <Box>
      <Grid container spacing={1} wrap="nowrap">
        <Grid>
          <Avatar src={photo} className={classes.userAvatar}>
            {initials}
          </Avatar>
        </Grid>
        <Grid>
          <Box style={{ whiteSpace: 'nowrap' }}>
            <Typography variant="caption">
              <Box fontWeight="bold" display="inline">
                {name}
              </Box>
              {isAccredited && (
                <>
                  &nbsp;
                  <Tooltip title="Accredited">
                    <Box style={{ display: 'inline' }} paddingLeft={1}>
                      <img
                        src={imageAccredited}
                        style={{ maxHeight: '0.75rem' }}
                        alt="Accredited"
                      />
                    </Box>
                  </Tooltip>
                </>
              )}

              <LeadStatus {...userInvestment} />
              <VerificationStatus {...userInvestment} />
              <WatchlistStatus {...userInvestment} />
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption">{email}</Typography>
          </Box>
          <Box>
            <Typography variant="caption">{entityName}&nbsp;</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const LeadStatus = ({ isLead, hasRequestedLead }) => {
  if (isLead) {
    return (
      <>
        &nbsp;
        <Tooltip title="Is Lead Investor">
          <Box style={{ display: 'inline' }} paddingLeft={0.5}>
            <PersonIcon
              fontSize="small"
              style={{ verticalAlign: 'text-bottom' }}
            />
          </Box>
        </Tooltip>
      </>
    );
  } else if (hasRequestedLead) {
    return (
      <>
        &nbsp;
        <Tooltip title="Has Requested Lead">
          <Box style={{ display: 'inline' }} paddingLeft={0.5}>
            <PersonOutlineIcon
              fontSize="small"
              style={{ verticalAlign: 'text-bottom' }}
            />
          </Box>
        </Tooltip>
      </>
    );
  }
  return null;
};

const VerificationStatus = ({
  dateReviewed,
  verificationStatus,
  verificationDetails,
}) => {
  if (!dateReviewed) {
    return null;
  } else if (verificationStatus === 'valid') {
    return (
      <Tooltip title={<div>KYC Check Passed</div>}>
        <Box style={{ display: 'inline' }} paddingLeft={0.5}>
          <VerifiedUserIcon
            fontSize="small"
            style={{ verticalAlign: 'text-bottom', color: '#007D05' }}
          />
        </Box>
      </Tooltip>
    );
  } else if (verificationStatus) {
    return (
      <Tooltip
        title={
          <div>
            There was an issue verifying the submitted information. Some of the
            information provided did not match.
            <br />
            status: {verificationStatus}
          </div>
        }
      >
        <Box style={{ display: 'inline' }} paddingLeft={0.5}>
          <div hidden>{JSON.stringify(verificationDetails, null, 2)}</div>
          <NewReleasesIcon
            fontSize="small"
            style={{ verticalAlign: 'text-bottom' }}
            // htmlColor={yellow[700]}
            htmlColor="#FFC400"
          />
        </Box>
      </Tooltip>
    );
  }
  return null;
  // return (
  //   <Tooltip title={<div>Verification in Progress...</div>}>
  //     <Box style={{ display: 'inline' }} paddingLeft={1}>
  //       <CircularProgress size="1rem" />
  //     </Box>
  //   </Tooltip>
  // );
};
const WatchlistStatus = ({ dateReviewed, watchListDetails }) => {
  if (!dateReviewed || !watchListDetails || watchListDetails.length === 0) {
    return null;
  }
  return (
    <Tooltip
      title={
        <div>
          There was a possible match to a watchlist.
          <br />
          details: {JSON.stringify(watchListDetails, null, 2)}
        </div>
      }
    >
      <Box style={{ display: 'inline' }} paddingLeft={0.5}>
        <NewReleasesIcon
          fontSize="small"
          color="error"
          style={{ verticalAlign: 'text-bottom' }}
        />
      </Box>
    </Tooltip>
  );
};

export default UserInvestmentProfile;
