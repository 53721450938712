import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { FormikPhoneInput } from 'components/ui/CustomPhone';
import { FormikCheckBox } from 'components/ui/CustomCheckBox';
import { usePhoto } from 'components/ui/PhotoPicker';
import defaultPerson from 'images/default-person.svg';
import { FormikRegionSelect } from 'components/ui/RegionSelect';

const useFormUserProfile = ({ data, onSubmit, showTitle = true }) => {
  const [formRef, setFormRef] = useState({});

  const { currentPhoto, photoPicker } = usePhoto(data, '15rem', defaultPerson);
  const validate = async () => {
    const {
      values: { firstName, lastName, phone, isAccredited, registeredState },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        firstName,
        lastName,
        phone,
        registeredState,
        isAccredited,
        photo: currentPhoto.data,
        photoFileName: currentPhoto.name,
      },
    };
  };

  const form = (
    <>
      <Form
        initialValues={data}
        enableReinitialize
        setRef={setFormRef}
        onSubmit={onSubmit}
      >
        <Grid container spacing={4}>
          <Grid xs={12} sm={7} md={8}>
            {showTitle && (
              <Box>
                <Typography>Profile Information</Typography>
              </Box>
            )}
            <Box marginTop={2}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={6}>
                  <FormikTextInput
                    name="firstName"
                    label="First Name"
                    type="text"
                    required
                  />
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                  <FormikTextInput
                    name="lastName"
                    label="Last Name"
                    type="text"
                    required
                  />
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={6}>
                  <FormikRegionSelect
                    name="registeredState"
                    label="State/Region of Residency"
                    required
                  />
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={6}>
                  <FormikPhoneInput
                    name="phone"
                    variant="filled"
                    label="Phone Number"
                    required
                  />
                </Grid>
                <Grid xs={12}>
                  <FormikCheckBox
                    name="isAccredited"
                    variant="filled"
                    label="Accredited Investor"
                  />
                  <Box>
                    <Typography variant="caption">
                      The SEC has rules around private investments as it relates
                      to &quot;Accredited Investors&quot;. For some investment
                      offerings, accreditation may be required. Please reach out
                      to the fund manager for more information.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid xs>
            <Box marginLeft={2}>
              <Typography>Profile Image</Typography>
            </Box>
            <Box marginTop={2}>
              <Box maxWidth="20rem">{photoPicker}</Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );

  return { form, validate };
};

export default useFormUserProfile;
