import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingSpinner } from 'components/ui/Loading';

import { CustomCheckBox } from 'components/ui/CustomCheckBox';

import SyndicatePaymentWire from './SyndicatePaymentWire';
import SyndicatePaymentCheck from './SyndicatePaymentCheck';
import WarningIcon from '@mui/icons-material/Warning';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

const SyndicateBanking = ({ investment, setInvestment }) => {
  const theme = useTheme();
  const {
    roundName,
    syndicateName,
    acceptChecks,
    acceptWires,
    paymentCheck,
    paymentWire,
  } = investment;

  if (!investment) {
    return <LoadingSpinner></LoadingSpinner>;
  }
  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid>
          <Box>
            {syndicateName ? (
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {syndicateName}
              </Typography>
            ) : (
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {roundName}
              </Typography>
            )}
          </Box>
        </Grid>

        {!((acceptChecks && paymentCheck) || (acceptWires && paymentWire)) && (
          <Grid xs={12} md={8}>
            <Box bgcolor={theme.palette.primary.main} color="white" padding={2}>
              <Grid container spacing={2}>
                <Grid>
                  <Box fontSize={30} lineHeight={0}>
                    <WarningIcon fontSize="inherit" />
                  </Box>
                </Grid>

                <Grid xs>
                  <Typography variant="subtitle1">
                    In order to accept investments you must setup and allow
                    checks or wires
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
      <BankingOptions {...{ investment, setInvestment }} />
    </Box>
  );
};

export const BankingOptions = ({ investment, setInvestment }) => {
  return (
    <Box>
      <Box
        marginTop={4}
        borderBottom="1px solid lightgray"
        style={{ paddingBottom: '0.5rem' }}
      >
        <Typography color="primary">Funding Options</Typography>
      </Box>
      <Box marginTop={2}>
        <BankingSettings {...{ investment, setInvestment }} />
      </Box>

      <Box marginTop={2}>
        <SyndicatePaymentWire {...{ investment, setInvestment }} />
      </Box>
      <Box marginTop={2}>
        <SyndicatePaymentCheck {...{ investment, setInvestment }} />
      </Box>
    </Box>
  );
};

const BankingSettings = ({ investment, setInvestment }) => {
  const [loading, setLoading] = useState(false);

  const { id, earlyPayment } = investment || {};

  const handleCheck = (e) => {
    const doChange = async (val) => {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          earlyPayment: val,
        },
      });
      setLoading(false);
      setInvestment(response.data);
    };
    const val = e.target.checked;
    setLoading(true);
    doChange(val);
  };
  return (
    <CustomCheckBox
      label={
        <Box marginLeft={1}>
          <Typography variant="subtitle1">
            Selecting this option will allow investors to send funds immediately
            after signing their investment documents. This will allow investors
            to send funds before the Manager approves the investor and accepts
            the proposed amount.
          </Typography>
        </Box>
      }
      checked={earlyPayment}
      onChange={handleCheck}
      disabled={loading}
      indeterminate={loading}
    />
  );
};

export default SyndicateBanking;
