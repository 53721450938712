import React from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import useFormSendEmail from 'hooks/forms/useFormSendEmail';
import groupsClient from 'api/groups/groupsClient';
import { useSelectedGroup } from 'hooks/useAppState';

const SendEmailModal = ({
  onClose,
  syndicateId,
  opportunityId,
  primaryContact,
  recipients,
}) => {
  const { groupId, slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const { form, validate, selected } = useFormSendEmail({
    recipients,
    primaryContact,
  });

  const onSave = async (onError) => {
    try {
      const { values, errors } = await validate();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      let { subject, body, replyTo } = values;

      const response = await groupsClient.sendTrackedEmail({
        syndicateId,
        groupId,
        subject,
        text: body,
        replyTo,
        emails: selected.map((x) => x.email),
        buttonText: opportunityId ? 'Open Opportunity' : 'Open Group',
        buttonUrl: opportunityId
          ? `${window.location.origin}/${slug}/opportunity/${opportunityId}`
          : `${window.location.origin}/${slug}`,
      });
      onClose(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <>
      <Box marginBottom={4}>
        <Box marginBottom={2}>{form}</Box>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid>
            <Button variant="contained" onClick={onClose} color="grey">
              Cancel
            </Button>
          </Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={`Send Email (${selected?.length || 0})`}
              variant="contained"
              disabled={selected?.length === 0}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendEmailModal;
