import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import groupInvestmentsClient from 'api/groupInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import DialogWithTitle from 'components/ui/DialogWithTitle';

import syndicatesClient from 'api/syndicatesClient';
import { LoadingArea, LoadingSpinner } from 'components/ui/Loading';
import { DocumentsTable } from 'views/Documents';
import userInvestmentsClient from 'api/userInvestmentsClient';
import DocumentIcon from 'components/ui/DocumentIcon';
import useStateNotification from 'hooks/useStateNotification';
import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';

const OpportunitySectionInvestments = ({ investments }) => {
  const filtered = (investments || []).filter((x) => x.syndicateConfirmed);
  if (filtered.length === 0) {
    return null;
  }

  const sorted = filtered.sort((a, b) =>
    a.dateClosed && !b.dateClosed ? 1 : a.dateClosed < b.dateClosed ? 1 : -1,
  );

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Investments
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3}>
          {sorted.map((investment) => (
            <Grid key={investment.id} xs={12} sm="auto">
              <Investment data={investment} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const Investment = ({ data }) => {
  const { id } = data;
  const [documentList, showDocuments] = useState(false);
  const [investment, setInvestment] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.get({ id });
        setInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    fetchData();
  }, [id]);

  const {
    isAdmin,
    disableSubscriptions,
    dateClosed,

    documents = [],
    syndicateId,
    myInvestmentId,
    roundName,
    syndicateName,
    unitPrice,
  } = investment || {};

  const [userInvestment, setUserInvestment] =
    useStateNotification('user_investments');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.get({
          id: myInvestmentId,
        });
        setUserInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (myInvestmentId) {
      fetchData();
    }
  }, [myInvestmentId, setUserInvestment]);

  if (!investment) {
    return (
      <Box
        padding={3}
        border="1px solid lightgray"
        bgcolor="white"
        width="15rem"
        height="15rem"
        position="relative"
      >
        <LoadingArea open />
      </Box>
    );
  }

  const {
    subscription,
    requestedUnits,
    acceptedUnits,
    amountReceived,
    refundAmount,
  } = userInvestment || {};

  const { id: signatureId, documentData } = subscription || {};

  return (
    <Box padding={3} border="1px solid lightgray" bgcolor="white">
      <Box>
        <Typography variant="h6">
          {roundName}
          {syndicateName ? ` (${syndicateName})` : ''}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color="primary">
          {dateClosed
            ? 'Closed'
            : disableSubscriptions
            ? 'Collecting Interest'
            : 'Accepting Investments'}
        </Typography>
      </Box>
      {(!dateClosed || isAdmin || myInvestmentId) && (
        <Box marginTop={3}>
          <Grid container spacing={4}>
            {documents && documents.length > 0 && (
              <Grid>
                {documentList && (
                  <>
                    <DialogWithTitle
                      open
                      onClose={() => {
                        showDocuments();
                      }}
                      title="Confidential Documents"
                      fullWidth
                    >
                      <DocumentsTable documents={documents} />
                    </DialogWithTitle>
                  </>
                )}
                <Box
                  onClick={() => showDocuments(documents)}
                  style={{ cursor: 'pointer' }}
                >
                  <Grid>
                    <Box cursor="pointer">
                      <Grid container spacing={2}>
                        <Grid>
                          <DocumentIcon count={documents.length} />
                        </Grid>
                        <Grid>
                          <Box>
                            <Typography>
                              Confidential
                              <br />
                              Documents
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid>
              <SyndicateDocuments
                syndicateId={syndicateId}
                myInvestmentId={myInvestmentId}
                documents={documents}
              />
            </Grid>
            <DisplayAmounts {...{ investment }} />
          </Grid>
        </Box>
      )}
      {requestedUnits > 0 && (
        <Box marginTop={4}>
          <Box textAlign="left">
            <Typography color="primary">Your Investment Information</Typography>
          </Box>
          <Box marginTop={2} style={{ cursor: 'pointer' }}>
            <Grid container spacing={4}>
              {documentData && (
                <Grid>
                  <SignatureLink {...{ id: signatureId, data: subscription }} />
                </Grid>
              )}
              <Grid>
                <NameValue
                  name="Subscription"
                  value={requestedUnits * unitPrice}
                />
              </Grid>
              <Grid>
                <NameValue name="Accepted" value={acceptedUnits * unitPrice} />
              </Grid>
              <Grid>
                <NameValue
                  name="Received"
                  value={amountReceived - refundAmount}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const DisplayAmounts = ({ investment }) => {
  const {
    id,
    showInterestAmount,
    showSubscribedAmount,
    showAcceptedAmount,
    showConfirmedAmount,
    showTargetAmount,
    targetAmount,
  } = investment;

  const fetchRequired =
    showInterestAmount ||
    showSubscribedAmount ||
    showAcceptedAmount ||
    showConfirmedAmount;

  const [totals, setTotals] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.totals({
          id,
        });
        setTotals(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id && fetchRequired) {
      fetchData();
    }
  }, [id, fetchRequired]);

  const { interest, proposed, invested } = totals || {};

  return (
    <>
      {showTargetAmount && (
        <Grid>
          <NameValue name="Target" value={targetAmount} />
        </Grid>
      )}
      {totals && (
        <>
          {showInterestAmount && (
            <Grid>
              <NameValue name="Interest" value={interest} />
            </Grid>
          )}
          {showSubscribedAmount && (
            <Grid>
              <NameValue name="Proposed" value={proposed} />
            </Grid>
          )}
          {showAcceptedAmount && (
            <Grid>
              <NameValue name="Invested" value={invested} />
            </Grid>
          )}
          {/* {showConfirmedAmount && (
            <Grid>
              <NameValue name="Collected" value={collected} />
            </Grid>
          )} */}
        </>
      )}
    </>
  );
};

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const NameValue = ({ name, value }) => {
  return (
    <Box paddingBottom={1} style={{ borderBottom: '1px solid lightgray' }}>
      <Box>
        <Typography>{name}</Typography>
      </Box>
      <Box textAlign="right">
        <Typography variant="caption">
          {value ? formatter.format(value) : '-'}
        </Typography>
      </Box>
    </Box>
  );
};

const SignatureLink = ({ id, data }) => {
  const { openLink } = useSyndicateDocumentPoll({ id, data });
  return (
    <Link onClick={openLink}>
      <Grid container spacing={1}>
        <Grid>
          <DocumentIcon />
        </Grid>
        <Grid>
          <Box>
            <Typography>
              Signature
              <br />
              Page
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Link>
  );
};

const SyndicateDocument = ({ id, name, description }) => {
  const { data, openLink } = useSyndicateDocumentPoll({ id });

  const { documentData } = data || {};
  return (
    <TableRow>
      <TableCell style={{ width: '40%' }}>
        {documentData ? (
          <Typography
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={openLink}
          >
            {name}
          </Typography>
        ) : (
          <>
            <Typography>
              {name} <CircularProgress size="1rem" />
            </Typography>
          </>
        )}
      </TableCell>
      <TableCell align="left">{description}</TableCell>
    </TableRow>
  );
};

const SyndicateDocuments = ({ syndicateId }) => {
  const [modal, showModal] = useState(false);
  const [syndicate, setSyndicate] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.get({ id: syndicateId });
        setSyndicate(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData();
    }
  }, [syndicateId]);

  if (syndicateId && !syndicate) {
    return <LoadingSpinner />;
  }
  const {
    operatingAgreementId,
    offeringCircularId,
    subscriptionAgreementId,
    groupInvestment,
  } = syndicate || {};

  const syndicateDocuments = [
    operatingAgreementId,
    offeringCircularId,
    subscriptionAgreementId,
  ].filter((x) => !!x);

  const { dateClosed } = groupInvestment || {};

  // const { name, document, link, description }

  return (
    <>
      {modal && (
        <>
          <DialogWithTitle
            open
            onClose={() => {
              showModal();
            }}
            title="Syndicate Documents"
            fullWidth
          >
            <Box bgcolor="white" border="1px solid lightgray" borderBottom="0">
              <Table size="small" stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SyndicateDocument
                    {...{
                      id: operatingAgreementId,
                      name: 'Operating Agreement',
                    }}
                  />
                  <SyndicateDocument
                    {...{
                      id: offeringCircularId,
                      name: 'Private Placement Memorandum',
                    }}
                  />
                  {subscriptionAgreementId && (
                    <SyndicateDocument
                      {...{
                        id: subscriptionAgreementId,
                        name: 'Subscription Agreement',
                      }}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </DialogWithTitle>
        </>
      )}
      {!dateClosed && (
        <Box onClick={() => showModal(true)} style={{ cursor: 'pointer' }}>
          <Grid>
            <Box cursor="pointer">
              <Grid container spacing={1}>
                <Grid>
                  <DocumentIcon count={syndicateDocuments.length} />
                </Grid>
                <Grid>
                  <Box>
                    <Typography>
                      Syndicate
                      <br />
                      Documents
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OpportunitySectionInvestments;
