import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import { useArrayUpdate } from 'hooks/useListUpdate';
import SyndicateFilingItem from './SyndicateFilingItem';

const LimitedLiabilityCompanyAgreements = ({ investment, syndicate }) => {
  const { isAdmin } = investment;
  const {
    id,
    operatingAgreementId,
    offeringCircularId,
    subscriptionAgreementId,
    dateFiledReviewed,
    dateFiledOfferingAuthorization,
  } = syndicate || {};

  const canUpload = isAdmin && dateFiledOfferingAuthorization;

  const [filings, setFilings] = useState();
  const { onListUpdate } = useArrayUpdate(setFilings);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicateFilingsClient.index({
          syndicateId: id,
        });
        setFilings(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const operatingAgreement = useSyndicateDocument({
    id: operatingAgreementId,
    documentName: 'Operating Agreement',
    canChange: !dateFiledReviewed || canUpload || isAdmin,
    role: 'manager',
  });
  const offeringCircular = useSyndicateDocument({
    id: offeringCircularId,
    documentName: 'Private Placement Memorandum',
    canChange: !dateFiledReviewed || canUpload || isAdmin,
    role: 'manager',
  });
  const subscriptionAgreement = useSyndicateDocument({
    id: subscriptionAgreementId,
    documentName: 'Subscription Agreement',
    canChange: !dateFiledReviewed || canUpload || isAdmin,
    role: 'manager',
  });

  if (!operatingAgreementId) {
    return null;
  }

  return (
    <>
      <Box
        marginTop={4}
        borderBottom="1px solid lightgray"
        style={{ paddingBottom: '0.5rem' }}
      >
        <Typography color="primary">
          Limited Liability Company Agreements
        </Typography>
      </Box>

      <Box
        borderBottom="1px solid lightgray"
        style={{ padding: '1rem', paddingLeft: '' }}
      >
        <SyndicateDocumentItem {...{ data: operatingAgreement }} />
      </Box>
      <Box
        borderBottom="1px solid lightgray"
        style={{ padding: '1rem', paddingLeft: '' }}
      >
        <SyndicateDocumentItem {...{ data: offeringCircular }} />
      </Box>
      {subscriptionAgreementId && (
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: subscriptionAgreement }} />
        </Box>
      )}
      {filings &&
        filings
          .filter((x) => x.form == 'side_letter')
          .sort((a, b) => (a.year > b.year ? -1 : 1))
          .map((filing) => {
            return (
              <Box
                key={filing.unique_id}
                borderBottom="1px solid lightgray"
                style={{ padding: '1rem', paddingLeft: '' }}
              >
                <SyndicateFilingItem {...{ filing, onChange: onListUpdate }} />
              </Box>
            );
          })}
    </>
  );
};

export default LimitedLiabilityCompanyAgreements;
