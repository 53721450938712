import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import moment from 'moment';

export const FormikDatePicker = ({
  validation,
  required,
  defaultValue,
  label,
  shrink = true,
  fullWidth = true,
  InputLabelProps,
  ...props
}) => {
  const scheme = required
    ? (validation || yup.date())
        .required(`${label ? label : 'Field'} is required`)
        .nullable()
    : validation;
  const validate = async (value) => {
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };

  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  const handleChange = (val) => {
    // convert to date string
    if (val) {
      val = moment(val).format('MM/DD/yyyy');
    }
    setValue(val);
  };
  return (
    <>
      <DatePicker
        value={field.value || null}
        inputFormat="MM/DD/yyyy"
        onChange={handleChange}
        {...props}
        renderInput={(args) => (
          <TextField
            {...args}
            variant={props.inputVariant || 'filled'}
            aria-label="change date"
            InputLabelProps={{
              shrink,
              ...InputLabelProps,
            }}
            {...field}
            label={label}
            error={error && touched}
            required={required}
            helperText={error && touched ? error : null}
            fullWidth={fullWidth}
          />
        )}
      />
    </>
  );
};
