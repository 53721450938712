import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Link, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import Form from 'components/Form/Form';

import { signIn } from 'redux/currentUser/actions';
import { useDispatch } from 'react-redux';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import userClient from 'api/user/userClient';
import GenericPortal from 'views/GenericPortal';
import { useProfile, useSelectedGroup } from 'hooks/useAppState';
import { SIGNING_IN_START } from 'redux/currentUser/types';

const ForgotPassword = ({ onCancel }) => {
  const [message, setMessage] = useState();

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { email },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await userClient.forgotPassword(email);
      const { data } = response;
      const { message } = data;

      setMessage(message);

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const submitButton = useRef(null);
  const handleSubmit = () => {
    submitButton.current.click();
  };

  return (
    <>
      <Form setRef={setFormRef} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <FormikTextInput name="email" label="Email" required />
          </Grid>
        </Grid>
      </Form>
      <Box marginTop={5} textAlign="center">
        {message && <Box marginBottom={2}>{message}</Box>}

        <Grid container justifyContent="center" spacing={3}>
          <Grid>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={'Reset Password'}
              buttonRef={submitButton}
              disabled={message}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const SignIn = () => {
  const match = useRouteMatch('*/sign-in');
  const dispatch = useDispatch();
  const [forgot, setForgot] = useState();

  const { groupName, slug } = useSelectedGroup((state) => ({
    slug: state.slug,
    groupName: state.name,
  }));

  const onSignIn = useCallback(async () => {
    if (match && match.isExact && slug) {
      await dispatch({ type: SIGNING_IN_START });
      window.location.href = `/${slug}/`;
      await new Promise(() => {});
    } else if (match && match.isExact) {
      await dispatch({ type: SIGNING_IN_START });
      window.location.href = '/';
      await new Promise(() => {});
    }
  }, [match, slug, dispatch]);

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { email, password },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      await dispatch(signIn({ email, password }, onSignIn));

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const submitButton = useRef(null);
  const handleSubmit = () => {
    submitButton.current.click();
  };

  const { name } = useProfile((state) => ({
    name: state.name,
  }));
  useEffect(() => {
    if (name) {
      onSignIn();
    }
  }, [name, onSignIn]);
  if (name) return null;

  return (
    <GenericPortal
      title={forgot ? 'Forgot Password' : 'Authentication Required'}
    >
      {forgot ? (
        <ForgotPassword onCancel={() => setForgot(false)}></ForgotPassword>
      ) : (
        <>
          <Form setRef={setFormRef} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <FormikTextInput name="email" label="Email" required />
              </Grid>
              <Grid xs={12}>
                <FormikTextInput
                  name="password"
                  label="Password"
                  type="password"
                  required
                />
              </Grid>
            </Grid>
          </Form>
          <Box marginTop={5} textAlign="center">
            <SaveFormButton
              onSave={onSave}
              name={'Sign In'}
              buttonRef={submitButton}
            ></SaveFormButton>
          </Box>
          <Box marginTop={4}>
            <CenteredContentBox>
              <Link onClick={() => setForgot(true)} href="#">
                Forgot Password?
              </Link>
            </CenteredContentBox>
          </Box>
          {slug && (
            <Box marginTop={2}>
              <CenteredContentBox>
                Need an account?&nbsp;
                <Link href={`/${slug}/request-access`}>
                  Request access to {groupName}
                </Link>
              </CenteredContentBox>
            </Box>
          )}
        </>
      )}
    </GenericPortal>
  );
};

export default withRouter(SignIn);
