import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Snackbar,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import opportunitiesClient from 'api/opportunitiesClient';
import { useSelectedGroup } from 'hooks/useAppState';
import { themeColors } from 'utils/consts';
import { DialogWithActions } from 'components/ui/DialogWithTitle';
import ShareOpportunity from '../ShareOpportunity';
import groupsClient from 'api/groups/groupsClient';
import useDeleteConfirm from 'hooks/useDeleteConfirm';
import getServerResponseErrors from 'api/getServerResponseErrors';

const AccentSwitch = withStyles({
  switchBase: {
    color: themeColors.accentLight,
    '&$checked': {
      color: themeColors.accentColor,
    },
    '&$checked + $track': {
      backgroundColor: themeColors.accentColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const DisplayOpportunityCheckbox = ({ opportunity }) => {
  const [groupOpportunity, setGroupOpportunity] = useState(null);

  useEffect(() => {
    if (opportunity) {
      const { groupOpportunity: go } = opportunity;
      // TODO: this is hacky ... should have group opportunity lookup if not provided by opportunity
      if (go) {
        setGroupOpportunity(go);
      }
    }
  }, [opportunity]);

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (groupOpportunity) {
      setLoading(false);
    }
  }, [groupOpportunity]);

  const { hidden, opportunityId, groupId } = groupOpportunity || {};
  useEffect(() => {
    setChecked(!hidden);
  }, [hidden]);

  const handleChange = () => {
    const changeHidden = async (val) => {
      const response = await opportunitiesClient.visibility({
        opportunityId,
        groupId,
        hidden: !val,
      });
      setGroupOpportunity(response.data);
    };
    setChecked((x) => {
      setLoading(true);
      changeHidden(!x);
      return !x;
    });
  };
  return (
    <FormControlLabel
      control={
        <AccentSwitch
          checked={checked}
          onChange={handleChange}
          name="useBusiness"
          disabled={loading}
        />
      }
      label="Display Opportunity in Portal"
    />
  );
};

const EditOpportunitySettings = ({ opportunity: initialOpportunity }) => {
  const [shareModal, showShare] = useState();
  const [deleted, setDeleted] = useState();
  const [isLoading, setLoading] = useState(true);
  const [opportunity, setOpportunity] = useState(initialOpportunity);
  const [hasSyndicate, setHasSyndicate] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const { groupId, slug, enableOpportunitySharing } = useSelectedGroup(
    (state) => ({
      groupId: state.id,
      slug: state.slug,
      enableOpportunitySharing: state.enableOpportunitySharing,
    }),
  );
  const { id } = opportunity || {};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const investmentResponse = await opportunitiesClient.investments({
          id,
          groupId,
        });
        const investments = investmentResponse.data;
        if (investments.length > 0 && investments.some((x) => x.syndicateId)) {
          setHasSyndicate(true);
        }
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, groupId, opportunity]);
  const onDelete = async (onError) => {
    try {
      await groupsClient.removeOpportunity({ id: groupId, opportunityId: id });
      setDeleted(true);
      setOpportunity();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const { modal, showModal } = useDeleteConfirm({
    onConfirm: onDelete,
    headerName: 'Opportunity',
  });
  useEffect(() => {
    if (deleted) {
      const timer = setTimeout(() => {
        history.replace(`/${slug}/opportunity`);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deleted, history, slug]);

  const Toast = (
    <Snackbar
      key={id}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'middle',
      }}
      ContentProps={{
        style: {
          backgroundColor: theme.palette.accent.main,
          fontColor: '#fff',
        },
      }}
      open
      autoHideDuration={3000}
      message={`Deleted Opportunity`}
    />
  );
  if (deleted) {
    return Toast;
  }
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography variant="h6" color="primary">
          Opportunity Settings
        </Typography>

        <Typography variant="caption">
          These are settings for this opportunity.
        </Typography>
      </Box>
      <Divider />
      <Box marginY={2}>
        <Typography variant="h6" color="primary">
          Display
        </Typography>

        <Typography variant="caption">
          Where this opportunity should be displayed.
        </Typography>
        <Box marginY={1}>
          <DisplayOpportunityCheckbox {...{ opportunity }} />
        </Box>
      </Box>
      <Divider />
      <Box marginY={2}>
        <Typography variant="h6" color="primary">
          Share
        </Typography>

        <Typography variant="caption">
          Share this opportunity with other groups.
        </Typography>
        {!enableOpportunitySharing && (
          <Box marginY={1}>
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.text.secondary }}
            >
              This feature is not enabled for this group. To enable it, go to
              the group settings tab.
            </Typography>
          </Box>
        )}
        <Box marginY={2}>
          {shareModal && (
            <DialogWithActions
              {...{
                title: `Share Opportunity`,
                onClose: () => showShare(false),
              }}
              fullWidth
            >
              <Box position="relative">
                <ShareOpportunity
                  {...{
                    opportunityId: id,
                    groupId,
                    onClose: () => {
                      showShare(false);
                    },
                  }}
                />
              </Box>
            </DialogWithActions>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => (enableOpportunitySharing ? showShare(true) : null)}
            disabled={!enableOpportunitySharing}
          >
            Share Opportunity
          </Button>
        </Box>
        {!hasSyndicate && !isLoading && (
          <>
            {modal}
            <Divider />
            <Box marginY={2}>
              <Typography variant="h6" color="primary">
                Danger Zone
              </Typography>

              <Box
                marginY={1}
                display="flex"
                justifyContent="space-between"
                p={4}
                style={{
                  border: `1px dashed ${theme.palette.accent.main}`,
                  borderRadius: 4,
                }}
              >
                <Box>
                  <Box>Remove this opportunity</Box>
                  <Box>
                    <Typography variant="caption">
                      This will remove this opportunity from the group.
                    </Typography>
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => showModal(true)}
                >
                  Remove Opportunity from Group
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditOpportunitySettings;
