import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelectedGroup } from 'hooks/useAppState';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import defaultOpportunity from 'images/default-opportunity.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    height: '5rem',
  },
  logo: {
    maxHeight: '5rem',
  },
  images: {
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: '6rem',
    },
  },
  textGridItem: {
    alignSelf: 'center',
  },
  grid: {
    height: '100%',
  },
  buttonContainer: {
    marginTop: 'auto',
  },
}));

const OpportunityCard = ({ opportunity }) => {
  const history = useHistory();
  const slug = useSelectedGroup((state) => state.slug);
  const url = useCallback((url = '') => `/${slug}${url}`, [slug]);

  const classes = useStyles();

  const { isAdmin, id, photo, oneLiner, name, companyType, groupOpportunity } =
    opportunity;

  const { status } = groupOpportunity || {};

  return (
    <Paper elevation={0} style={{ height: '100%' }}>
      <Box
        padding={4}
        paddingBottom={2}
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(url(`/opportunity/${id}`))}
      >
        <Box className={classes.logoContainer}>
          <CenteredContentBox>
            {photo ? (
              <img alt="" src={photo} className={classes.logo} />
            ) : (
              <img alt="" src={defaultOpportunity} className={classes.logo} />
            )}
          </CenteredContentBox>
        </Box>
        <Grid container spacing={1}>
          <Grid xs>
            <Typography color="primary" style={{ textAlign: 'center' }}>
              <Box>{name}</Box>
            </Typography>
            <Typography
              variant="caption"
              style={{ textAlign: 'center' }}
              component="div"
            >
              {status}
            </Typography>
            <Typography variant="caption">{companyType}</Typography>
          </Grid>
          {/* <Grid style={{ textAlign: 'right' }}>
              <Typography>$10,000</Typography>
              <Typography variant="caption">2 units</Typography>{' '}
            </Grid> */}
        </Grid>
        <Box marginTop={2} height="4rem">
          <Typography
            variant="body2"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
              maxHeight: '4rem',
            }}
          >
            {oneLiner}
          </Typography>
        </Box>
      </Box>
      <Box
        paddingBottom={4}
        marginTop={2}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid>
            <Link
              variant="contained"
              color="primary"
              href={`/${slug}/opportunity/${id}`}
              style={{ cursor: 'pointer' }}
            >
              View
            </Link>
          </Grid>
          {isAdmin && (
            <>
              <Grid>|</Grid>
              <Grid>
                <Link
                  variant="contained"
                  color="primary"
                  href={`/${slug}/opportunity/${id}/edit`}
                  style={{ cursor: 'pointer' }}
                >
                  Manage
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
OpportunityCard.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(OpportunityCard);
