import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';
import { useSelectedGroup } from 'hooks/useAppState';

import { FormikTextInput } from 'components/ui/CustomTextField';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import groupsClient from 'api/groups/groupsClient';
import { VariableSizeList } from 'react-window';

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.name} ${option.email}`,
});

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  editor: {
    '& .ql-editor': {
      minHeight: '5rem',
    },
  },
});

const useAdminList = ({ primaryContact }) => {
  const [admins, setAdmins] = useState([]);
  const [replyTo, setReplyTo] = useState([]);
  const { id, contactEmail, name } = useSelectedGroup((state) => {
    return {
      id: state.id,
      contactEmail: state.contactEmail,
      name: state.name,
    };
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.admins({ id });
        const data = response.data.map((x) => ({
          name: x.name,
          email: x.email,
        }));

        setAdmins(data);
      } catch (e) {
        console.log(e);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  useEffect(() => {
    const data = [...admins];

    if (
      contactEmail &&
      data.filter((x) => x.email.toLowerCase() === contactEmail.toLowerCase())
        .length === 0
    ) {
      data.unshift({ name: name, email: contactEmail.toLowerCase() });
      setAdmins(data);
    }
    if (
      primaryContact &&
      data.filter((x) => x.email.toLowerCase() === primaryContact.toLowerCase())
        .length === 0
    ) {
      data.unshift({
        name: 'Primary Contact',
        email: primaryContact.toLowerCase(),
      });
      setAdmins(data);
    }

    if (primaryContact || contactEmail) {
      setReplyTo(
        data.filter(
          (x) =>
            x.email.toLowerCase() ===
            (primaryContact || contactEmail).toLowerCase(),
        ),
      );
    }
  }, [name, contactEmail, primaryContact, admins]);

  const sortedAdmins = useMemo(() => {
    if (admins.length > 0) {
      return admins.sort((a, b) => {
        if (primaryContact) {
          if (a.email === primaryContact.toLowerCase()) return -1;
          if (b.email === primaryContact.toLowerCase()) return 1;
        }
        if (a.email === contactEmail.toLowerCase()) return -1;
        if (b.email === contactEmail.toLowerCase()) return 1;

        return a.name.localeCompare(b.name);
      });
    }
    return [];
  }, [admins, contactEmail, primaryContact]);

  return { admins: sortedAdmins, replyTo, setReplyTo };
};

const useFormSendEmail = ({ recipients, primaryContact }) => {
  const [formRef, setFormRef] = useState({});

  const [body, setBody] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isAdminsDirty, setIsAdminsDirty] = useState(false);
  const [excludedInvestors, setExcludedInvestors] = useState([]);
  const bodyRef = useRef();
  const classes = useStyles();

  const [selected, setSelected] = useState(recipients);

  const { admins, replyTo, setReplyTo } = useAdminList({ primaryContact });

  useEffect(() => {
    if (body && !isDirty) setIsDirty(true);
  }, [isDirty, body]);

  useEffect(() => {
    if (recipients && recipients.length) {
      const filteredRecipients = recipients.filter(
        (x) => excludedInvestors.map((y) => y.email).indexOf(x.email) === -1,
      );
      setSelected(filteredRecipients);
    }
  }, [recipients, excludedInvestors]);
  const validate = async () => {
    const {
      values: { subject },
      errors,
    } = await validateForm(formRef);
    const bodyHasError =
      bodyRef && bodyRef.current.getEditor().getText() == '\n';
    if (bodyHasError) {
      errors.push('Body is required');
      if (!isDirty) setIsDirty(true);
    }
    if (replyTo.length === 0) {
      errors.push('Reply To is required');
      if (!isAdminsDirty) setIsAdminsDirty(true);
    }
    return {
      errors,
      values: {
        subject,
        body,
        replyTo,
      },
    };
  };

  const sortedRecpients = useMemo(() => {
    if (recipients.length > 0) {
      return recipients.sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [recipients]);
  const bodyHasError =
    isDirty &&
    (!body || (bodyRef && bodyRef.current.getEditor().getText() == '\n'));
  const form = (
    <Form enableReinitialize setRef={setFormRef}>
      <Box marginBottom={2}>
        <Typography>
          Sending message to {selected?.length || 0} of {recipients.length}{' '}
          investors based on your current filters and exclusions
        </Typography>

        {/** you are sending an email to all investors with the following statuses and list the filters they selected */}
      </Box>
      <Box marginBottom={2}>
        <Typography variant="caption">
          Excluded investors will not have an email sent to them.
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={sortedRecpients}
          disableCloseOnSelect
          limitTags={10}
          filterSelectedOptions
          classes={classes}
          ListboxComponent={ListboxComponent}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.email}
          renderOption={(props, option) => (
            <li {...props}>
              {option.name ? `${option.name} - ` : ''}
              {option.email}
            </li>
          )}
          ChipProps={{ size: 'small', variant: 'outlined' }}
          value={excludedInvestors}
          onChange={(_e, value, _reason) => {
            setExcludedInvestors(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={`Excluded Investors ${
                excludedInvestors.length > 0
                  ? `(${excludedInvestors.length})`
                  : ''
              }`}
            />
          )}
        />
      </Box>
      <Box marginBottom={2}>
        <Typography variant="caption">
          Each investor, not excluded above, will receive an email with the
          subject and body individually.This is known as BCC, so reply all will
          only go to the following set in the Reply To field below:
        </Typography>
      </Box>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={admins}
        disableCloseOnSelect
        limitTags={10}
        filterSelectedOptions
        classes={classes}
        ListboxComponent={ListboxComponent}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.email}
        renderOption={(option) => (
          <>
            {option.name} - {option.email}
          </>
        )}
        ChipProps={{ size: 'small', variant: 'outlined' }}
        value={replyTo}
        onChange={(_e, value, _reason) => {
          setReplyTo(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label={`Reply To ${
              replyTo.length > 0 ? `(${replyTo.length})` : ''
            }`}
          />
        )}
      />

      <FormikTextInput
        variant="outlined"
        name="subject"
        label="Subject"
        fullWidth
        required
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      <FormControl fullWidth error={bodyHasError}>
        <InputLabel
          shrink
          htmlFor="body-input"
          variant="outlined"
          required
          style={{
            background: 'white',
            padding: '0 0.5rem',
            marginLeft: '-0.25rem',
          }}
        >
          Body
        </InputLabel>
        <ReactQuill
          ref={bodyRef}
          className={classes.editor}
          id="body-input"
          aria-describedby="component-error-text"
          theme="snow"
          value={body}
          onChange={setBody}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              ['link', 'image'],
              ['clean'],
            ],
          }}
        />
        {bodyHasError && (
          <FormHelperText
            style={{ marginLeft: '1rem' }}
            id="component-error-text"
          >
            Body is required
          </FormHelperText>
        )}
      </FormControl>
    </Form>
  );

  return { form, validate, selected, replyTo };
};

export default useFormSendEmail;
