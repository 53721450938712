import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useMediaQuery } from '@mui/material';

const InvestorStatus = ({ status, email }) => {
  const isLargeUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  if (status === 'suspended') {
    return (
      <Tooltip
        title={`${email} has been suspended. They can still access previous investments but will not be able to invest in new rounds.`}
      >
        <Grid container whiteSpace="nowrap">
          <Grid>
            <ErrorRoundedIcon
              fontSize="small"
              color="disabled"
              style={{ verticalAlign: 'top' }}
            />
          </Grid>
          <Grid
            marginLeft={1}
            display="inline"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            Suspended
          </Grid>
        </Grid>
      </Tooltip>
    );
  } else if (status === 'confirmed') {
    return (
      <Grid container whiteSpace="nowrap">
        <Grid>
          <CheckCircleRoundedIcon
            fontSize="small"
            color="primary"
            style={{ verticalAlign: 'top' }}
          />
        </Grid>
        <Grid
          marginLeft={1}
          display="inline"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          Access
        </Grid>
      </Grid>
    );
  } else if (status === 'pending') {
    return (
      <Tooltip title={`${email} has not accepted the invitation yet`}>
        <Grid container whiteSpace="nowrap">
          <Grid>
            <ErrorRoundedIcon
              fontSize="small"
              color="action"
              style={{ verticalAlign: 'top' }}
            />
          </Grid>
          <Grid
            marginLeft={1}
            display="inline"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            Invited
          </Grid>
        </Grid>
      </Tooltip>
    );
  } else if (status === 'rejected') {
    return (
      <Tooltip title={`${email} has been reject.`}>
        <Grid container whiteSpace="nowrap">
          <Grid>
            <ErrorRoundedIcon
              fontSize="small"
              color="disabled"
              style={{ verticalAlign: 'top' }}
            />
          </Grid>
          <Grid
            marginLeft={1}
            display="inline"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            Rejected
          </Grid>
        </Grid>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={`${email} has Requested Access and needs confirmed`}>
      <Grid container whiteSpace="nowrap">
        <Grid>
          <ErrorRoundedIcon
            fontSize="small"
            color="action"
            style={{ verticalAlign: 'top' }}
          />
        </Grid>
        <Grid
          marginLeft={1}
          display="inline"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          {`Requested ${isLargeUp ? 'Access' : ''}`}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

export default React.memo(InvestorStatus);
