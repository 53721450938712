import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SaveFormButton from 'components/Form/SaveFormButton';
import { DialogWithActions } from 'components/ui/DialogWithTitle';
import { CustomTextField } from 'components/ui/CustomTextField';

const useDeleteConfirm = ({ onConfirm, headerName }) => {
  const [modal, showModal] = useState(false);
  const [confirmVal, setConfirmVal] = useState('');
  const setShowModal = (val) => {
    if (!val) {
      setConfirmVal('');
    }
    showModal(val);
  };

  const onDelete = useCallback(
    async (...args) => {
      try {
        await onConfirm(...args);
        setShowModal(false);
      } catch (e) {
        console.log(e);
      }
    },
    [onConfirm],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid>
            <Button
              variant="contained"
              onClick={() => setShowModal(false)}
              color="grey"
            >
              Cancel
            </Button>
          </Grid>
          <Grid>
            <SaveFormButton
              onSave={onDelete}
              disabled={confirmVal !== 'DELETE'}
              name={`Delete ${headerName}`}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `Delete ${headerName || ''}`,
          footer,
          onClose: () => setShowModal(false),
        }}
      >
        <Box mb={4}>
          Type <strong>DELETE</strong> to confirm that you want to delete this
        </Box>
        <Box>
          <CustomTextField
            label="Delete confirmation"
            variant="outlined"
            onChange={(e) => setConfirmVal(e.target.value)}
            value={confirmVal}
          />
        </Box>
      </DialogWithActions>
    ) : null;
  }, [confirmVal, headerName, modal, onDelete]);

  return { modal: modalElement, showModal: setShowModal };
};

// eslint-disable-next-line import/no-unused-modules
export default useDeleteConfirm;
