import React from 'react';
import Routes from './Routes';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  lighten,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from 'redux/store';
import UserAuth from 'components/UserAuth/UserAuth';
import { useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { themeColors } from 'utils/consts';

const baseMuiTheme = createTheme();
// const breakpoints = createBreakpoints({});

const buildTheme = ({
  primaryColor,
  secondaryColor,
  accentColor,
  linkColor,
}) => {
  const buttonOutlineOverrides = {
    borderColor: baseMuiTheme.palette.grey[400],
    color: '#4F4F4F',
    '&:hover': {
      borderColor: '#4547DC',
    },
    '&:active': {
      borderColor: accentColor,
    },
  };
  const theme = createTheme({
    palette: {
      // mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: primaryColor,
        gradients: {
          main: `linear-gradient(93.48deg, ${primaryColor} 8.65%, ${secondaryColor} 99.32%)`,
        },
      },
      secondary: {
        main: secondaryColor || '#5D26A4',
      },
      accent: {
        main: accentColor,
      },
      link: {
        main: linkColor,
      },
      money: {
        main: '#00C805',
      },
      success: {
        main: themeColors.moneyColor,
      },
      danger: {
        main: '#D0342C',
      },
      warning: {
        main: '#D0342C',
      },
      error: {
        main: '#D0342C',
      },
      grey: {
        100: '#F0F2F5',
        main: baseMuiTheme.palette.grey[300],
        dark: baseMuiTheme.palette.grey[400],
      },
      text: {
        primary: 'rgb(47, 50, 59)',
        secondary: 'rgba(47, 50, 59, .54)',
        disabled: 'rgba(47, 50, 59, .38)',
        hint: 'rgba(47, 50, 59, .38)',
      },
    },
    typography: {
      fontFamily: 'Lato, Helvetica, Arial, sans-serif',
      body1: {
        fontSize: '1.125rem',
      },
      h5: {
        fontWeight: '300',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            img {
              max-width: 100%
            },
          },
          `,
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            [baseMuiTheme.breakpoints.down('md')]: {
              minWidth: '100px',
            },
            '&:hover': {
              color: themeColors.secondaryColor,
            },

            selected: {
              color: themeColors.primaryColor,
            },
          },
          textColorPrimary: {
            color: themeColors.linkColor,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiTab-root': {
              minHeight: '48px',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'large',
          disableElevation: true,
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: '100px',
            textTransform: 'none',
          },
          containedPrimary: {
            backgroundColor: accentColor,
            '&:hover': {
              backgroundColor: linkColor,
            },
            '&:active': {
              backgroundColor: secondaryColor,
            },
          },
          outlinedPrimary: {
            ...buttonOutlineOverrides,
          },
          outlinedSecondary: {
            ...buttonOutlineOverrides,
          },
          outlinedSizeSmall: {
            ...buttonOutlineOverrides,
          },
          outlinedSizeLarge: {
            ...buttonOutlineOverrides,
          },
          outlined: {
            ...buttonOutlineOverrides,
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          size: 'large',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '100px',
            textTransform: 'none',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
          colorDefault: {
            backgroundColor: themeColors.accentLight,
            color: '#454545',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            backgroundColor: lighten(themeColors.primaryColor, 0.9),
            color: themeColors.primaryColor,
          },
          outlinedInfo: {
            borderColor: themeColors.primaryColor,
            color: themeColors.primaryColor,
            '& .MuiAlert-icon': {
              color: themeColors.primaryColor,
            },
          },
          // warning
          standardWarning: {
            backgroundColor: lighten(baseMuiTheme.palette.warning.light, 0.9),
            color: baseMuiTheme.palette.warning.light,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: baseMuiTheme.palette.grey[400],
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: themeColors.primaryColor,
            height: '4px',
          },
        },
      },
      '@global': {
        '.money-color': {
          color: themeColors.moneyColor,
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            color: linkColor,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .data-grid-header-no-padding .MuiDataGrid-columnHeaderTitleContainer':
              {
                padding: 0,
              },
            fontFamily: 'Lato, Helvetica, Arial, sans-serif',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          outlinedPrimary: {
            borderColor: themeColors.accentColor,
            color: themeColors.accentColor,
          },
        },
      },
      MuiDropzoneArea: {
        styleOverrides: {
          root: {
            minHeight: 'initial',
            padding: 0,
            border: 'none',
            transition: '.2s background-color ease-out',
          },
          text: {
            marginBottom: 0,
            marginTop: baseMuiTheme.spacing(1),
            ...baseMuiTheme.typography.subtitle1,
          },
          icon: {
            color: baseMuiTheme.palette.primary.main,
          },
          active: {
            border: 'none',
            color: baseMuiTheme.palette.common.white,
            backgroundColor: baseMuiTheme.palette.primary.main,
            backgroundImage: 'none',
            '& .MuiDropzoneArea-icon': {
              color: baseMuiTheme.palette.common.white,
            },
          },
          invalid: {
            border: 'none',
            backgroundColor: baseMuiTheme.palette.error.main,
            backgroundImage: 'none',
            '& .MuiDropzoneArea-icon': {
              color: baseMuiTheme.palette.common.white,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            // overflowX: 'hidden',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: baseMuiTheme.palette.common.white,
          },
          underline: {
            '&:before': {
              borderBottomColor: primaryColor,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            '&.Mui-checked': {
              color: accentColor,
            },
            color: '#DDDDDD',
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: accentColor,
            },
            color: '#DDDDDD',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            color: accentColor,
            '&:hover': {
              backgroundColor: lighten(accentColor, 0.8),
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: baseMuiTheme.palette.common.white,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiModal-backdrop': {
              background:
                'linear-gradient(95deg, rgba(21,70,166,0.9) 0%, rgba(93,38,164,0.9) 100%)',
            },
          },
        },
      },
    },
  });
  return theme;
};

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme:dark)');
  try {
    console.log(`Running ${process.env.SOURCE_VERSION}`);
    // eslint-disable-next-line no-empty
  } catch {}

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider
              theme={buildTheme({
                ...themeColors,
                prefersDarkMode,
              })}
            >
              <CssBaseline />
              <UserAuth>
                <Routes />
              </UserAuth>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </LocalizationProvider>
    </>
  );
};

export default App;
