import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import defaultMissing from 'images/default-missing.svg';
import { LoadingArea } from './Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const imageStyles = (width, height, objectFit) => {
  const styles = makeStyles(() => ({
    image: {
      position: 'relative',
      maxHeight: height,
      maxWidth: width,
      margin: 'auto',
      '& img': {
        borderRadius: '5px',
        maxHeight: height,
        maxWidth: width,
        objectFit: objectFit,
      },
    },
  }));
  return styles();
};
const ImageFallback = ({
  src,
  fallback,
  alt,
  width = '10rem',
  height = '10rem',
  objectFit = 'cover',
}) => {
  const classes = imageStyles(width, height, objectFit);
  const missing = fallback || defaultMissing;

  const addImageFallback = (e) => {
    e.target.src = missing;
  };
  return (
    <div className={classes.image}>
      <LazyLoadImage
        src={src}
        alt={alt}
        onError={addImageFallback}
        placeholder={<LoadingArea open />}
      />
    </div>
  );
};

export default ImageFallback;
