import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';

import EditRounds from './EditRounds';

const EditFunding = ({ opportunity, onUpdate }) => {
  const { id } = opportunity;
  const [formRef, setFormRef] = useState({});

  const onSave = async (onError) => {
    try {
      const {
        values: { previousFunding, previousValuation },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await opportunitiesClient.update({
        id,
        data: {
          previousFunding,
          previousValuation,
        },
      });
      onUpdate((cache) => ({
        ...cache,
        ...response.data,
      }));
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <>
      <Box marginBottom={4}>
        <Form
          initialValues={opportunity}
          enableReinitialize
          setRef={setFormRef}
        >
          <Box marginBottom={2}>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Previous Funding Information
              </Typography>
              <Typography variant="caption">
                Funding information for the opportunity prior to the current
                round
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid xs={12} sm={6} md={4}>
                <FormikCurrencyInput
                  variant="filled"
                  name="previousFunding"
                  label="Total Previous Funding"
                  required
                />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <FormikCurrencyInput
                  variant="filled"
                  name="previousValuation"
                  label="Valuation at Previous Funding"
                />
              </Grid>
            </Grid>
          </Box>

          <Box marginBottom={2}>
            <Box marginTop={4}>
              <SaveFormButton onSave={onSave}></SaveFormButton>
            </Box>
          </Box>
        </Form>
      </Box>
      <hr></hr>

      <Box marginBottom={4} marginTop={4}>
        <Box marginTop={2}>
          <EditRounds
            {...{
              opportunity,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default EditFunding;
