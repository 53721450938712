import { Box, TableCell, TableSortLabel } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!comparator) return array;
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedHeaderCells = ({ columns, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      {columns.map((column) => {
        const { id, label, ...others } = column;
        return (
          <TableCell
            key={id}
            sortDirection={orderBy === id ? order : false}
            {...others}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : 'asc'}
              onClick={createSortHandler(id)}
            >
              <Box>{label}</Box>
              {orderBy === id ? (
                <span
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        );
      })}
    </>
  );
};

const useTableSort = ({
  columns,
  rows,
  page = 0,
  pageCount = 20,
  itemFilter = null,
  defaultOrder = 'asc',
  defaultOrderBy = null,
  filterTerms = null,
}) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const { sorted, headerCells } = useMemo(() => {
    const handleRequestSort = (event, property) => {
      setOrderBy((orderBy) => {
        setOrder((order) => {
          return orderBy == property && order === 'asc' ? 'desc' : 'asc';
        });
        return property;
      });
    };

    let filtered = itemFilter ? rows.filter(itemFilter) : rows;
    let items = stableSort(filtered, getComparator(order, orderBy));

    const headerCells = (
      <EnhancedHeaderCells
        columns={columns}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
    );
    return { sorted: items, headerCells };
  }, [columns, rows, order, orderBy, itemFilter]);
  const pagedItems = useMemo(() => {
    const sortedItems =
      pageCount > 0
        ? sorted.slice(page * pageCount, (page + 1) * pageCount)
        : sorted;
    return sortedItems;
  }, [sorted, page, pageCount]);

  useEffect(() => {
    setOrderBy(defaultOrderBy);
    setOrder(defaultOrder);
  }, [filterTerms, defaultOrder, defaultOrderBy]);

  return { pagedItems, sorted, headerCells, itemCount: sorted.length };
};
export default useTableSort;
