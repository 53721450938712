import React, { useState } from 'react';

import { Box } from '@mui/material';

import ManagementPicker from './ManagementPicker';
import ManagementEdit from './ManagementEdit';

import DialogWithTitle from 'components/ui/DialogWithTitle';

const useEditModalManagmentStructure = ({ groupId, onChange, onClose }) => {
  const [editManagement, setEditManagement] = useState(null);

  return {
    setEditManagement,
    managementModal: (
      <DialogWithTitle
        open={!!editManagement}
        onClose={() => {
          setEditManagement(null);
          if (onClose) onClose();
        }}
        title="Create Management Structure"
        fullWidth
      >
        <ManagementEdit
          onClose={() => {
            setEditManagement(null);
          }}
          onChange={onChange}
          {...{ groupId, data: editManagement }}
        ></ManagementEdit>
      </DialogWithTitle>
    ),
  };
};

const StructureSetup = ({ groupId, children, onChange, open, onClose }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { setEditManagement, managementModal } = useEditModalManagmentStructure(
    { groupId, onChange, onClose },
  );

  return (
    <>
      <Box onClick={() => setShowAddModal(true)}>{children}</Box>
      {managementModal}
      <DialogWithTitle
        open={open || showAddModal}
        onClose={() => {
          setShowAddModal(false);
          if (onClose) onClose();
        }}
        title="Select Management Structure Type"
        fullWidth
      >
        <ManagementPicker
          onManagerPick={() => {
            setShowAddModal(false);
            setEditManagement({
              id: null,
              structureType: 'single',
            });
          }}
          onSeriesPick={() => {
            setShowAddModal(false);
            setEditManagement({
              id: null,
              structureType: 'series',
            });
          }}
        ></ManagementPicker>
      </DialogWithTitle>
    </>
  );
};

export default StructureSetup;
