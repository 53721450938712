import React, { useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Social from 'components/ui/Social';
import useMore from 'components/useMore';
import defaultPerson from 'images/default-person.svg';
import ImageFallback from 'components/ui/ImageFallback';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import EditLeader from 'views/EditOpportunity/components/EditLeader';

const OpportunitySectionLeadership = ({ opportunity }) => {
  const { leaders = [] } = opportunity || {};

  if (leaders.length === 0) {
    return null;
  }

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Leadership
        </Typography>
      </Box>
      <Box marginTop={4}>
        {leaders.map((leader) => (
          <Box key={leader.id} marginBottom={4}>
            <LeaderView data={leader} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export const LeaderView = ({ data, canEdit = false, onChange = () => {} }) => {
  const {
    photo,
    name,
    title,
    bio,
    email,
    otherUrl,
    twitterUrl,
    facebookUrl,
    linkedinUrl,
    websiteUrl,
    crunchbaseUrl,
    instagramUrl,
  } = data;

  const [editModal, showEdit] = useState(false);

  const [showMore, hasMore, setMore, setRef, checkMore] = useMore();

  const onUpdate = (data) => {
    onChange(data);
    checkMore();
  };
  return (
    <Box bgcolor="white" border="1px solid lightgray" padding={2}>
      {editModal ? (
        <DialogWithTitle
          open
          onClose={() => showEdit(false)}
          fullWidth
          title="Edit Leader"
        >
          <EditLeader
            onChange={onUpdate}
            onClose={() => showEdit(false)}
            {...{ data }}
          ></EditLeader>
        </DialogWithTitle>
      ) : null}
      <Grid container spacing={3}>
        <Grid>
          <ImageFallback
            src={photo}
            fallback={defaultPerson}
            alt=""
            width="6rem"
            height="6rem"
          />
        </Grid>
        <Grid xs>
          <Box marginBottom={2}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Typography color="primary">{name}</Typography>
                {email && <Typography variant="subtitle2">{email}</Typography>}
                <Typography variant="subtitle2">{title}</Typography>
              </Grid>
              {canEdit && (
                <Grid>
                  <Box marginRight={2}>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showEdit((x) => !x)}
                    >
                      Edit
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box>
            <Box
              maxHeight={showMore ? '' : '4rem'}
              overflow={showMore ? '' : 'hidden'}
              ref={setRef}
            >
              <Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>
                {bio}
              </Typography>
            </Box>
            {hasMore && (
              <Box marginBottom={2}>
                <Link
                  color="primary"
                  size="small"
                  onClick={() => setMore(!showMore)}
                  style={{ cursor: 'pointer' }}
                >
                  {showMore ? 'Less' : 'Read More'}
                </Link>
              </Box>
            )}
          </Box>
          {(otherUrl ||
            twitterUrl ||
            facebookUrl ||
            linkedinUrl ||
            websiteUrl) && (
            <Box marginTop={1}>
              <Social
                justify="flex-start"
                {...{
                  otherUrl,
                  twitterUrl,
                  facebookUrl,
                  linkedinUrl,
                  websiteUrl,
                  crunchbaseUrl,
                  instagramUrl,
                }}
              ></Social>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OpportunitySectionLeadership;
