import React, { useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import Form from 'components/Form/Form';

import FormItem from 'components/Form/components/FormItem/FormItem';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { FormikTextInput } from 'components/ui/CustomTextField';
import { useDispatch } from 'react-redux';
import { signIn } from 'redux/currentUser/actions';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import groupsClient from 'api/groups/groupsClient';
import GenericPortal from 'views/GenericPortal';
import EditSlug from './EditSlug';

const CreateGroup = () => {
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [needsConfirmed, setNeedsConfirmed] = useState(false);
  const [slug, setSlug] = useState();

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          firstName,
          lastName,
          email,
          password,
          passwordConfirmation,
          groupName,
          slug,
        },
        errors,
      } = await validateForm(formRef);
      if (password !== passwordConfirmation) {
        errors.push('Passwords do not match');
      }
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const result = await groupsClient.create({
        user: {
          firstName,
          lastName,
          email,
          password,
          passwordConfirmation,
        },
        group: {
          name: groupName,
          slug: slug,
        },
      });

      const group = result.data;

      const redirect = async () => {
        try {
          await dispatch(signIn({ email, password }));
          window.location.href = `/${group.slug}/`;
        } catch (e) {
          setNeedsConfirmed(email);
        }
      };

      setSuccess(true);
      setTimeout(redirect, 2000);

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <GenericPortal title={<>Get started</>}>
      {needsConfirmed ? (
        <Box textAlign="center">
          <Box marginBottom={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Awaiting email confirmation
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              An email has been sent to {needsConfirmed}. You should receive it
              shortly.
            </Typography>
          </Box>
        </Box>
      ) : success ? (
        <Box textAlign="center">
          <Box marginBottom={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Almost there!
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Please wait while we redirect you
            </Typography>
          </Box>
          <CircularProgress />
        </Box>
      ) : (
        <Form setRef={setFormRef}>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Manager Information
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormikTextInput
                  name="firstName"
                  label="First Name"
                  variant="filled"
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormikTextInput name="lastName" label="Last Name" required />
              </Grid>

              <Grid xs={12}>
                <FormikTextInput
                  name="email"
                  label="Email"
                  type="email"
                  required
                />
              </Grid>

              <Grid xs={12} md={6}>
                <FormikTextInput
                  name="password"
                  label="Password"
                  type="password"
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormikTextInput
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  type="password"
                  required
                />
              </Grid>
            </Grid>
            <Box padding={1}>
              <Typography variant="caption">
                Note: For existing users, please enter your current SmartCapital
                password
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Group Information
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormItem>
                  <FormikTextInput
                    name="groupName"
                    label="Group Name"
                    helperText="*Your group name can be changed later."
                    required
                  />
                </FormItem>
              </Grid>
              <Grid xs={12}>
                <EditSlug {...{ slug, setSlug }} />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={5}>
            <CenteredContentBox>
              <SaveFormButton
                name="Signup"
                onSave={onSave}
                variant="contained"
                disabled={!slug}
              ></SaveFormButton>
            </CenteredContentBox>
          </Box>
        </Form>
      )}
    </GenericPortal>
  );
};

export default CreateGroup;
