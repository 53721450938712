import React from 'react';

import { Box } from '@mui/material';

import Button from 'components/ui/Button/Button';

const TaxQuestions = ({ taxYear, handleStart }) => {
  const { year } = taxYear;

  return (
    <Box marginTop={4}>
      <Box
        minHeight="40vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box textAlign="center">
          <Box fontSize={20}>Was there a tax filing for the year {year}?</Box>

          <Box marginTop={2}>
            <Button
              style={{ marginRight: '1rem' }}
              onClick={() => {
                handleStart(true, 0);
              }}
              variant="contained"
              color="primary"
            >
              Upload Filing
            </Button>
            <Button
              style={{ marginRight: '1rem' }}
              onClick={() => {
                handleStart(false, 0);
              }}
              variant="outlined"
              color="primary"
              // disabled={isFirstYear}
            >
              No Filing
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TaxQuestions;
