import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { CustomSelect } from 'components/ui/CustomSelect';
import FormWizard from './FormWizard';
import roundsClient from 'api/roundsClient';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { useEditRound } from 'hooks/forms/useFormRound';
import Button from 'components/ui/Button/Button';
import { useCreateOpportunity } from 'hooks/forms/useFormCreateOpportunity';
import useFormTargetAmount from 'hooks/forms/useFormTargetAmount';
import { useSyndicateFormName } from './SyndicateFormName';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

const useSyndicateFormOpportunity = ({
  groupId,
  investment,
  opportunityId,
}) => {
  const { roundId, targetAmount, syndicateData, syndicateId } =
    investment || {};
  const { syndicateName } = syndicateData || {};
  const [selected, setSelection] = useState(roundId || null);
  console.log(`selected: ${selected}`);
  const [selectedOpportunity, setSelectedOpportunity] = useState(
    opportunityId || null,
  );
  const [opportunities, setOpportunities] = useState(null);
  const { form: investmentForm, validate: investmentValidate } =
    useFormTargetAmount({
      data: { targetAmount },
    });
  const { form: nameForm, validate: nameValidate } = useSyndicateFormName({
    investment,
    data: {
      syndicateId: syndicateId,
      syndicateName: investment?.syndicateName || syndicateName,
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await groupInvestmentsClient.index({
          groupId,
        });
        const response = await opportunitiesClient.index({ groupId });
        setOpportunities(
          response.data.filter(
            (x) =>
              !x.openInvestmentId ||
              data.some(
                (y) => y.id == x.openInvestmentId && y.syndicateData == null,
              ) ||
              x.id === selectedOpportunity,
          ),
        );
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId, selectedOpportunity]);

  const { onListUpdate: onOpportunityListUpdate } =
    useArrayUpdate(setOpportunities);
  const { modal: opportunityModal, showModal: showOpportunityModal } =
    useCreateOpportunity({
      data: opportunities?.find((x) => x.id == selectedOpportunity),
      shouldRedirect: false,
      onChange: (item) => {
        onOpportunityListUpdate(item);
        setSelectedOpportunity(item.id);
        showOpportunityModal(false);
      },
    });
  useEffect(() => {
    if (selectedOpportunity == -1) {
      showOpportunityModal({});
      setSelectedOpportunity(null);
    }
  }, [selectedOpportunity, setSelectedOpportunity, showOpportunityModal]);

  const [rounds, setRounds] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await roundsClient.index({
          opportunityId: selectedOpportunity,
        });
        setRounds(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (selectedOpportunity) {
      if (!roundId) {
        setRounds(null);
        setSelection(null);
      }
      fetchData();
    }
  }, [selectedOpportunity, roundId]);

  const { onListUpdate } = useArrayUpdate(setRounds);
  const { modal, showModal } = useEditRound({
    opportunityId: selectedOpportunity,
    onChange: (item) => {
      onListUpdate(item);
      setSelection(item.id);
    },
  });
  useEffect(() => {
    if (selected == -1) {
      showModal({});
      setSelection(null);
    }
  }, [selected, showModal]);

  const validate = useCallback(async () => {
    const errors = [];
    if (!selectedOpportunity || selectedOpportunity < 0) {
      errors.push('You must select an opportunity and a round');
    } else if (!selected || selected < 0) {
      errors.push('You must select a round');
    }
    const { values: investValues, errors: investErrors } =
      await investmentValidate();
    const { values: nameValues, errors: nameErrors } = await nameValidate();
    return {
      errors: [...errors, ...investErrors, ...nameErrors],
      values: {
        opportunityId: selectedOpportunity,
        roundId: selected > 0 ? selected : null,
        roundName: rounds.find((r) => r.id === selected)?.name,
        ...investValues,
        syndicateData: {
          ...investValues?.syndicateData,
          ...nameValues,
        },
      },
    };
  }, [investmentValidate, nameValidate, rounds, selected, selectedOpportunity]);
  const form = useMemo(
    () => (
      <Box>
        {modal}
        {opportunityModal}
        <Box marginTop={2}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            What opportunity is this syndicate investing in?
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption">
            Hint: This is usually a company or a project.
          </Typography>
        </Box>

        <Box>
          <Grid container spacing={3}>
            <Grid md={6} mt={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <CustomSelect
                    variant="filled"
                    label="Opportunity"
                    required
                    disabled={!!opportunityId}
                    value={selectedOpportunity}
                    onChange={(e) => setSelectedOpportunity(e.target.value)}
                  >
                    {opportunities?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                    <MenuItem key="new" value={-1}>
                      Create New Opportunity
                    </MenuItem>
                  </CustomSelect>
                </Box>
                <Box marginLeft={2} marginTop={2}>
                  <Button
                    onClick={() => showOpportunityModal(true)}
                    disabled={!selectedOpportunity || !!opportunityId}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={3} mb={0.5}>
            <Grid md={6}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <CustomSelect
                    variant="filled"
                    label="Opportunity Round"
                    required
                    disabled={!selectedOpportunity || !rounds}
                    value={selected}
                    onChange={(e) => setSelection(e.target.value)}
                  >
                    {rounds &&
                      rounds.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    <MenuItem key="new" value={-1}>
                      Create New Round
                    </MenuItem>
                  </CustomSelect>
                </Box>
                <Box marginLeft={2} marginTop={2}>
                  <Button
                    onClick={() =>
                      showModal(rounds.find((x) => x.id == selected))
                    }
                    disabled={!selected}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {investmentForm}
        <Box mt={4}>{nameForm}</Box>
      </Box>
    ),
    [
      modal,
      opportunityModal,
      opportunityId,
      selectedOpportunity,
      opportunities,
      rounds,
      selected,
      investmentForm,
      nameForm,
      showOpportunityModal,
      showModal,
    ],
  );
  return { form, validate };
};

const SyndicateFormOpportunity = ({
  data,
  opportunityId,
  groupId,
  investment,
  onNext,
  onBack = null,
  onClose,
  buttonName,
}) => {
  const { form, validate } = useSyndicateFormOpportunity({
    data,
    groupId,
    opportunityId,
    investment,
  });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormOpportunity;
