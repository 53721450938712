import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import useFormAddress from './useFormAddress';
import groupLocationsClient from 'api/groupLocationsClient';

const useFormGroupLocation = ({ data, showNickname = true }) => {
  const { id, location } = data || {};
  const [formRef, setFormRef] = useState({});

  const { form: addressForm, validate: addressValidate } = useFormAddress({
    data: location,
    prefix: '',
  });

  const validate = useCallback(async () => {
    const {
      values: { name },
      errors,
    } = await validateForm(formRef);

    const { values: locationValues, errors: locationErrors } =
      await addressValidate();

    return {
      errors: [...errors, ...locationErrors],
      values: {
        name: name || locationValues.name,
        location_attributes: locationValues,
      },
    };
  }, [formRef, addressValidate]);

  const form = useMemo(
    () => (
      <Box>
        <Form
          key={id}
          initialValues={data}
          enableReinitialize
          setRef={setFormRef}
        >
          {showNickname && (
            <Box marginTop={2}>
              <FormikTextInput
                name="name"
                label="Nickname"
                variant="filled"
                required
                placeholder="Main Office"
              />
            </Box>
          )}
          <Box marginTop={showNickname && 4}>
            <Typography>Address Information</Typography>
          </Box>
          <Box marginTop={2}>{addressForm}</Box>
        </Form>
      </Box>
    ),
    [data, id, addressForm, showNickname],
  );
  return { form, validate, addressValidate };
};

export const useEditGroupLocation = ({ groupId, onChange }) => {
  const [modal, showModal] = useState();
  const { form, validate } = useFormGroupLocation({
    data: modal,
    showNickname: false,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = modal?.id
          ? groupLocationsClient.update
          : groupLocationsClient.create;

        const response = await upsert({
          groupId,
          id: modal?.id,
          data: values,
        });
        const data = response.data;
        onChange(data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [groupId, modal?.id, onChange, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid>
            <Button
              variant="contained"
              onClick={() => showModal()}
              color="grey"
            >
              Cancel
            </Button>
          </Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={modal?.id ? 'Save' : 'Add Address'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${modal.id ? 'Edit' : 'Add'} Address`,
          // header,
          footer,
          onClose: () => showModal(),
        }}
      >
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [form, modal, onSave]);

  return { modal: modalElement, showModal, form, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormGroupLocation;
