import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Snackbar, IconButton, Button, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

import userInvestmentsClient from 'api/userInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  undo: {
    color: theme.palette.common.white,
  },
}));

const useTodoUndo = ({ updateInvestment }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [latestPass, setLatestPass] = useState();
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (snackPack.length && !latestPass) {
      // Set a new snack when we don't have an active one
      setLatestPass({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && latestPass && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, latestPass, open]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleUndo = useCallback(
    async (data) => {
      const { investment, userInvestment } = data;
      const { id: investmentId } = investment;
      const { id, interestAmount, isUndecided } = userInvestment || {};
      const parsedAmount = parseFloat(interestAmount || 0);
      try {
        if (!isUndecided || parsedAmount < 0) {
          const response = await userInvestmentsClient.update({
            id,
            investmentId,
            data: {
              interestAmount: 0,
              isUndecided: true,
            },
          });
          updateInvestment({ ...data, userInvestment: response.data });
        }
        setOpen(false);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    },
    [updateInvestment],
  );

  const handleExited = () => {
    setLatestPass(undefined);
  };
  const getSnackMessage = ({ groupName, syndicateName, opportunityName }) => {
    return `${groupName} - ${syndicateName || opportunityName}`;
  };
  const { Toast } = useMemo(() => {
    const Toast = (
      <Snackbar
        key={latestPass ? latestPass.key : undefined}
        ContentProps={{
          style: {
            backgroundColor: theme.palette.accent.main,
            fontColor: '#fff',
          },
        }}
        open={open}
        autoHideDuration={null}
        onClose={handleClose}
        message={latestPass ? getSnackMessage(latestPass) : undefined}
        action={
          <React.Fragment>
            <Button
              size="small"
              variant="text"
              className={classes.undo}
              onClick={() => handleUndo(latestPass)}
            >
              UNDO
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        TransitionProps={{
          onExited: handleExited,
        }}
      />
    );
    return { Toast };
  }, [
    classes.close,
    classes.undo,
    handleUndo,
    latestPass,
    open,
    theme.palette.accent.main,
  ]);
  return {
    handleUndo,
    handleExited,
    getSnackMessage,
    handleClose,
    open,
    setOpen,
    snackPack,
    setSnackPack,
    latestPass,
    Toast,
  };
};

export default useTodoUndo;
