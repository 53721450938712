import React, { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';

import opportunitiesClient from 'api/opportunitiesClient';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { usePhoto } from 'components/ui/PhotoPicker';
import { FormikTextInput } from 'components/ui/CustomTextField';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';
import defaultPerson from 'images/default-person.svg';

const EditLeader = ({ opportunityId, data, onClose, onChange }) => {
  const defaultValues = {
    ...data,
    name: data.user?.name || data.name,
    email: data.user?.email || data.email,
  };
  const { id, user } = defaultValues;
  const { currentPhoto, photoPicker } = usePhoto(data, '10rem', defaultPerson);

  // const [isAdmin, setAdmin] = useState(data.isAdmin);

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          name,
          title,
          email,
          facebookUrl,
          linkedinUrl,
          twitterUrl,
          crunchbaseUrl,
          instagramUrl,
          websiteUrl,
          bio,
        },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const upsert = id
        ? opportunitiesClient.updateLeader
        : opportunitiesClient.createLeader;

      const response = await upsert({
        id,
        opportunityId,
        data: {
          name,
          title,
          email,
          facebookUrl,
          linkedinUrl,
          twitterUrl,
          crunchbaseUrl,
          instagramUrl,
          websiteUrl,
          bio,
          isAdmin: false,
          photo: currentPhoto.data,
          photoFileName: currentPhoto.name,
        },
      });
      const data = response.data;
      onChange(data);

      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const handleDelete = useCallback(
    async (onError) => {
      try {
        await opportunitiesClient.deleteLeader({ id });
        onChange({ id, isDeleted: true }, 'Delete Successful');
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    },
    [id, onChange, onClose],
  );

  return (
    <ActionWithConfirm onAction={handleDelete}>
      {({ actionButton }) => (
        <Form
          initialValues={defaultValues}
          enableReinitialize
          setRef={setFormRef}
        >
          <Box>
            <Grid container spacing={3}>
              <Grid xs={6}>
                <FormikTextInput
                  name="name"
                  label="Full Name"
                  disabled={user?.name}
                  required
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput name="title" label="Title" />
              </Grid>

              <Grid container xs={12}>
                <Grid xs={8}>
                  <Grid container direction="column" spacing={3}>
                    <Grid xs={12}>
                      <FormikTextInput
                        name="email"
                        label="Email"
                        disabled={!!user}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <FormikTextInput
                        name="bio"
                        label="Short Bio"
                        multiline
                        maxRows={5}
                        minRows={5}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={4}>{photoPicker}</Grid>
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="facebookUrl"
                  label="Facebook URL"
                  url
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="linkedinUrl"
                  label="LinkedIn URL"
                  url
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="twitterUrl"
                  label="Twitter URL"
                  url
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="crunchbaseUrl"
                  label="Crunchbase URL"
                  url
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="instagramUrl"
                  label="Instagram URL"
                  url
                />
              </Grid>
              <Grid xs={6}>
                <FormikTextInput
                  variant="filled"
                  name="websiteUrl"
                  label="Website URL"
                  url
                />
              </Grid>
            </Grid>

            <Box marginTop={5}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid>
                  <Button variant="contained" onClick={onClose} color="grey">
                    Cancel
                  </Button>
                </Grid>
                {id && <Grid>{actionButton}</Grid>}
                <Grid>
                  <SaveFormButton
                    onSave={onSave}
                    name={id ? 'Save' : 'Add'}
                  ></SaveFormButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Form>
      )}
    </ActionWithConfirm>
  );
};

export default EditLeader;
