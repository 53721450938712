import React from 'react';
import { Box, useTheme } from '@mui/material';
import { FaCircle } from 'react-icons/fa';
import { FiledStatuses } from './InvestorTaxesList';

export const StatusItem = ({ color, content, styles }) => {
  return (
    <Box color={color} display="flex" alignItems="center" style={styles}>
      {content ? (
        <>
          <Box mr={1} display="flex" flexDirection="column" alignItems="center">
            <FaCircle />
          </Box>
          <Box>{content}</Box>
        </>
      ) : (
        <Box textAlign="center" width="3rem" style={{ color: 'darkgray' }}>
          N/A
        </Box>
      )}
    </Box>
  );
};

const getStatusColor = ({ value, theme }) => {
  switch (value) {
    case FiledStatuses.NOT_FILED:
      return theme.palette.accent.main;
    case FiledStatuses.SKIPPED:
      return theme.palette.text.primary;
    case FiledStatuses.FILED:
      return theme.palette.success.main;
    default:
      return theme.palette.text.primary;
  }
};

const StatusCell = ({ value }) => {
  const theme = useTheme();
  const color = getStatusColor({ value, theme });
  return <StatusItem color={color} content={value} />;
};

export default StatusCell;
