import React, { useRef, useState } from 'react';
import { Paper, Box, Container, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import qs from 'query-string';
import Form from 'components/Form/Form';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { withRouter } from 'react-router-dom';
import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import userClient from 'api/user/userClient';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(2),
    background: theme.palette.primary.gradients.main,
    color: 'white',
  },
}));

const ResetPassword = ({ location }) => {
  const classes = useStyles();
  const [message, setMessage] = useState();

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { password, passwordConfirmation },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const { search } = location;
      const urlParams = qs.parse(search);
      const authHeaders = {
        'token-type': 'Bearer',
        ...urlParams,
      };

      const response = await userClient.resetPassword({
        password,
        passwordConfirmation,
        authHeaders,
      });
      const { data } = response;
      const { message } = data;

      setMessage(message);

      // const { uid } = urlParams;
      // if (uid) await dispatch(signIn({ email: uid, password }));
      // await dispatch(getCurrentUser());

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const submitButton = useRef(null);
  const handleSubmit = () => {
    submitButton.current.click();
  };

  return (
    <Container maxWidth="sm" style={{ height: '100%' }}>
      <CenteredContentBox fullWidth>
        <Paper>
          <Box className={classes.header}>Reset Password</Box>
          <Box className={classes.root}>
            <Form setRef={setFormRef} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <FormikTextInput
                    name="password"
                    label="Password"
                    type="password"
                    required
                  />
                </Grid>
                <Grid xs={12}>
                  <FormikTextInput
                    name="passwordConfirmation"
                    label="Password"
                    type="password"
                    required
                  />
                </Grid>
              </Grid>
            </Form>
            <Box marginTop={5} textAlign="center">
              {message && <Box marginBottom={2}>{message}</Box>}
              {message ? (
                <Button variant="contained" color="primary" href="/sign-in">
                  Go to Sign in
                </Button>
              ) : (
                <Grid container justifyContent="center" spacing={3}>
                  <Grid>
                    <SaveFormButton
                      onSave={onSave}
                      name={'Reset Password'}
                      buttonRef={submitButton}
                    ></SaveFormButton>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Paper>
      </CenteredContentBox>
    </Container>
  );
};

export default withRouter(ResetPassword);
