import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';

import FormWizard from './FormWizard';
import { CustomSelect } from 'components/ui/CustomSelect';
import { useEditGroupLocation } from 'hooks/forms/useFormGroupLocation';
import groupLocationsClient from 'api/groupLocationsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';
import DisplayAddress from 'components/ui/DisplayAddress';
import Button from 'components/ui/Button/Button';
import { useArrayUpdate } from 'hooks/useListUpdate';

const useSyndicateFormBusiness = ({ groupId, data }) => {
  const { id, business } = data || {};
  const { id: businessId } = business || {};

  const [list, setList] = useState(null);
  const [selected, setSelection] = useState(businessId || null);

  const hasCustom =
    id && business && !(list || []).find((x) => x.location.id == businessId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupLocationsClient.index({ groupId });
        const list = response.data;
        setList(list);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const getSelection = useCallback(() => {
    return (list || []).find((x) => x.location.id == selected);
  }, [list, selected]);

  const validate = useCallback(async () => {
    const item = getSelection();
    if (!item) {
      return {
        errors: ['You must select an existing address or add a new address'],
        values: {},
      };
    }

    return {
      errors: [],
      values: {
        business: item.location,
        businessId: item.location.id,
      },
    };
  }, [getSelection]);

  const { onListUpdate } = useArrayUpdate(setList);
  const { modal, showModal } = useEditGroupLocation({
    groupId,
    onChange: (item) => {
      onListUpdate(item);
      setSelection(item.location.id);
    },
  });

  useEffect(() => {
    if (selected == -1) {
      showModal({});
      setSelection(null);
    }
  }, [selected, showModal]);

  const form = useMemo(() => {
    return (
      <Box>
        {modal}
        <Box marginTop={2}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            What is the business address of the Syndicate?
          </Typography>
        </Box>
        {list ? (
          <>
            <Box display="flex" marginTop={2}>
              <Box width="20rem">
                <CustomSelect
                  variant="filled"
                  label="Select Address"
                  required
                  value={selected}
                  onChange={(e) => setSelection(e.target.value)}
                >
                  {list.map((item) => (
                    <MenuItem key={item.location.id} value={item.location.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                  {hasCustom && (
                    <MenuItem key={businessId} value={businessId}>
                      --Custom--
                    </MenuItem>
                  )}
                  <MenuItem key="new" value={-1}>
                    Create New Address
                  </MenuItem>
                </CustomSelect>
              </Box>
              <Box marginLeft={2} marginTop={2}>
                <Button
                  onClick={() => showModal(getSelection())}
                  disabled={!selected}
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Box marginTop={2}>
              {selected > 0 && (
                <DisplayAddress address={getSelection().location} />
              )}
            </Box>
          </>
        ) : (
          <Box>
            <LoadingSpinner />
          </Box>
        )}
      </Box>
    );
  }, [businessId, getSelection, hasCustom, list, modal, selected, showModal]);

  return { form, validate };
};

const SyndicateFormBusiness = ({
  groupId,
  data,
  onNext,
  onBack,
  onClose,
  buttonName,
}) => {
  const { form, validate } = useSyndicateFormBusiness({ groupId, data });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormBusiness;
