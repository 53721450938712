import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { FaFileInvoiceDollar, FaDollarSign, FaPen } from 'react-icons/fa';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MailIcon from '@mui/icons-material/Mail';

import SubscriptionAmount from './SubscriptionAmount';

import SubscriptionType from './SubscriptionType';

import SubscriptionSign from './SubscriptionSign';
import SubscriptionInvestment from './SubscriptionInvestment';
import SubscriptionReview from './SubscriptionReview';
import SubscriptionForm from './SubscriptionForm';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';
import userInvestmentsClient from 'api/userInvestmentsClient';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';
import syndicatesClient from 'api/syndicatesClient';
import CustomizedSteppers from 'components/ui/CustomStepper';
import Header from 'components/ui/wizard/Header';
import { useProfile } from 'hooks/useAppState';

const defaultSteps = [
  {
    id: 'amount',
    name: 'Amount',
    icon: <FaDollarSign />,
  },
  {
    id: 'type',
    name: 'Investor Type',
    icon: <MailIcon />,
  },
  {
    id: 'info',
    name: 'Investor Info',
    icon: <PeopleIcon />,
  },
  {
    id: 'review',
    name: 'Review',
    icon: <FaFileInvoiceDollar />,
  },
  {
    id: 'signatures',
    name: 'Required Signatures',
    icon: <FaPen />,
  },
  {
    id: 'investment',
    name: 'Investment',
    icon: <AttachMoneyIcon />,
  },
];

const getStep = (userInvestment, syndicate, profile) => {
  const {
    requestedUnits,
    entityType,
    dateInvestorInfo,
    dateReviewed,
    subscription,
  } = userInvestment || {};
  const { groupInvestment } = syndicate || {};
  const { earlyPayment } = groupInvestment || {};
  const { isAccredited } = profile || {};
  const { signatures = [] } = subscription || {};

  const investorSigned = signatures.find(
    (x) => x.memberType === 'person',
  )?.dateSigned;
  const managerSigned = signatures.find(
    (x) => x.memberType === 'manager',
  )?.dateSigned;

  if (investorSigned && (earlyPayment || managerSigned)) return 6;
  if (dateReviewed) return 5;
  if (dateInvestorInfo && isAccredited) return 4;
  if (entityType) return 3;
  if (requestedUnits > 0) return 2;
  return 1;
};
const SubscriptionWizard = ({
  syndicateId,
  userInvestment,
  investmentId,
  photo,
  onChange,
  onClose,
}) => {
  const { id } = userInvestment || {};
  const profile = useProfile((state) => state);

  const [step, setStep] = useState();
  const [steps, setSteps] = useState();

  const [syndicate, setSyndicate] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.get({
          id: syndicateId,
        });
        setSyndicate(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };

    if (syndicateId) {
      fetchData();
    }
  }, [syndicateId]);

  useEffect(() => {
    if (!step && syndicate) {
      const step = getStep(userInvestment, syndicate, profile);

      const steps = [...defaultSteps].map((x, i) => ({
        ...x,
        completed: i < step - 1,
      }));
      setSteps(steps);
      setStep(step);
    }
  }, [userInvestment, syndicate, step, profile]);

  if (!syndicate || !step || !profile) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  const goStep = async (isBack) => {
    setStep((prevState) => {
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(prevState);
      if (!isBack) {
        updatedSteps[prevIndex] = {
          ...updatedSteps[prevIndex],
          completed: true,
        };
      }
      setSteps(updatedSteps);
      return prevState + (isBack ? -1 : 1);
    });
  };
  const nextStep = () => {
    goStep();
  };
  const prevStep = () => {
    goStep(true);
  };

  const onDelete = async () => {
    try {
      const response = await userInvestmentsClient.void_subscription({
        id,
        data: {
          requestedUnits: 0,
          interestAmount: 0,
          dateReviewed: null,
          investorName: null,
          entityType: null,
        },
      });
      onChange(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  };

  return (
    <ActionWithConfirm
      onAction={onDelete}
      action={step >= 5 ? 'Restart Subscription' : 'Restart'}
    >
      {({ actionButton }) => (
        <>
          <Header>
            <Box textAlign="center" marginBottom={5}>
              {photo && (
                <Box marginBottom={2}>
                  <img alt="" src={photo} style={{ height: '5rem' }} />
                </Box>
              )}
              <Box>
                <CustomizedSteppers steps={steps} activeStep={step - 1} />
              </Box>
            </Box>
          </Header>
          {step === 1 && (
            <SubscriptionAmount
              {...{
                actionButton,
                syndicate,
                investmentId,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange({ ...data, isNew: !id });
                  nextStep();
                },
                onBack: prevStep,
              }}
            ></SubscriptionAmount>
          )}
          {step === 2 && (
            <SubscriptionType
              {...{
                actionButton,
                syndicate,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange(data);
                  nextStep();
                },
                onBack: prevStep,
              }}
            ></SubscriptionType>
          )}
          {step === 3 && (
            <SubscriptionForm
              {...{
                actionButton,
                syndicate,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange(data);
                  nextStep();
                },
                onBack: prevStep,
              }}
            ></SubscriptionForm>
          )}
          {step === 4 && (
            <SubscriptionReview
              {...{
                actionButton,
                syndicate,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange(data);
                  nextStep();
                },
                onBack: prevStep,
              }}
            ></SubscriptionReview>
          )}
          {step === 5 && (
            <SubscriptionSign
              {...{
                actionButton,
                syndicate,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange(data);
                  nextStep();
                },
                onBack: prevStep,
              }}
            ></SubscriptionSign>
          )}
          {step === 6 && (
            <SubscriptionInvestment
              {...{
                actionButton,
                syndicate,
                userInvestment,
                onClose,
                onChange: (data) => {
                  onChange(data);
                  nextStep();
                },
                onBack: prevStep,
              }}
            />
          )}
        </>
      )}
    </ActionWithConfirm>
  );
};

export default SubscriptionWizard;
