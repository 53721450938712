import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Snackbar,
  Typography,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Tabs,
  Tab,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TablePagination from '@mui/material/TablePagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoteIcon from '@mui/icons-material/Note';
import {
  DataGridPro,
  gridPageCountSelector,
  gridFilteredSortedRowIdsSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/lab/PaginationItem';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { getMenuOptions } from './InvestorRow';
import { Alert } from '@mui/material';
import TabPanel from 'components/ui/TabPanel/TabPanel';
import {
  currencyFormatter,
  CustomNoRowsOverlay,
  useTableStyles,
} from 'views/SyndicatesIndex/SyndicatesIndex';
import moment from 'moment';
import groupUsersClient from 'api/groupUsersClient';
import { FaUserEdit } from 'react-icons/fa';
import { CustomGridColumnMenu } from 'components/ui/CustomGridColumnMenu';
import InvestorStatus from './InvestorStatus';
import InvestorName from './InvestorName';
import { useProfile } from 'hooks/useAppState';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import EmailLog from 'views/UserInvestment/EmailLog';
import InvestorNotes from './InvestorNotes';

const Actions = ({ onSuccess, onError, investor, onEdit }) => {
  const isSmartcapital = useProfile((state) => state.isAdmin);
  const [actionEl, setActionEl] = useState();

  const onConfirm = useCallback(
    async ({ id, email }) => {
      try {
        const response = await groupUsersClient.confirm({ id });
        onSuccess(response.data, `${email} Confirmed`);
      } catch (e) {
        onError(e, `Confirm error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onResend = useCallback(
    async ({ id, email }) => {
      try {
        const response = await groupUsersClient.resend({ id });
        onSuccess(response.data, `Invitation sent to ${email}`);
      } catch (e) {
        onError(e, `Invitation error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onReActivate = useCallback(
    async ({ id }) => {
      try {
        const response = await groupUsersClient.reactivate({ id });
        onSuccess(response.data, `User reactivated`);
      } catch (e) {
        onError(e, `Invitation error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onSuspend = useCallback(
    async ({ id, email, dateConfirmed }) => {
      try {
        const response = await groupUsersClient.suspend({ id });
        onSuccess(
          response.data,
          `${email} ${dateConfirmed ? 'Suspended' : 'Rejected'}`,
        );
      } catch (e) {
        onError(e, `Suspend error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onChangePermission = useCallback(
    async ({ id, email, isAdmin }) => {
      try {
        if (isAdmin) {
          await groupUsersClient.reactivate({ id });
        }
        const response = await groupUsersClient.update({
          id,
          data: {
            isAdmin,
          },
        });
        onSuccess(response.data, `${email} permission changed`);
      } catch (e) {
        onError(e, `Permission change error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onChangeSuper = useCallback(
    async ({ id, email, isAdmin }) => {
      try {
        const response = await groupUsersClient.updateSuper({
          id,
          isAdmin,
        });
        onSuccess(response.data, `${email} permission changed`);
      } catch (e) {
        onError(e, `Permission change error: ${getServerResponseErrors(e)}`);
      }
    },
    [onSuccess, onError],
  );

  const onPermission = useCallback(
    (val) => {
      if (onChangePermission) {
        onChangePermission({
          ...investor,
          isAdmin: val,
        });
      }
    },
    [investor, onChangePermission],
  );

  const onSuperPermission = useCallback(
    (val) => {
      if (onChangeSuper) {
        onChangeSuper({
          ...investor,
          isAdmin: val,
        });
      }
    },
    [investor, onChangeSuper],
  );

  const menuOptions = getMenuOptions({
    investor,
    onResend,
    onReActivate,
    onSuspend,
    onConfirm,
    status: investor.status.toLowerCase(),
    setActionEl,
  });
  return (
    <>
      <IconButton
        title="Edit Investor"
        onClick={() => onEdit(investor.data)}
        size="large"
      >
        <FaUserEdit />
      </IconButton>
      <IconButton
        title="More Options"
        onClick={(e) => setActionEl(e.currentTarget)}
        size="large"
      >
        <MoreHorizIcon />
      </IconButton>
      {actionEl && (
        <Menu
          id="menu"
          anchorEl={actionEl}
          open
          onClose={() => setActionEl(null)}
        >
          <MenuItem
            onClick={() => {
              onEdit(investor.data);
              setActionEl(null);
            }}
          >
            Edit
          </MenuItem>
          {isSmartcapital &&
            investor.isSmartcapital &&
            (investor.isSuperadmin ? (
              <MenuItem
                onClick={() => {
                  onSuperPermission(false);
                  setActionEl(null);
                }}
              >
                Remove Super Admin
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  onSuperPermission(true);
                  setActionEl(null);
                }}
              >
                Make Super Admin
              </MenuItem>
            ))}

          {investor.isAdmin ? (
            <MenuItem
              onClick={() => {
                onPermission(false);
                setActionEl(null);
              }}
            >
              Remove Admin
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                onPermission(true);
                setActionEl(null);
              }}
            >
              Make Admin
            </MenuItem>
          )}
          {menuOptions}
        </Menu>
      )}
    </>
  );
};

const statusValueOptions = [
  { value: 'requested access', label: 'Requested' },
  { value: 'pending', label: 'Invited' },
  { value: 'confirmed', label: 'Access' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'rejected', label: 'Rejected' },
];

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rows = useGridSelector(apiRef, gridFilteredSortedRowIdsSelector);
  const rowCount = rows.length;
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);
  return (
    <TablePagination
      component="div"
      count={rowCount}
      page={page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(e) => apiRef.current.setPageSize(e.target.value)}
      onPageChange={(_e, value) => apiRef.current.setPage(value - 1)}
      ActionsComponent={({ page, onPageChange }) => (
        <Pagination
          sx={{ ul: { flexWrap: 'nowrap', marginLeft: '1rem' } }}
          variant="outlined"
          size="small"
          showFirstButton
          showLastButton
          count={pageCount}
          page={page + 1}
          onChange={onPageChange}
        />
      )}
    />
  );
};

const CustomToolbar = ({ leftOfSearchComponent }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton sx={{ marginRight: 1, marginLeft: 1 }} />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ allColumns: true }}
      />
      <Box style={{ flex: 1 }} />
      <Box marginRight={1}>
        <Box>{leftOfSearchComponent}</Box>
      </Box>
      <GridToolbarQuickFilter
        {...{
          debounceMs: 500,
          variant: 'outlined',
          size: 'small',
          placeholder: 'Search Investors',
        }}
      />
    </GridToolbarContainer>
  );
};

const StyledPagination = withStyles({
  ul: {
    flexWrap: 'nowrap',
    marginLeft: '1rem',
  },
})(Pagination);

export const TablePageActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  return (
    <StyledPagination
      style={{ flexWrap: 'nowrap' }}
      color="primary"
      variant="outlined"
      page={page + 1}
      count={Math.ceil(count / rowsPerPage)}
      size="small"
      showFirstButton
      showLastButton
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => onPageChange(event, value - 1)}
    />
  );
};

const InvestorTable = ({
  investors,
  onEdit,
  onChange,
  onFail,
  sendEmail,
  tableRef,
  groupId,
}) => {
  const theme = useTheme();
  const rows = useMemo(
    () =>
      (investors || []).map((x) => ({
        id: x.id,
        data: x,
        name: x.name.toLowerCase().trim(),
        status: x.status,
        invested: parseFloat(x.totalInvested),
        investments: x.investmentCount,
        activityLast: x.activityLast,
        isAdmin: x.isAdmin,
        isSuperadmin: x.user.isAdmin,
        isSmartcapital: x.user.isSmartcapital,
        dateAdded: new Date(
          (x.dateJoined && x.dateConfirmed && x.dateJoined > x.dateConfirmed
            ? x.dateJoined
            : x.dateConfirmed) ||
            x.dateJoined ||
            x.dateConfirmed ||
            '01-01-1900',
        ),
      })),
    [investors],
  );
  const classes = useTableStyles();
  const [alertData, setAlertData] = useState({});
  const [investorNotes, showNotes] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    email: false,
  });
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const onError = useCallback(
    (e, message) => {
      console.log(getServerResponseErrors(e));
      setAlertData({ text: message, severity: 'error' });
      onFail();
    },
    [onFail],
  );
  const onSuccess = useCallback(
    (data, message) => {
      onChange(data);
      setAlertData({ text: message, severity: 'success' });
    },
    [onChange],
  );

  const muiColumns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'User',
        flex: 2,
        renderCell: ({ row }) => {
          const { data: investor, isSuperadmin } = row;
          const { photo, initials, name, email, phone, isAdmin, user } =
            investor || {};
          const { isAccredited } = user || {};
          return (
            <InvestorName
              {...{
                photo,
                initials,
                name,
                email,
                phone,
                isAdmin,
                isSuperadmin,
                isAccredited,
              }}
            />
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        valueGetter: ({ row }) => row.data.email,
      },
      {
        field: 'notes',
        headerName: ' ',
        flex: 0.75,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row: investor }) => (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<NoteIcon />}
            onClick={() => showNotes(investor)}
          >
            Notes
          </Button>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueGetter: ({ value }) => value.toLowerCase(),
        valueOptions: statusValueOptions,
        flex: 1,
        renderCell: ({ row, value }) => (
          <InvestorStatus
            {...{ status: value.toLowerCase(), email: row.data.email }}
          />
        ),
      },
      {
        field: 'invested',
        headerName: 'Total Invested',
        align: 'left',
        headerAlign: 'left',
        type: 'number',
        renderCell: ({ value }) => {
          return (
            <Typography
              style={{ color: theme.palette.money.main, fontSize: 'inherit' }}
            >
              {value ? currencyFormatter.format(value) : '-'}
            </Typography>
          );
        },
      },
      {
        field: 'investments',
        headerName: 'Investments',
        align: 'left',
        headerAlign: 'left',
        type: 'number',
      },
      {
        field: 'activityLast',
        headerName: 'Last Activity',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ value }) => {
          return value ? moment(value).format('MM-DD-yyyy') : '-';
        },
      },
      {
        field: 'dateAdded',
        headerName: 'Date Added',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ value }) => {
          return value ? moment(value).format('MM-DD-yyyy') : '-';
        },
      },

      {
        field: 'buttons',
        headerName: ' ',
        flex: 0.75,
        align: 'right',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row: investor }) => (
          <Actions {...{ investor, onEdit, onError, onSuccess }} />
        ),
      },
    ];
  }, [onEdit, onError, onSuccess, theme.palette.money.main]);
  return (
    <>
      {investorNotes && (
        <DialogWithTitle
          open
          onClose={() => showNotes(null)}
          title="User Investment Notes"
          fullWidth
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            style={{ marginBottom: 10 }}
          >
            <Tab label="Notes" value={0} />
            <Tab label="Email Log" value={1} />
          </Tabs>
          <TabPanel value={currentTab} index={0}>
            <InvestorNotes
              {...{
                user: investorNotes.data.user,
                onClose: () => showNotes(null),
              }}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <EmailLog
              {...{
                id: investorNotes.id,
                user: investorNotes.data.user,
                userId: investorNotes.data.userId,
                groupId,
                onClose: () => showNotes(null),
              }}
            />
          </TabPanel>
        </DialogWithTitle>
      )}
      <Box style={{ display: 'flex', height: '100%' }}>
        <Box style={{ flexGrow: 1 }}>
          <DataGridPro
            className={classes.root}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Pagination: CustomPagination,
              Toolbar: () => (
                <CustomToolbar leftOfSearchComponent={sendEmail} />
              ),
              ColumnMenu: CustomGridColumnMenu,
            }}
            initialState={{
              pagination: {
                pageSize: 10,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            apiRef={tableRef}
            rows={rows}
            getRowHeight={() => 'auto'}
            columns={muiColumns}
            disableSelectionOnClick
            autoHeight
            pagination
            disableColumnPinning
            disableColumnSelector
          />
          <Snackbar
            open={alertData && alertData.text}
            autoHideDuration={2000}
            onClose={() => setAlertData({})}
          >
            <Alert
              onClose={() => setAlertData({})}
              severity={alertData.severity}
            >
              {alertData.text}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

export default InvestorTable;
