import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import { FormikTextInput } from 'components/ui/CustomTextField';

const EditSocial = ({ opportunity, onUpdate }) => {
  const { id } = opportunity;

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          facebookUrl,
          linkedinUrl,
          twitterUrl,
          crunchbaseUrl,
          instagramUrl,
        },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const response = await opportunitiesClient.update({
        id,
        data: {
          facebookUrl,
          linkedinUrl,
          twitterUrl,
          crunchbaseUrl,
          instagramUrl,
        },
      });
      onUpdate((cache) => ({
        ...cache,
        ...response.data,
      }));
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <Form initialValues={opportunity} enableReinitialize setRef={setFormRef}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Typography variant="h6" color="primary">
            Social Links
          </Typography>
          <Typography variant="caption">
            These are the social links for the opportunity.
          </Typography>
        </Grid>
        <Grid xs={6}>
          <FormikTextInput
            variant="filled"
            name="facebookUrl"
            label="Facebook URL"
            url
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextInput
            variant="filled"
            name="linkedinUrl"
            label="LinkedIn URL"
            url
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextInput
            variant="filled"
            name="twitterUrl"
            label="Twitter URL"
            url
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextInput
            variant="filled"
            name="crunchbaseUrl"
            label="Crunchbase URL"
            url
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextInput
            variant="filled"
            name="instagramUrl"
            label="Instagram URL"
            url
          />
        </Grid>

        <Grid xs={12}>
          <SaveFormButton onSave={onSave}></SaveFormButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditSocial;
