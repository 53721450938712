import React from 'react';
import { Typography, Button, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useProfile } from 'hooks/useAppState';
import { formatter } from './formatterUtils';

const StateFilingsCard = ({
  shouldFile,
  blueSky,
  blueSkyEstimate,
  id,
  editClick,
}) => {
  const isAdmin = useProfile((state) => state.isAdmin);
  return (
    <Paper
      variant="outlined"
      square
      style={{ padding: '1rem', height: '100%' }}
    >
      <Grid container justifyContent="space-between">
        <Grid>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Blue Sky Filings:
          </Typography>
          <Typography variant="subtitle1">
            {shouldFile && blueSky.length > 0
              ? `${blueSky.join(', ')}`
              : 'None'}
          </Typography>
          <Typography variant="subtitle1">
            {formatter.format(blueSkyEstimate || 0)}
          </Typography>
        </Grid>
        <Grid>
          <Button
            color="primary"
            size="small"
            onClick={editClick}
            disabled={!!id && !isAdmin}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default StateFilingsCard;
