import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Fab,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';
import EditOpportunityDocuments from './EditOpportunityDocuments';
import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import { Add, DeleteForever } from '@mui/icons-material';
import moment from 'moment';
import TabPanel from 'components/ui/TabPanel/TabPanel';
import { Divider } from '@mui/material';

const RevenueCard = ({
  id,
  isActual,
  showARR,
  showMRR,
  showTTMR,
  showYearlyRevenue,
  showDelete,
  onDelete,
}) => {
  const isCurrent = moment().year(id).isSame(moment(), 'year');
  return (
    <Grid key={id} xs={12}>
      <Paper
        variant="outlined"
        style={{
          padding: '1rem',
          width: '100%',
          height: 'auto',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="primary">
            {id}
          </Typography>
          {showDelete && (
            <IconButton size="small" onClick={() => onDelete({ id })}>
              <DeleteForever />
            </IconButton>
          )}
        </Box>
        {isCurrent && isActual && (showYearlyRevenue || showMRR || showARR) ? (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormikDatePicker
                disableFuture
                maxDate={moment().endOf('day')}
                variant="filled"
                style={{
                  visibility: id != moment().year() ? 'hidden' : 'visible',
                }}
                name={`years.${id}.actual.revenueAsOfDate`}
                label="Revenue As Of Date"
              />
            </Grid>
          </Grid>
        ) : null}
        {showYearlyRevenue && (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormikCurrencyInput
                variant="filled"
                name={`years.${id}.${isActual ? 'actual' : 'projected'}.yearly`}
                label={`${
                  id > moment().year() ? 'Projected ' : ''
                }Yearly Revenue`}
              />
            </Grid>
          </Grid>
        )}
        {showMRR && (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormikCurrencyInput
                variant="filled"
                name={`years.${id}.${isActual ? 'actual' : 'projected'}.mrr`}
                label={
                  id > moment().year()
                    ? 'Projected MRR'
                    : 'Monthly Recurring Revenue'
                }
              />
            </Grid>
          </Grid>
        )}
        {showARR && (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormikCurrencyInput
                variant="filled"
                name={`years.${id}.${isActual ? 'actual' : 'projected'}.arr`}
                label={
                  id > moment().year()
                    ? 'Projected ARR'
                    : 'Annual Recurring Revenue'
                }
              />
            </Grid>
          </Grid>
        )}
        {showTTMR && (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormikCurrencyInput
                variant="filled"
                name={`years.${id}.actual.ttmr`}
                label="Trailing Twelve Month Revenue"
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

const EditFinancials = ({ opportunity, onUpdate }) => {
  const { id, revenueData } = opportunity;
  const { years } = revenueData;

  const [list, updateList] = useState({});
  useEffect(() => {
    if (years && years.length > 0) {
      const yearsObject = years.reduce((acc, year) => {
        acc[year.id] = year;
        return acc;
      }, {});
      updateList(yearsObject);
    } else {
      updateList({ [moment().year()]: { id: moment().year() } });
    }
  }, [years]);

  const onDelete = (item, projected) => {
    updateList((prev) => {
      const newList = { ...prev };
      if (projected && item.id === moment().year()) {
        delete newList[item.id].projected;
      } else {
        delete newList[item.id];
      }

      return newList;
    });
  };

  const onAdd = (year, isActual) => {
    // onListUpdate({ id: year, [isActual ? 'actual' : 'projected']: {} });
    updateList((prev) => {
      return {
        ...prev,
        [year]: {
          ...(prev[year] || {}),
          id: year,
          [isActual ? 'actual' : 'projected']: {},
        },
      };
    });
  };

  const [showCurrentYearly, setShowCurrentYearlyState] = useState(
    revenueData?.showCurrentYearly || false,
  );
  const [showCurrentMrr, setShowCurrentMRRState] = useState(
    revenueData?.showCurrentMrr || false,
  );
  const [showCurrentArr, setShowCurrentARRState] = useState(
    revenueData?.showCurrentArr || false,
  );
  const [showCurrentTtmr, setShowCurrentTTMRState] = useState(
    revenueData?.showCurrentTtmr || false,
  );
  const [showHistoricalYearly, setShowHistoricalYearlyState] = useState(
    revenueData?.showHistoricalYearly || false,
  );
  const [showHistoricalMrr, setShowHistoricalMRRState] = useState(
    revenueData?.showHistoricalMrr || false,
  );
  const [showHistoricalArr, setShowHistoricalARRState] = useState(
    revenueData?.showHistoricalArr || false,
  );
  const [showProjectedYearly, setShowProjectedYearlyState] = useState(
    revenueData?.showProjectedYearly || false,
  );
  const [showProjectedMrr, setShowProjectedMRRState] = useState(
    revenueData?.showProjectedMrr || false,
  );
  const [showProjectedArr, setShowProjectedARRState] = useState(
    revenueData?.showProjectedArr || false,
  );

  const [formRef, setFormRef] = useState({});
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const onSave = async (onError) => {
    try {
      const {
        values: { revenueTrailing, years },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const data = {
        revenueTrailing,
        showCurrentYearly: showCurrentYearly,
        showCurrentMrr: showCurrentMrr,
        showCurrentArr: showCurrentArr,
        showCurrentTtmr: showCurrentTtmr,
        showHistoricalYearly: showHistoricalYearly,
        showHistoricalMrr: showHistoricalMrr,
        showHistoricalArr: showHistoricalArr,
        showProjectedYearly: showProjectedYearly,
        showProjectedMrr: showProjectedMrr,
        showProjectedArr: showProjectedArr,
        years: Object.values(years).sort((a, b) => a.id - b.id),
      };

      const response = await opportunitiesClient.update({
        id,
        data: {
          revenueData: JSON.stringify(data),
        },
      });
      onUpdate((cache) => ({
        ...cache,
        ...response.data,
      }));
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const yearsInThePast = Object.values(list).filter(
    (x) => x.id < moment().year(),
  );
  const yearsInTheFutureInclusive = Object.values(list).filter(
    (x) => x.id >= moment().year() && x.projected,
  );
  const yearsInThePresent = Object.values(list).filter(
    (x) => x.id === moment().year(),
  );

  return (
    <>
      <Box marginBottom={4}>
        <Form
          initialValues={{
            revenueTrailing: revenueData?.revenueTrailing,
            years: list,
          }}
          enableReinitialize
          setRef={setFormRef}
        >
          <Box marginBottom={2}>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Revenue Information
              </Typography>

              <Typography variant="caption">
                These are revenue metrics for the opportunity.
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Current Revenue
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid>
                <CustomCheckBox
                  label="Show Yearly Revenue"
                  checked={showCurrentYearly}
                  onChange={() => setShowCurrentYearlyState((x) => !x)}
                />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Show MRR"
                  checked={showCurrentMrr}
                  onChange={() => setShowCurrentMRRState((x) => !x)}
                />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Show ARR"
                  checked={showCurrentArr}
                  onChange={() => setShowCurrentARRState((x) => !x)}
                />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Show TTMR"
                  checked={showCurrentTtmr}
                  onChange={() => setShowCurrentTTMRState((x) => !x)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {!yearsInThePresent.length && (
                <Grid
                  xs={12}
                  style={{
                    display: 'flex',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    variant="extended"
                    onClick={() => onAdd(moment().year(), true)}
                  >
                    <Add />
                    {moment().year()}
                  </Fab>
                </Grid>
              )}
              {yearsInThePresent
                .sort((a, b) => a.id - b.id)
                .map((item) => (
                  <RevenueCard
                    key={item.id}
                    id={item.id}
                    item={item.actual}
                    isActual
                    showYearlyRevenue={showCurrentYearly}
                    showMRR={showCurrentMrr}
                    showARR={showCurrentArr}
                    showTTMR={showCurrentTtmr}
                    onChange={updateList}
                  />
                ))}
            </Grid>
            <Divider />
            <Box marginTop={4}>
              <Grid container spacing={2} alignItems="center">
                <Tabs value={currentTab} onChange={handleTabChange}>
                  <Tab label="Historical Revenue" value={0} />
                  <Tab label="Projected Revenue" value={1} />
                </Tabs>
                <Grid xs={12}>
                  <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>
                      <Grid>
                        <CustomCheckBox
                          label="Show Yearly Revenue"
                          checked={showHistoricalYearly}
                          onChange={() =>
                            setShowHistoricalYearlyState((x) => !x)
                          }
                        />
                      </Grid>
                      <Grid>
                        <CustomCheckBox
                          label="Show MRR"
                          checked={showHistoricalMrr}
                          onChange={() => setShowHistoricalMRRState((x) => !x)}
                        />
                      </Grid>
                      <Grid>
                        <CustomCheckBox
                          label="Show ARR"
                          checked={showHistoricalArr}
                          onChange={() => setShowHistoricalARRState((x) => !x)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ overflowY: 'auto', maxHeight: '25rem' }}
                    >
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Fab
                          color="primary"
                          aria-label="add"
                          variant="extended"
                          onClick={() =>
                            onAdd(
                              Object.values(list)
                                .map((x) => x.id)
                                .sort()[0] - 1,
                              true,
                            )
                          }
                        >
                          <Add />
                          {Object.values(list)
                            .map((x) => x.id)
                            .sort()[0] - 1}
                        </Fab>
                      </Grid>
                      {yearsInThePast
                        .sort((a, b) => a.id - b.id)
                        .map((item) => {
                          const isLastYear = item.id === moment().year() - 1;
                          const nextItem = !isLastYear
                            ? yearsInThePast[
                                yearsInThePast
                                  .sort((a, b) => a.id - b.id)
                                  .findIndex((x) => x.id === item.id) + 1
                              ]
                            : null;
                          const temp = [];
                          if (nextItem) {
                            for (let i = item.id + 1; i < nextItem.id; i++) {
                              temp.push({
                                id: i,
                              });
                            }
                          } else {
                            for (
                              let i = item.id + 1;
                              i < moment().year();
                              i++
                            ) {
                              temp.push({
                                id: i,
                              });
                            }
                          }
                          return (
                            <>
                              <RevenueCard
                                key={item.id}
                                id={item.id}
                                isActual
                                item={item.actual}
                                showYearlyRevenue={showHistoricalYearly}
                                showMRR={showHistoricalMrr}
                                showARR={showHistoricalArr}
                                showDelete
                                onDelete={(item) => onDelete(item, false)}
                                onChange={updateList}
                              />
                              {temp &&
                                temp.map((x) => (
                                  <Grid
                                    key={`${x.id}-plus`}
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Fab
                                      color="primary"
                                      aria-label="add"
                                      variant="extended"
                                      onClick={() => onAdd(x.id, true)}
                                    >
                                      <Add />
                                      {x.id}
                                    </Fab>
                                  </Grid>
                                ))}
                            </>
                          );
                        })}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2}>
                      <Grid>
                        <CustomCheckBox
                          label="Show Yearly Revenue"
                          checked={showProjectedYearly}
                          onChange={() =>
                            setShowProjectedYearlyState((x) => !x)
                          }
                        />
                      </Grid>
                      <Grid>
                        <CustomCheckBox
                          label="Show MRR"
                          checked={showProjectedMrr}
                          onChange={() => setShowProjectedMRRState((x) => !x)}
                        />
                      </Grid>
                      <Grid>
                        <CustomCheckBox
                          label="Show ARR"
                          checked={showProjectedArr}
                          onChange={() => setShowProjectedARRState((x) => !x)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ overflowY: 'auto', maxHeight: '25rem' }}
                    >
                      {yearsInTheFutureInclusive
                        .sort((a, b) => a.id - b.id)
                        .map((item) => {
                          const isLast =
                            item.id ===
                            yearsInTheFutureInclusive
                              .map((x) => x.id)
                              .sort((a, b) => a.id - b.id)
                              .reverse()[0];
                          const nextItem = !isLast
                            ? yearsInTheFutureInclusive[
                                yearsInTheFutureInclusive
                                  .sort((a, b) => a.id - b.id)
                                  .findIndex((x) => x.id === item.id) + 1
                              ]
                            : null;
                          const temp = [];
                          if (nextItem && !isLast) {
                            for (let i = item.id + 1; i < nextItem.id; i++) {
                              temp.push({
                                id: i,
                              });
                            }
                          }
                          return (
                            <>
                              {item.projected && (
                                <RevenueCard
                                  key={item.id}
                                  id={item.id}
                                  item={item.projected}
                                  showYearlyRevenue={showProjectedYearly}
                                  showMRR={showProjectedMrr}
                                  showARR={showProjectedArr}
                                  showDelete
                                  onDelete={(item) => onDelete(item, true)}
                                  onChange={updateList}
                                />
                              )}
                              {/* If the list has no next year add plus button
                          need to add every year between the current year and the next year */}
                              {nextItem &&
                                temp.map((x) => (
                                  <Grid
                                    key={`${x.id}-plus`}
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Fab
                                      color="primary"
                                      aria-label="add"
                                      variant="extended"
                                      onClick={() => onAdd(x.id)}
                                    >
                                      <Add />
                                      {x.id}
                                    </Fab>
                                  </Grid>
                                ))}
                            </>
                          );
                        })}
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Fab
                          color="primary"
                          aria-label="add"
                          variant="extended"
                          onClick={() =>
                            onAdd(
                              Object.values(yearsInTheFutureInclusive)
                                .map((x) => x.id)
                                .sort()
                                .reverse()[0] + 1 || moment().year(),
                            )
                          }
                        >
                          <Add />
                          {Object.values(yearsInTheFutureInclusive)
                            .map((x) => x.id)
                            .sort()
                            .reverse()[0] + 1 || moment().year()}
                        </Fab>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box marginBottom={2}>
            <Box marginTop={4}>
              <SaveFormButton onSave={onSave}></SaveFormButton>
            </Box>
          </Box>
        </Form>
      </Box>
      <Divider />

      <Box marginBottom={4} marginTop={4}>
        <Box marginTop={2}>
          <EditOpportunityDocuments
            {...{
              opportunity,
              onUpdate,
            }}
            canEdit
          />
        </Box>
      </Box>
    </>
  );
};

export default EditFinancials;
