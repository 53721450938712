import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import syndicatesClient from 'api/syndicatesClient';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import LimitedLiabilityCompanyAgreements from './LimitedLiabilityCompanyAgreements';

const SyndicateSignatures = ({ syndicate, setSyndicate, investment }) => {
  const { id, operatingAgreementId, primaryManager, groupInvestment } =
    syndicate || {};

  const { acceptChecks, acceptWires, paymentCheck, paymentWire } =
    groupInvestment || {};
  const bankingSetup =
    (acceptChecks && paymentCheck) || (acceptWires && paymentWire);
  const {
    groupManagerPerson: { email },
  } = primaryManager || {};

  const [signatures, setSignatures] = useState(null);
  const { onListUpdate: onUpdate } = useArrayUpdate(setSignatures);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.get_document_citations({
          id: operatingAgreementId,
          params: { title: 'Operating Agreement Signature' },
        });
        setSignatures(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (operatingAgreementId) {
      fetchData();
    }
  }, [operatingAgreementId]);

  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.file_offering_agreements({ id });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const needsSigned =
    !signatures ||
    signatures.some((x) => x.signatures.some((y) => !y.dateSigned));

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box
          padding={3}
          paddingTop={2}
          style={{ overflowY: 'auto', maxHeight: '50vh' }}
        >
          <Box marginTop={2} marginBottom={2}>
            <Typography variant="subtitle1">
              The final step of the Syndicate creation process is for all
              managers to execute the Operating Agreement. To continue, each
              manager must sign.
            </Typography>
          </Box>
          <LimitedLiabilityCompanyAgreements
            {...{ syndicate, setSyndicate, investment }}
          />

          <Box
            marginTop={2}
            borderBottom="1px solid lightgray"
            style={{ paddingBottom: '0.5rem' }}
          >
            <Typography color="primary">Manager Signature(s)</Typography>
          </Box>

          {signatures && (
            <>
              {signatures.length == 1 ? (
                <SignatureManagerRow
                  key={signatures[0].id}
                  data={signatures[0]}
                  email={email}
                  onUpdate={onUpdate}
                />
              ) : (
                signatures.map((signature) => (
                  <SignaturePersonRow
                    key={signature.id}
                    data={signature}
                    email={email}
                    onUpdate={onUpdate}
                  />
                ))
              )}
            </>
          )}
        </Box>
        <Box>
          <Box marginTop={2}>
            <CustomCheckBox
              label={
                <Box fontSize="1rem">
                  By checking this box, you confirm that you have reviewed all
                  signatures and that they are correct in form and validity.
                </Box>
              }
              checked={checked}
              onChange={() => setChecked((x) => !x)}
            />
          </Box>
          <Box padding={2} marginTop={2}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid></Grid>
              <Grid>
                <SaveFormButton
                  onSave={onSave}
                  name={'Start Accepting Investments'}
                  disabled={needsSigned || !checked || !bankingSetup}
                ></SaveFormButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const SignaturePersonRow = ({ data, onUpdate }) => {
  const { signatures } = data;
  const { signerName } = signatures[0];
  const doc = useSyndicateDocument({
    id: data.id,
    data: data,
    documentName: signerName,
    role: 'person',
    onUpdate,
  });

  return (
    <Box
      borderBottom="1px solid lightgray"
      style={{ padding: '1rem', paddingLeft: '' }}
    >
      <SyndicateDocumentItem {...{ data: doc }} />
    </Box>
  );
};

const SignatureManagerRow = ({ data, onUpdate }) => {
  const { signatures } = data;
  const { signerName } = signatures[0];

  const doc = useSyndicateDocument({
    id: data.id,
    data: data,
    documentName: signerName,
    role: 'manager',
    onUpdate,
  });

  return (
    <Box
      borderBottom="1px solid lightgray"
      style={{ padding: '1rem', paddingLeft: '' }}
    >
      <SyndicateDocumentItem {...{ data: doc }} />
    </Box>
  );
};

export default SyndicateSignatures;
