import React, { useMemo, useState } from 'react';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import { Box, Typography } from '@mui/material';
import { useSelectedGroup } from './useAppState';

const useEmailHelp = () => {
  const { groupName, contactEmail } = useSelectedGroup((state) => ({
    contactEmail: state.contactEmail,
    groupName: state.name,
  }));

  const [toggle, setToggle] = useState(false);
  const showSupport = () => {
    setToggle(true);
  };
  const hideSupport = () => {
    setToggle(false);
  };

  const toggleSupport = () => {
    setToggle((x) => !x);
  };

  const helpComponent = useMemo(
    () => (
      <>
        {toggle && (
          <DialogWithTitle open title="Help and Support" onClose={hideSupport}>
            <Box>
              <Box>
                <Typography>Application Support</Typography>
                <Typography variant="caption">
                  For application support please contact{' '}
                  <a href="mailto:success@smartcapitalx.com">
                    success@smartcapitalx.com
                  </a>
                </Typography>
              </Box>
              {contactEmail && (
                <Box marginTop={3}>
                  <Typography>{groupName} Support</Typography>
                  <Typography variant="caption">
                    For questions about {groupName} or related investments
                    please contact{' '}
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                  </Typography>
                </Box>
              )}
            </Box>
          </DialogWithTitle>
        )}
      </>
    ),
    [toggle, contactEmail, groupName],
  );

  return {
    helpComponent,
    showSupport,
    hideSupport,
    toggleSupport,
  };
};

export default useEmailHelp;
