import React, { useState, useEffect, useCallback } from 'react';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { Box, CircularProgress, Typography } from '@mui/material';
import opportunitiesClient from 'api/opportunitiesClient';
import { useSelectedGroup } from 'hooks/useAppState';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { withRouter, useHistory } from 'react-router-dom';

const CreateOpportunity = () => {
  const history = useHistory();
  const { groupId, slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const url = useCallback((url = '') => `/${slug}${url}`, [slug]);
  const [error, setError] = useState(false);

  const createOpportunity = useCallback(async () => {
    try {
      const response = await opportunitiesClient.create({
        groupId,
        data: {
          name: `New Opportunity`,
        },
      });
      history.replace(url(`/opportunity/${response.data.id}/edit`));
    } catch (e) {
      setError(getServerResponseErrors(e));
    }
  }, [groupId, url, history]);

  useEffect(() => {
    createOpportunity();
  }, [createOpportunity]);

  return (
    <Box height="100%">
      <CenteredContentBox>
        <Box textAlign="center">
          <Box marginBottom={3}>
            <CircularProgress />
          </Box>
          {!error ? (
            <Typography>Creating Opportunity</Typography>
          ) : (
            <Typography color="error">An error occurred: {error}</Typography>
          )}
        </Box>
      </CenteredContentBox>
    </Box>
  );
};

export default withRouter(CreateOpportunity);
