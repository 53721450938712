import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { validateForm } from 'components/Form/SaveFormButton';
import useFormAddress from 'hooks/forms/useFormAddress';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';

const useFormWireInstructions = ({ data }) => {
  const { id, bank, recipient, bankRecipient } = data || {};

  const { form: bankForm, validate: bankValidate } = useFormAddress({
    data: bank,
    prefix: 'Bank',
  });

  const { form: recipientForm, validate: recipientValidate } = useFormAddress({
    data: recipient || { name: bankRecipient },
    prefix: 'Recipient',
  });

  const [checked, setChecked] = useState(false);

  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { bankAccountNumber, bankRoutingNumber },
      errors,
    } = await validateForm(formRef);

    const { values: bankValues, errors: bankErrors } = await bankValidate();
    const { values: recipientValues, errors: recipientErrors } =
      await recipientValidate();

    return {
      errors: [...errors, ...bankErrors, ...recipientErrors],
      values: {
        bankAccountNumber,
        bankRoutingNumber,
        bank_attributes: bankValues,
        recipient_attributes: checked ? null : recipientValues,
      },
    };
  };

  const form = (
    <Box>
      <Box>
        {!id && (
          <Box>
            <CustomCheckBox
              name="useBusiness"
              label="Bank Recipient Information matches Management Address"
              checked={checked}
              onChange={() => setChecked((x) => !x)}
            />
          </Box>
        )}
        {!checked && (
          <Box>
            <Box marginTop={2}>{recipientForm}</Box>
          </Box>
        )}
      </Box>
      <Box marginTop={2}>
        <Box marginBottom={2}>
          <Typography>Bank Information</Typography>
        </Box>
        <Box marginBottom={1}>
          <Form initialValues={data} enableReinitialize setRef={setFormRef}>
            <Grid container spacing={2}>
              <Grid xs={3}>
                <FormikTextInput
                  name="bankAccountNumber"
                  label="Account Number"
                  variant="filled"
                  required
                />
              </Grid>
              <Grid xs={3}>
                <FormikTextInput
                  name="bankRoutingNumber"
                  label="ABA/Routing Number"
                  variant="filled"
                  required
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
        <Box>{bankForm}</Box>
      </Box>
    </Box>
  );

  return { form, validate };
};

export default useFormWireInstructions;
