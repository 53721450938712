import React from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useNameStyles = makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const InvestorMiniProfile = ({ data }) => {
  const classes = useNameStyles();
  const { userInfo, entityName } = data || {};
  const { photo, initials, name, email } = userInfo || {};
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Avatar
          style={{ fontSize: '16px' }}
          src={photo}
          className={classes.userAvatar}
        >
          {initials}
        </Avatar>
      </Box>
      <Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption" noWrap>
            {name}
          </Typography>
          <Typography variant="caption" noWrap>
            {email}
          </Typography>
          <Typography variant="caption" noWrap>
            {entityName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestorMiniProfile;
