import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash/isEqual';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { ManageTaxDocuments } from 'views/TaxDocuments';

import userInvestmentsClient from 'api/userInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { createStyles } from '@mui/styles';
import { useProfile } from 'hooks/useAppState';
import useEditTaxDocument from 'hooks/forms/useFormTaxDocument';
import moment from 'moment';
import { LoadingSpinner } from 'components/ui/Loading';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardWrapper: {
      border: '1px solid',
      borderColor: theme.palette.grey[400],
      borderRadius: 4,
      padding: 12,
    },
    money: {
      color: theme.palette.money.main,
    },
  }),
);

const InvestorTaxes = ({
  data,
  onChange,
  syndicateId,
  unitPrice,
  finalClosingYear,
}) => {
  const classes = useStyles();
  const { id } = data;
  const isSuperAdmin = useProfile((state) => state.isAdmin);

  const [investment, setInvestment] = useState(data);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.get({ id });
        setInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.taxDocuments({ id });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  const { onListUpdate } = useArrayUpdate(setDocuments);

  const { userInfo, acceptedUnits, entityName } = investment || {};

  const { name, email } = userInfo || {};

  useEffect(() => {
    if (investment && data && !isEqual(data, investment)) {
      onChange(investment);
    }
  }, [data, investment, onChange]);

  const { modal, showModal } = useEditTaxDocument({
    syndicateId,
    userInvestmentId: id,
    onChange: onListUpdate,
    finalClosingYear,
  });

  const currentYear = moment().year();

  if (!documents || !investment) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.cardWrapper}
      >
        <Grid>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography>{email}</Typography>
          <Typography>{entityName}</Typography>
        </Grid>
        <Grid>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Invested</Typography>
            <Typography variant="h6" className={classes.money}>
              {(
                parseFloat(acceptedUnits) * parseFloat(unitPrice)
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Units</Typography>
            <Typography variant="h6">{acceptedUnits}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid xs>
            <Box>
              <Typography variant="h6">Tax Documents</Typography>

              <Typography variant="caption">
                Tax documents intended to only be seen by managers and the
                investor
              </Typography>
            </Box>
          </Grid>
          <Grid>
            <Box>
              {modal}
              {isSuperAdmin && currentYear > finalClosingYear && (
                <Box marginTop={2}>
                  <Button
                    color="primary"
                    onClick={() => showModal({ form: 'other' })}
                  >
                    Add Document
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <ManageTaxDocuments
          {...{
            documents,
            showFiled: true,
            showModal,
            canEdit: isSuperAdmin,
          }}
        />
      </Box>
    </Box>
  );
};

export default InvestorTaxes;
