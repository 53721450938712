import React from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import makeStyles from '@mui/styles/makeStyles';

import IndividualLLC from 'images/IndividualLLC.png';
import SeriesLLC from 'images/SeriesLLC.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50rem',
    maxWidth: '100%',
  },
  header: {
    background: theme.palette.primary.gradients.main,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  contentContainer: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    paddingBottom: theme.spacing(4),
  },
}));

const ManagementPicker = ({ onManagerPick, onSeriesPick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.contentContainer}>
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="stretch"
        justifyContent="center"
      >
        <Grid>
          <Box
            padding={3}
            height="28rem"
            bgcolor="white"
            borderRadius={1}
            textAlign="center"
            width="22rem"
          >
            <Box marginBottom={2}>
              <img src={IndividualLLC} alt="" style={{ height: '8rem' }}></img>
            </Box>

            <Box height="10rem">
              <h3>Single Syndicates</h3>
              <div>
                Each Syndicate will have it&apos;s own distinct Limited
                Liability Company (LLC) for that specific investment.
              </div>
            </Box>
            <Box marginTop={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={onManagerPick}
              >
                Quick Setup
              </Button>
            </Box>
            {/* <Box marginTop={2}>
              <Link
                component="button"
                onClick={() => {
                  console.info("I'm a button.");
                }}
              >
                More information
              </Link>
            </Box> */}
          </Box>
        </Grid>
        <Grid>
          <Box
            padding={3}
            height="28rem"
            bgcolor="white"
            borderRadius={1}
            textAlign="center"
            width="22rem"
          >
            <Box marginBottom={2}>
              <img src={SeriesLLC} alt="" style={{ height: '8rem' }}></img>
            </Box>
            <Box height="10rem">
              <h3>Series Syndicates</h3>
              <div>
                Each Syndicate will belong to a Master Limited Liability Company
                (LLC), where each investment will be its own Series within the
                LLC. This type of structure is good if you&apos;re going to be
                repetitively using the same management structure and only want
                one LLC opened for all syndicates.
              </div>
            </Box>
            <Box marginTop={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onSeriesPick}
                disabled
              >
                Coming Soon
              </Button>
            </Box>
            {/* <Box marginTop={2}>
              <Link
                color="primary"
                component="button"
                onClick={() => {
                  console.info("I'm a button.");
                }}
              >
                More information
              </Link>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManagementPicker;
