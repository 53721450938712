import React, { useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import Form from 'components/Form/Form';
import getServerResponseErrors from 'api/getServerResponseErrors';
import userClient from 'api/user/userClient';
import { FormikTextInput } from 'components/ui/CustomTextField';

import { LoadingSpinner } from 'components/ui/Loading';
import GenericPortal from 'views/GenericPortal';
import { useDispatch } from 'react-redux';
import { signIn } from 'redux/currentUser/actions';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import { useSelectedGroup } from 'hooks/useAppState';
import groupsClient from 'api/groups/groupsClient';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: '10rem',
  },
  logo: {
    height: '10rem',
  },
}));

const AcceptInvitation = () => {
  const { invitationToken, email, groupId } = qs.parse(window.location.search);
  const selectedGroup = useSelectedGroup();

  const [loading, setLoading] = useState(!selectedGroup && groupId);
  const [group, setGroup] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.get(groupId);
        setGroup(response.data);
      } finally {
        setLoading(false);
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userClient.getInvitation({
          email,
          invitationToken,
        });

        const { confirmed, firstName, lastName, errors } = response.data;
        if (confirmed) {
          if (!errors) {
            await userClient.acceptInvitation({
              email,
              invitation_token: invitationToken,
              group_id: groupId,
            });
          }
          window.location.href = '/';
          await new Promise(() => {}); // wait forever
        } else {
          setData({ email, invitationToken, firstName, lastName });
        }
      } catch (e) {
        const errors = getServerResponseErrors(e);
        console.log(errors);
        setData({ errors });
      }
    };
    fetchData();
  }, [email, invitationToken, groupId]);

  return (
    <GenericPortal maxWidth="md" title="Account Setup">
      {loading || !data ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <CreateAccount
          {...{ data, group: selectedGroup || group }}
        ></CreateAccount>
      )}
    </GenericPortal>
  );
};

const CreateAccount = ({ data, group }) => {
  const classes = useStyles();
  const { email, invitationToken, errors } = data;
  const { slug } = group;

  const dispatch = useDispatch();
  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { password, passwordConfirmation },
        errors,
      } = await validateForm(formRef);
      if (password !== passwordConfirmation) {
        errors.push('Passwords do not match');
      }
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      await userClient.acceptInvitation({
        email,
        password,
        passwordConfirmation,
        invitation_token: invitationToken,
      });
      await dispatch(signIn({ email, password }));
      window.location.href = `/${slug}`;
      await new Promise(() => {}); // wait forever

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const submitButton = useRef(null);
  const handleSubmit = () => {
    submitButton.current.click();
  };

  if (!invitationToken || errors) {
    return (
      <CenteredContentBox>
        Invitation token invalid or expired. Go to&nbsp;
        <Link href={`${group ? group.slug : ''}/sign-in`}>Sign In</Link>
      </CenteredContentBox>
    );
  }

  return (
    <>
      <Grid container direction="row" spacing={4} alignItems="flex-start">
        {group && (
          <Grid xs={12} sm={12} md={4}>
            <CenteredContentBox>
              <Box className={classes.logoContainer}>
                {group.photo && (
                  <img alt="" src={group.photo} className={classes.logo} />
                )}
                <Typography variant="h6">{group.name}</Typography>
              </Box>
            </CenteredContentBox>
          </Grid>
        )}
        <Grid xs>
          <Form
            setRef={setFormRef}
            onSubmit={handleSubmit}
            initialValues={data}
          >
            <Grid container spacing={3}>
              <Grid xs={12}>
                <FormikTextInput name="email" label="Email" disabled />
              </Grid>
              <Grid xs={12} sm={6}>
                <FormikTextInput
                  name="password"
                  label="Password"
                  type="password"
                  required
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <FormikTextInput
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  type="password"
                  required
                />
              </Grid>
            </Grid>
            <Box marginTop={5} textAlign="right">
              <SaveFormButton
                onSave={onSave}
                name="Create Account"
                buttonRef={submitButton}
              ></SaveFormButton>
            </Box>
          </Form>
        </Grid>
      </Grid>
    </>
  );
};

export default AcceptInvitation;
