import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { LoadingArea } from 'components/ui/Loading';

import { useEditPaymentCheck } from 'hooks/forms/useFormPaymentCheck';

const CheckList = ({ groupId }) => {
  const [checks, sestChecks] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.checks({ id: groupId });
        sestChecks(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const { onListUpdate } = useArrayUpdate(sestChecks);

  const { modal, showModal } = useEditPaymentCheck({
    groupId,
    onChange: onListUpdate,
  });
  return (
    <Box position="relative">
      {!checks && <LoadingArea open />}
      <Box>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography variant="h5">Check Instructions</Typography>
          </Grid>
          <Grid>
            <Button onClick={() => showModal({})}>Add Check Info</Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} minHeight="2rem">
        {modal}
        {checks && checks.length > 0 ? (
          <Box>
            {checks.map((check) => (
              <Box key={check.id} marginBottom={2}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Box>
                      <Typography>{check.name}</Typography>
                    </Box>
                    <Box>{check.checkRecipient}</Box>
                  </Grid>
                  <Grid>
                    <Button color="primary" onClick={() => showModal(check)}>
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : (
          <Box textAlign="center">
            No check instructions have been added yet
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckList;
