import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import { LoadingSpinner } from 'components/ui/Loading';
import { SendReminder } from '../SendReminder';

import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';

const SubscriptionSign = ({
  userInvestment,
  onChange,
  actionButton,
  syndicate,
}) => {
  const {
    entityType,
    isAuthorizedSigner,
    signerName,
    signerEmail,
    subscriptionSignatureId,
    // signerPhone,
    // subscriptionSignature,
    acceptedUnits,
  } = userInvestment;

  const {
    data: subscription,
    openLink,
    clicked,
  } = useSyndicateDocumentPoll({
    id: subscriptionSignatureId,
    role: 'person',
  });

  const { groupInvestment } = syndicate;
  const { earlyPayment } = groupInvestment;
  const {
    isReady,
    documentData,
    draftData,
    signatures = [],
    isExpired,
  } = subscription || {};
  const hasCustodian = entityType == 'ira';

  const investorSigned = signatures.find(
    (x) => x.memberType === 'person',
  )?.dateSigned;
  const custodianSigned = signatures.find(
    (x) => x.memberType === 'custodian',
  )?.dateSigned;
  const managerSigned = signatures.find(
    (x) => x.memberType === 'manager',
  )?.dateSigned;

  const needsInvestorSignature =
    !investorSigned && (isAuthorizedSigner || hasCustodian);

  const waitingInvestorSignature = !isAuthorizedSigner && !investorSigned;
  const waitingCustodianSignature =
    !isAuthorizedSigner && hasCustodian && !custodianSigned;

  return (
    <Box>
      {isExpired ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>
                Your Subscription Document has expired, you will need to restart
                your subscription.
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : investorSigned && managerSigned && !documentData ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>
                Signature Page is currently stored on an external system
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : !isReady || !draftData ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>Preparing Signature Page ...</Typography>
              <LoadingSpinner></LoadingSpinner>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : (
        <Box minHeight="10rem" bgcolor="white" textAlign="center" padding={2}>
          <CenteredContentBox>
            <Button
              variant={needsInvestorSignature ? 'contained' : 'outlined'}
              color="primary"
              onClick={openLink}
              disabled={!subscription}
            >
              Review Signature Pages {needsInvestorSignature && 'and Sign'}
            </Button>
            {needsInvestorSignature ? (
              <>
                {clicked && (
                  <Box marginTop={2}>
                    <Typography variant="subtitle1">
                      Awaiting Signature Confirmation ...
                    </Typography>
                    <LoadingSpinner></LoadingSpinner>
                  </Box>
                )}
              </>
            ) : waitingInvestorSignature || waitingCustodianSignature ? (
              <Box padding={2} textAlign="center">
                <Box>
                  <Typography>Awaiting signature from {signerName}.</Typography>
                </Box>
                <Box>
                  <Typography variant="caption">{signerEmail}</Typography>
                </Box>
                <Box marginTop={1}>
                  <SendReminder {...{ userInvestment, onChange }} />
                </Box>
                {waitingCustodianSignature && (
                  <Box marginTop={2}>
                    <Typography variant="caption">
                      Congratulations! A signature request was sent to the
                      custodian of your investment to review and sign. Once the
                      custodian has signed, the syndicate manager will
                      countersign, and then you will receive instructions on how
                      to send the money for the investment.
                    </Typography>
                  </Box>
                )}
                {waitingInvestorSignature && (
                  <Box marginTop={2}>
                    <Typography variant="caption">
                      Congratulations! A signature request was sent to the
                      authorized signer of the entity to review and sign. Once
                      the authorized signer has signed, the syndicate manager
                      will countersign, and then you will receive instructions
                      on how to send the money for the investment.
                    </Typography>
                  </Box>
                )}
                <Box marginTop={2}>
                  <Typography>
                    Once the document is completed by all signers, you will be
                    able to download a signed copy.
                  </Typography>
                </Box>
              </Box>
            ) : investorSigned && !managerSigned ? (
              <Box marginTop={2}>
                <Typography>
                  You have signed your investment agreements. <br />
                  The manager needs to countersign the agreements to finalize
                  the investment.
                  <br />
                  Once all signees have completed the document, you can download
                  the signed and finalized copy
                  {earlyPayment ? (
                    <>
                      .<br />
                      Click next to view the instructions to send the money.
                    </>
                  ) : (
                    <>
                      , as well as view the investment instructions displayed on
                      the opportunity.
                    </>
                  )}
                </Typography>
              </Box>
            ) : (
              investorSigned &&
              managerSigned && (
                <Box>
                  <Typography variant="caption">(completed)</Typography>
                </Box>
              )
            )}
          </CenteredContentBox>
        </Box>
      )}
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid>{acceptedUnits == 0 ? actionButton : null}</Grid>
          <Grid>
            <Button
              variant="contained"
              name="Continue"
              color="primary"
              disabled={!earlyPayment}
              onClick={() =>
                onChange({
                  ...userInvestment,
                  subscription: { ...userInvestment.subscription, signatures },
                })
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default SubscriptionSign;
