import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  Link,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DialogWithTitle from 'components/ui/DialogWithTitle';

import { LoadingArea, LoadingSpinner } from 'components/ui/Loading';

import { SyndicateFeeTable } from './FeeTable';

import { CustomTextField } from 'components/ui/CustomTextField';

import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import { FaDotCircle } from 'react-icons/fa';

import UserInvestmentTable from './UserInvestmentsTable';

import useDataUserInvestments from './useDataUserInvestments';
import { UserInvestmentExportButton } from './UserInvestmentExport';
import EditVisibilitySettings from 'views/GroupInvestment/EditVisibilitySettings';
import EditTargetAmount from 'views/GroupInvestment/EditTargetAmount';

import groupInvestmentsClient from 'api/groupInvestmentsClient';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';
import { useSyndicateActions } from 'hooks/useSyndicate';
import CloseSyndicateButton from 'views/Syndicate/CloseSyndicateButton';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useHistory } from 'react-router';
import CustomizedSteppers from 'components/ui/CustomStepper';

import moment from 'moment';
import { useSelectedGroup } from 'hooks/useAppState';
import SendEmailModal from './SendEmailModal';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const UserInvestments = ({ investmentId }) => {
  const {
    syndicate,
    investment,
    setInvestment,
    setSyndicate,
    syndicateButton,
  } = useSyndicateActions({
    investmentId,
  });

  if (!investment) {
    return <LoadingSpinner />;
  }

  return (
    <UserInvestmentsHelper
      {...{
        investment,
        setInvestment,
        syndicate,
        setSyndicate,
        syndicateButton,
      }}
    />
  );
};

const UserInvestmentsHelper = ({
  investment,
  setInvestment,
  syndicate,
  setSyndicate,
  syndicateButton,
}) => {
  const history = useHistory();
  const { slug, id: groupId } = useSelectedGroup((state) => ({
    slug: state.slug,
    id: state.id,
  }));
  const {
    roundName,
    syndicateName,
    syndicateData,
    syndicateId,
    status,
    unitPrice,
    isAdmin,
    opportunityName,
    opportunityId,
  } = investment;

  const theme = useTheme();
  const {
    targetAmount,
    dateClosed,
    id,
    disableSubscriptions,
    acceptChecks,
    acceptWires,
    paymentCheck,
    paymentWire,
  } = investment || {};

  const {
    dateFiledFormationService,
    dateFiledFormationFilings,
    dateFiledOfferingReview,
    dateFiledOfferingAgreements,
    finalClosing,
    primaryManager,
  } = syndicate || {};
  const primaryContact = primaryManager?.groupManagerPerson?.email;

  const bankingSetup =
    (acceptChecks && paymentCheck) || (acceptWires && paymentWire);

  const [visibility, setVisibility] = useState();
  const [target, setTarget] = useState();
  // const [add, setAdd] = useState();

  const searchRef = useRef();
  const listRef = useRef();

  const onAdd = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }, []);

  const [notesData, updateNotes] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.usersWithNotes(id);
        updateNotes(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const {
    isLoading,
    userInvestments,
    filteredData,
    debouncedSearch,
    filterMenu,
    filterTerms,
    onListUpdate,
  } = useDataUserInvestments(
    investment,
    onAdd,
    dateFiledOfferingAgreements,
    notesData,
  );
  const handleDelete = async (onError, minDelay) => {
    try {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          disableSubscriptions: true,
          dateClosed: moment().format('MM/DD/yyyy'),
        },
      });
      await minDelay;
      setInvestment(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };

  const handleReOpen = async (onError, minDelay) => {
    try {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          disableSubscriptions:
            syndicate && dateFiledOfferingAgreements ? false : true,
          dateClosed: null,
        },
      });
      await minDelay;
      setInvestment(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const handlePause = async (onError, minDelay) => {
    try {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          disableSubscriptions: true,
        },
      });
      await minDelay;
      setInvestment(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const awaitingCollection = userInvestments
    .filter((x) => x.requestedUnits > 0)
    .reduce(
      (acc, x) =>
        acc +
        (x.acceptedUnits > 0
          ? x.acceptedUnits * unitPrice
          : x.requestedUnits * unitPrice) -
        (parseFloat(x.amountReceived) - parseFloat(x.refundAmount)),
      0,
    );

  const steps = [
    {
      id: 'collecting',
      name: 'Interest',
      icon: <FaDotCircle />,
      completed: true,
    },
    {
      id: 'creating',
      name: 'Creation',
      icon: syndicateId ? <FaDotCircle /> : null,
      completed: !!dateFiledOfferingAgreements,
    },
    {
      id: 'accepting',
      name: 'Investments',
      icon: dateFiledOfferingAgreements ? <FaDotCircle /> : null,
      completed: dateFiledOfferingAgreements && bankingSetup,
    },
    {
      id: 'closed',
      name: 'Closed',
      icon: null,
      completed: dateFiledOfferingAgreements && !!dateClosed,
    },
  ];
  const name = syndicateName || syndicateData?.syndicateName || roundName;

  return (
    <Box>
      <Box>
        <Grid container justifyContent="space-between" spacing={5}>
          <Grid xs={12} sm>
            <Box>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {name}
              </Typography>
              <Link
                onClick={() =>
                  history.push(`/${slug}/opportunity/${opportunityId}/edit`)
                }
                style={{ cursor: 'pointer' }}
              >
                <Typography color="primary" variant="subtitle1">
                  {opportunityName}
                </Typography>
              </Link>
              <Typography variant="subtitle1">{status}</Typography>
            </Box>
            <Box marginTop={2}>
              <CustomizedSteppers
                steps={steps}
                activeStep={steps
                  .filter((x) => !x.completed)
                  .map((x) => x.id)
                  .shift()}
              />
            </Box>

            <Box marginTop={4}>
              {target ? (
                <DialogWithTitle
                  open
                  onClose={() => setTarget(null)}
                  title="Edit Targeted Amount"
                >
                  <EditTargetAmount
                    onClose={() => setTarget(null)}
                    {...{
                      data: investment,
                      onChange: setInvestment,
                    }}
                  />
                </DialogWithTitle>
              ) : null}
              <Grid container spacing={8}>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Targeted Amount
                  </Typography>
                  <Typography color="primary">
                    {targetAmount > 0 ? formatter.format(targetAmount) : '-'}{' '}
                    <Button size="small" onClick={() => setTarget(true)}>
                      Edit
                    </Button>
                  </Typography>
                </Grid>
                {dateFiledOfferingAgreements && (
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: 'bold' }}
                    >
                      Awaiting Collection
                    </Typography>
                    <Typography color="primary">
                      {formatter.format(awaitingCollection)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid xs={12} sm>
            <Box position="relative" minHeight="5rem">
              {(isLoading || (syndicateId && !syndicate)) && (
                <LoadingArea open />
              )}
              {syndicate && !dateFiledOfferingAgreements && !dateClosed && (
                <Box
                  marginBottom={2}
                  bgcolor={theme.palette.primary.main}
                  color="white"
                  padding={2}
                >
                  {!dateFiledFormationService && (
                    <Grid container spacing={2}>
                      <Grid
                        style={{
                          color: theme.palette.accent.main,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <InsertDriveFileOutlinedIcon fontSize="large" />
                      </Grid>
                      <Grid xs>
                        <Typography variant="subtitle2">
                          To accept investments from your group, please finish
                          completing the Syndicate.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {dateFiledFormationService && !dateFiledFormationFilings ? (
                    <Grid container spacing={2}>
                      <Grid
                        style={{
                          color: theme.palette.accent.main,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={75}
                          color="inherit"
                          size={40}
                        />
                      </Grid>

                      <Grid xs>
                        <Typography variant="subtitle2">
                          SmartCapital is currently filing the Articles of
                          Organization for the Syndicate. Filings typically take
                          one business day to process. Once filed, you must
                          review and sign all documents before you can continue
                          the syndication process.
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : dateFiledFormationFilings && !dateFiledOfferingReview ? (
                    <Grid container spacing={2}>
                      <Grid
                        style={{
                          color: theme.palette.accent.main,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={75}
                          color="inherit"
                          size={40}
                        />
                      </Grid>

                      <Grid xs>
                        <Typography variant="subtitle2">
                          To continue the syndication process you must sign the
                          federal filing documents
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    dateFiledOfferingReview &&
                    !dateFiledOfferingAgreements && (
                      <Grid container spacing={2}>
                        <Grid
                          style={{
                            color: theme.palette.accent.main,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={75}
                            color="inherit"
                            size={40}
                          />
                        </Grid>

                        <Grid xs>
                          <Typography variant="subtitle2">
                            To continue the syndication process you must review
                            the syndication documents
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                  {dateFiledOfferingAgreements && (
                    <Grid container spacing={2}>
                      <Grid
                        style={{
                          color: theme.palette.accent.main,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={75}
                          color="inherit"
                          size={40}
                        />
                      </Grid>

                      <Grid xs>
                        <Typography variant="subtitle2">
                          To complete the syndication process, all the managers
                          must sign and the signatures be verified.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}
              {syndicate && dateFiledFormationFilings && !bankingSetup && (
                <Box
                  marginBottom={2}
                  bgcolor={theme.palette.primary.main}
                  color="white"
                >
                  <Box
                    bgcolor={theme.palette.primary.main}
                    color="white"
                    padding={2}
                    onClick={() => {
                      history.push(`${window.location.pathname}/banking`);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Grid container spacing={2}>
                      <Grid>
                        <Box fontSize={30} lineHeight={0}>
                          <WarningIcon fontSize="inherit" />
                        </Box>
                      </Grid>

                      <Grid xs>
                        <Typography variant="subtitle1">
                          In order to accept investments you must&nbsp;
                          <Link
                            style={{
                              color: 'lightpink',
                              textDecoration: 'underline',
                            }}
                          >
                            setup banking
                          </Link>
                          &nbsp;and allow checks or wires
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
              {isAdmin && (
                <Box textAlign="right">
                  {(!syndicateId ||
                    (syndicate && !dateFiledOfferingAgreements)) &&
                    !dateClosed && (
                      <SaveFormButton
                        style={{ marginRight: '1rem' }}
                        onSave={handleDelete}
                        name="Stop Collecting Interest"
                        variant="outlined"
                      />
                    )}
                  {(!syndicateId ||
                    (syndicate && !dateFiledOfferingAgreements)) &&
                    dateClosed && (
                      <SaveFormButton
                        onSave={handleReOpen}
                        name="Collect Interest"
                        variant="outlined"
                        style={{ marginRight: '1rem' }}
                      />
                    )}
                  {syndicate &&
                    dateFiledOfferingAgreements &&
                    !dateClosed &&
                    disableSubscriptions && (
                      <SaveFormButton
                        style={{ marginRight: '1rem' }}
                        onSave={handleReOpen}
                        name="Accept Investments"
                        variant="outlined"
                        disabled={!bankingSetup}
                      />
                    )}
                  {syndicate && dateFiledOfferingAgreements && !dateClosed && (
                    <>
                      {!disableSubscriptions && (
                        <SaveFormButton
                          onSave={handlePause}
                          name="Pause Investments"
                          variant="outlined"
                          style={{ marginRight: '1rem' }}
                        />
                      )}
                      <CloseSyndicateButton
                        {...{ syndicate, id, setInvestment, setSyndicate }}
                      />
                    </>
                  )}
                  {syndicate &&
                    dateFiledOfferingAgreements &&
                    dateClosed &&
                    disableSubscriptions && (
                      <SaveFormButton
                        onSave={handleReOpen}
                        name="Reopen Syndicate"
                        variant="outlined"
                      />
                    )}
                  {!syndicate || !dateFiledOfferingAgreements
                    ? syndicateButton
                    : null}
                </Box>
              )}

              {dateFiledOfferingAgreements && (
                <Box
                  maxWidth={finalClosing && dateClosed ? '30rem' : '20rem'}
                  marginTop={2}
                  marginLeft="auto"
                >
                  <SyndicateFeeTable
                    {...{ investment, syndicate, userInvestments }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box marginTop={4}>
        <Box marginTop={2}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              {visibility ? (
                <DialogWithTitle
                  open
                  onClose={() => setVisibility(null)}
                  title="Display Settings"
                >
                  <EditVisibilitySettings
                    onClose={() => setVisibility(null)}
                    {...{
                      data: investment,
                      dateFiledOfferingAgreements,
                      onChange: setInvestment,
                    }}
                  />
                </DialogWithTitle>
              ) : null}
              <Button
                onClick={() => setVisibility(true)}
                size="small"
                style={{ marginRight: '1rem' }}
              >
                Display Settings
              </Button>

              {isAdmin && (
                <UserInvestmentExportButton
                  {...{ investment, data: filteredData }}
                />
              )}
            </Grid>

            <Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <SendEmailButton
                    {...{
                      filteredData,
                      syndicateId: id,
                      opportunityId,
                      primaryContact,
                    }}
                  />
                </Grid>
                <Grid>{filterMenu}</Grid>
                <Grid>
                  <Box textAlign="right" whiteSpace="nowrap" padding="0.5rem">
                    <CustomTextField
                      inputRef={searchRef}
                      onChange={(e) => debouncedSearch(e.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      inputProps={{
                        style: { padding: '0.5rem', paddingLeft: 0 },
                      }}
                      startAdornment={<SearchIcon />}
                      placeholder="Search"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={2} position="relative">
          <Box ref={listRef}></Box>
          {isLoading ? (
            <Box minHeight="10rem">
              <LoadingArea open />
            </Box>
          ) : (
            <UserInvestmentTable
              {...{
                filteredData,
                investment,
                onListUpdate,
                syndicate,
                filterTerms,
                groupId,
                updateNotes,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const SendEmailButton = ({
  filteredData,
  syndicateId,
  opportunityId,
  primaryContact,
}) => {
  const [recipients, setRecipients] = useState([]);
  const [emailVisibility, setEmailVisibility] = useState(false);

  const handleOpen = () => {
    const list = filteredData.map((d) => ({
      email: d?.data?.email || d?.data?.userInfo?.email,
      name: d?.data?.userInfo?.name || d?.data?.user?.name,
    }));
    setRecipients(list);
    setEmailVisibility(true);
  };

  return (
    <>
      {emailVisibility ? (
        <DialogWithTitle
          open
          onClose={() => setEmailVisibility(null)}
          title="Send Email"
          fullWidth
        >
          <SendEmailModal
            onClose={() => setEmailVisibility(null)}
            {...{
              recipients,
              syndicateId,
              opportunityId,
              primaryContact,
            }}
          />
        </DialogWithTitle>
      ) : null}

      <Button onClick={handleOpen} size="small">
        Send Email ({filteredData.length})
      </Button>
    </>
  );
};

export default UserInvestments;
