import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { withRouter, useParams, useHistory } from 'react-router-dom';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import opportunitiesClient from 'api/opportunitiesClient';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { OpportunityInvestWidget } from 'views/UserInvestment/OpportunityInvestWidget';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import OpportunitySectionAbout from './OpportunitySecionAbout';
import OpportunitySectionLeadership from './OpportunitySectionLeadership';
import OpportunitySectionFinancials from './OpportunitySectionFinancials';
import OpportunitySectionRounds from './OpportunitySectionRounds';
import OpportunitySectionInvestments from './OpportunitySectionInvestments';
import OpportunitySectionInstructions from './OpportunitySectionInstructions';
import { useSelectedGroup } from 'hooks/useAppState';
import OpportunitySectionPress from './OpportunitySectionPress';
import OpportunitySectionDocuments from './OpportunitySectionDocuments';
import OpportunitySectionFeatured from './OpportunitySectionFeatured';
import VisibilityIcon from '@mui/icons-material/Visibility';

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const OpportunityShowcase = () => {
  const { slug, groupId } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const history = useHistory();
  const { id } = useParams();
  const [gettingData, setGettingData] = useState(true);

  const [opportunity, setOpportunity] = useState();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [investments, setInvestments] = useState([]);
  useEffect(() => {
    setGettingData(true);
    const fetchInvestments = async () => {
      try {
        const response = await opportunitiesClient.investments({ id, groupId });
        setInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    const fetchData = async () => {
      try {
        const response = await opportunitiesClient.get({ id, groupId });
        const { data } = response;
        setOpportunity(data);

        await fetchInvestments();
      } catch (e) {
        console.log(getServerResponseErrors(e));
      } finally {
        setGettingData(false);
      }
    };
    fetchData();
  }, [id, groupId]);

  const [tab, setTab] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTab(newValue);

    const el = document.getElementById(`nav-${newValue}`);
    el.scrollIntoView({
      behavior: 'smooth',
    });
    // scrollIntoView
  };

  if (gettingData)
    return (
      <CenteredContentBox>
        <CircularProgress />
      </CenteredContentBox>
    );

  const { isAdmin, documents, leaders, articles, rounds, featured } =
    opportunity;

  return (
    <Box>
      <Box paddingBottom={2}>
        <Grid container justifyContent="space-between">
          <Grid>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(`/${slug}/opportunities`);
              }}
              style={{ cursor: 'pointer' }}
              startIcon={<ChevronLeftIcon fontSize="24px" />}
            >
              Back to Opportunities
            </Button>
            {isAdmin && (
              <Button
                variant="outlined"
                color="primary"
                endIcon={<VisibilityIcon style={{ fontSize: '1rem' }} />}
                onClick={() =>
                  history.push(`${history.location.pathname}/edit`)
                }
                style={{ marginLeft: '1rem' }}
              >
                Manager View
              </Button>
            )}
          </Grid>

          <Grid>
            {opportunity && (
              <OpportunityInvestWidget
                {...{ opportunity }}
              ></OpportunityInvestWidget>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom={3}>
        <OpportunitySectionInstructions {...{ opportunity }} />
      </Box>
      <Paper style={{ backgroundColor: '#F7F7F7' }}>
        <Box padding={2} paddingBottom={50}>
          <Grid container spacing={isSmall ? 2 : 6}>
            <Grid sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tab}
                onChange={handleTab}
                aria-label="Quick Navigation Menu"
              >
                <Tab label="About" {...a11yProps(0)} />
                {leaders && leaders.length > 0 && (
                  <Tab label="Leadership" {...a11yProps(1)} />
                )}
                {articles && articles.length > 0 && (
                  <Tab label="Press" {...a11yProps(2)} />
                )}
                {featured && featured.length > 0 && (
                  <Tab label="Key Investors" {...a11yProps(3)} />
                )}
                <Tab label="Financials" {...a11yProps(4)} />
                {documents && documents.length > 0 && (
                  <Tab label="Documents" {...a11yProps(5)} />
                )}
                {rounds && rounds.length > 0 && (
                  <Tab label="Rounds" {...a11yProps(6)} />
                )}
                {investments &&
                  investments.filter((x) => x.syndicateId).length > 0 && (
                    <Tab label="Investments" {...a11yProps(7)} />
                  )}
              </Tabs>
            </Grid>
            <Grid xs>
              <Box paddingRight={isSmall ? 0 : 4}>
                <Box id="nav-0">
                  <OpportunitySectionAbout opportunity={opportunity} />
                </Box>
                <Box id="nav-1" marginTop={8}>
                  <OpportunitySectionLeadership opportunity={opportunity} />
                </Box>
                <Box id="nav-2" marginTop={8}>
                  <OpportunitySectionPress opportunity={opportunity} />
                </Box>
                <Box id="nav-3" marginTop={8}>
                  <OpportunitySectionFeatured opportunity={opportunity} />
                </Box>
                <Box id="nav-4" marginTop={8}>
                  <OpportunitySectionFinancials opportunity={opportunity} />
                </Box>
                <Box id="nav-5" marginTop={8}>
                  <OpportunitySectionDocuments opportunity={opportunity} />
                </Box>
                <Box id="nav-6" marginTop={8}>
                  <OpportunitySectionRounds
                    opportunity={opportunity}
                    investments={investments}
                  />
                </Box>
                <Box id="nav-7" marginTop={8}>
                  <OpportunitySectionInvestments {...{ investments }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default withRouter(OpportunityShowcase);
