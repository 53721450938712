import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';

import { FormikTextInput } from 'components/ui/CustomTextField';
import {
  FormikCountrySelect,
  FormikRegionSelect,
} from 'components/ui/RegionSelect';

const useFormAddress = ({
  data,
  prefix = '',
  nameLabel = null,
  lockCountry = false,
}) => {
  const { attn } = data || { country: 'US' };

  const [extra, setExtra] = useState(attn);

  const [formRef, setFormRef] = useState({});
  const validate = useCallback(async () => {
    const {
      values: {
        id,
        attn,
        name,
        secondary,
        street,
        city,
        state,
        postal,
        country,
      },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        id,
        attn,
        name,
        secondary,
        street,
        city,
        state,
        postal,
        country,
      },
    };
  }, [formRef]);

  const form = useMemo(
    () => (
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        {({ values }) => {
          const { country } = values || {};
          return (
            <Grid container spacing={2}>
              {extra && (
                <Grid xs={12}>
                  <FormikTextInput
                    name={`attn`}
                    label={`ATTN: (optional)`}
                    variant="filled"
                  />
                </Grid>
              )}
              <Grid xs={12}>
                <FormikTextInput
                  name={`name`}
                  label={
                    nameLabel ? nameLabel : `${prefix} Address Name`.trim()
                  }
                  variant="filled"
                  required
                  inputProps={{
                    autoComplete: 'organization',
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <FormikTextInput
                  name={`street`}
                  label={`${prefix} Street Address`.trim()}
                  variant="filled"
                  required
                  inputProps={{
                    autoComplete: 'address-line1',
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <FormikTextInput
                  name={`secondary`}
                  label={`${prefix} Secondary Address (optional)`.trim()}
                  variant="filled"
                  placeholder="Building, Suite, Room, Apt. etc"
                  inputProps={{
                    autoComplete: 'address-line2',
                  }}
                />
              </Grid>
              <Grid xs={12} md={5}>
                <FormikTextInput
                  name={`city`}
                  label={`${prefix} City`.trim()}
                  variant="filled"
                  required
                  inputProps={{
                    autoComplete: 'address-level2',
                  }}
                />
              </Grid>
              <Grid xs={7} md={4}>
                <FormikRegionSelect
                  country={country || 'US'}
                  name={`state`}
                  label={`${prefix} State/Region`.trim()}
                  variant="filled"
                  required
                  inputProps={{
                    autoComplete: 'address-level1',
                  }}
                />
              </Grid>
              <Grid xs={5} md={3}>
                <FormikTextInput
                  name={`postal`}
                  label={`${prefix} Postal Code`.trim()}
                  variant="filled"
                  required
                  inputProps={{
                    autoComplete: 'postal-code',
                  }}
                />
              </Grid>
              {extra && (
                <Grid xs={12} md={5}>
                  <FormikCountrySelect
                    name={`country`}
                    label={`${prefix} Country`.trim()}
                    variant="filled"
                    defaultValue="US"
                    disabled={lockCountry}
                    inputProps={{
                      autoComplete: 'country',
                    }}
                  />
                </Grid>
              )}
              {!extra && false && (
                <Grid xs={12}>
                  <Typography variant="subtitle2" component="label">
                    <input
                      type="checkbox"
                      checked={extra}
                      onChange={() => setExtra((x) => !x)}
                    />
                    &nbsp;Show additional address fields
                  </Typography>
                </Grid>
              )}
            </Grid>
          );
        }}
      </Form>
    ),
    [data, extra, lockCountry, nameLabel, prefix],
  );

  return { form, validate };
};

export default useFormAddress;
