import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingSpinner } from 'components/ui/Loading';

import useSyndicate from 'hooks/useSyndicate';
import InvestmentCard from './ReviewCards/InvestmentCard';
import AddressCard from './ReviewCards/AddressCard';
import ClosingDatesCard from './ReviewCards/ClosingDatesCard';
import StateFilingsCard from './ReviewCards/StateFilingsCard';
import ManagersCard from './ReviewCards/ManagersCard';
import FeesCard, { SoftwareFees } from './ReviewCards/FeesCard';
import MinimumsCard from './ReviewCards/MinimumsCard';
import { handleInvestment } from './SyndicateFormationFlow';
import BankingCard from './ReviewCards/BankingCard';
import SyndicateFormBlueSky, {
  useSyndicateFormBlueSky,
} from './SyndicateFormBlueSky';
import { useEditModal } from './SyndicateFormReview';
import SyndicateFormOpportunity from './SyndicateFormOpportunity';
import SyndicateFormBusiness from './SyndicateFormBusiness';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';
import SyndicateFormManagers from './SyndicateFormManagers';
import SyndicateFormFees from './SyndicateFormFees';
import SyndicateFormUnitPricing from './SyndicateFormUnitPricing';
import SyndicateFormClosingDates from './SyndicateFormClosingDates';
import SyndicateFormBanking from './SyndicateFormBanking';

const SyndicateAgreements = ({ investment, setInvestment }) => {
  const { syndicate, setSyndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  if (!syndicate) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <SyndicateAgreementsHelper
        {...{
          investment,
          syndicate,
          setInvestment,
          setSyndicate,
        }}
      />
    </Box>
  );
};

const SyndicateAgreementsHelper = ({
  investment,
  syndicate,
  setInvestment,
  setSyndicate,
}) => {
  const { roundName, opportunityName, groupId, syndicateData } =
    syndicate?.groupInvestment || {};
  const {
    id,
    business,
    blueSky,
    primaryManager,
    managementFeeBase,
    managementFeePerMember,
    managementFeePercent,
    carryAmount,
    unitPrice,
    minimumUnits,
    initialClosing,
    finalClosing,
    syndicateName,
    dateFiledReviewed,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeePercent,
    useSmartCapitalAccount,
  } = syndicate || {};
  const { paymentWire, paymentCheck, acceptWires, acceptChecks } =
    investment || {};
  const [investmentState, setInvestmentState] = useState(investment || {});
  const { groupManager, groupManagerPerson } = primaryManager || {};
  const { estimate: blueSkyEstimate } = useSyndicateFormBlueSky({
    groupId,
    data: { ...syndicate, shouldFile: blueSky && blueSky.length > 0 },
  });
  const { modal, handleOpen, handleClose } = useEditModal({
    title: 'Edit Syndicate',
  });
  const saveSyndicate = async (data) => {
    try {
      const response = await syndicatesClient.update({
        id,
        data: {
          ...data,
        },
      });
      setSyndicate(response.data);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  const handleBankOpen = (investState) =>
    handleOpen(() => (
      <SyndicateFormBanking
        {...{
          investment: investState,
          setInvestment: (val) => {
            setInvestmentState(val);
            handleBankOpen(val);
          },
          groupId,
          onNext: () => {
            setInvestment(investState);
            handleClose();
          },
          onClose: handleClose,
          buttonName: 'Save',
        }}
      />
    ));
  return (
    <>
      {modal}
      <Box>
        <Box mb={2}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Formation
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid md={6}>
            <InvestmentCard
              {...{
                syndicateName,
                roundName,
                opportunityName,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormOpportunity
                      {...{
                        groupId,
                        opportunityId: investment?.opportunityId,
                        data: syndicate,
                        investment,
                        onNext: handleInvestment(
                          investment,
                          groupId,
                          investment?.id,
                          (val) => {
                            setInvestment(val);
                            handleClose();
                          },
                          'review',
                        ),
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <AddressCard
              {...{
                business,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormBusiness
                      {...{
                        groupId,
                        opportunityId: investment?.opportunityId,
                        data: syndicate,
                        investment,
                        onNext: (val) => {
                          saveSyndicate(val).then(handleClose);
                        },
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <StateFilingsCard
              {...{
                shouldFile: blueSky && blueSky.length > 0,
                blueSky,
                blueSkyEstimate,
                id,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormBlueSky
                      {...{
                        groupId,
                        opportunityId: investment?.opportunityId,
                        data: syndicate,
                        investment,
                        onNext: (val) => {
                          saveSyndicate(val).then(handleClose);
                        },
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <ManagersCard
              {...{
                dateFiledReviewed,
                groupManager,
                groupManagerPerson,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormManagers
                      {...{
                        groupId,
                        opportunityId: investment?.opportunityId,
                        data: syndicateData,
                        investment,
                        onNext: (val, error) =>
                          handleInvestment(
                            investment,
                            groupId,
                            investment?.id,
                            (val) => {
                              setInvestment(val);
                              saveSyndicate({
                                managers: [
                                  val.syndicateData.primaryManager?.id,
                                ],
                              });
                              handleClose();
                            },
                            'review',
                          )({ syndicateData: { ...val } }, error),
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <SoftwareFees
              {...{
                smartcapitalFeeBase,
                smartcapitalFeePerMember,
                smartcapitalFeePercent,
              }}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Offering
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid md={6}>
            <MinimumsCard
              {...{
                unitPrice,
                minimumUnits,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormUnitPricing
                      {...{
                        data: syndicate,
                        onNext: (val) => {
                          saveSyndicate(val).then(handleClose);
                        },
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <ClosingDatesCard
              {...{
                initialClosing,
                finalClosing,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormClosingDates
                      {...{
                        data: syndicate,
                        onNext: (val) => {
                          saveSyndicate(val).then(handleClose);
                        },
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
          <Grid md={6}>
            <BankingCard
              {...{
                dateFiledReviewed,
                useSmartCapitalAccount,
                paymentWire,
                paymentCheck,
                acceptWires,
                acceptChecks,
                groupId,
                editClick: () => handleBankOpen(investmentState),
              }}
            />
          </Grid>
          <Grid md={6}>
            <FeesCard
              {...{
                managementFeeBase,
                managementFeePerMember,
                managementFeePercent,
                carryAmount,
                editClick: () =>
                  handleOpen(() => (
                    <SyndicateFormFees
                      {...{
                        data: syndicate,
                        smartcapitalFeeBase,
                        smartcapitalFeePerMember,
                        smartcapitalFeePercent,
                        onNext: (val) => {
                          saveSyndicate(val).then(handleClose);
                        },
                        onClose: handleClose,
                        buttonName: 'Save',
                      }}
                    />
                  )),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SyndicateAgreements;
