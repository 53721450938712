import React, { useState, useEffect } from 'react';

import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { LoadingArea } from 'components/ui/Loading';

import { useEditPaymentWire } from 'hooks/forms/useFormPaymentWire';

const WireList = ({ groupId }) => {
  const [wires, sestWires] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.wires({ id: groupId });
        sestWires(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const { onListUpdate } = useArrayUpdate(sestWires);

  const { modal, showModal } = useEditPaymentWire({
    groupId,
    onChange: onListUpdate,
  });

  return (
    <Box position="relative">
      {!wires && <LoadingArea open />}
      <Box>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography variant="h5">Wire Instructions</Typography>
          </Grid>
          <Grid>
            <Button onClick={() => showModal({})}>Add Wire Info</Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} minHeight="2rem">
        {modal}
        {wires && wires.length > 0 ? (
          <Box>
            {wires.map((wire) => (
              <Box key={wire.id} marginBottom={2}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Box>
                      <Typography>{wire.name}</Typography>
                    </Box>
                    <Box>{wire.routingNumber}</Box>
                  </Grid>
                  <Grid>
                    <Button color="primary" onClick={() => showModal(wire)}>
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : (
          <Box textAlign="center">No wire instructions have been added yet</Box>
        )}
      </Box>
    </Box>
  );
};

export default WireList;
