import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { LoadingArea } from 'components/ui/Loading';
import groupManagersClient from 'api/groupManagersClient';

import { useEditGroupManager } from 'hooks/forms/useFormGroupManager';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}));

const ManagerList = ({ groupId }) => {
  const classes = useStyles();
  const [data, setData] = useState();

  const fetchData = useCallback(async () => {
    try {
      const response = await groupManagersClient.hidden({ groupId });
      setData(response.data);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  }, [groupId]);
  useEffect(() => {
    if (groupId) {
      fetchData();
    }
  }, [fetchData, groupId]);

  const { onListUpdate } = useArrayUpdate(setData);

  const onUnarchive = async (managerId) => {
    try {
      await groupManagersClient.update({
        id: managerId,
        data: { dateHidden: null },
      });
      fetchData();
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  const { modal, showModal } = useEditGroupManager({
    groupId,
    onChange: onListUpdate,
    fetchData,
    canDelete: true,
  });

  const managers = data?.filter((m) => !m.dateHidden);
  const hiddenManagers = data?.filter((m) => m.dateHidden);
  return (
    <Box position="relative">
      {!managers && <LoadingArea open />}
      <Box>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography variant="h5">Managers</Typography>
          </Grid>
          <Grid>
            <Button
              onClick={() =>
                showModal({
                  groupManagerPersons: [
                    {
                      firstName: '',
                      lastName: '',
                      phone: '',
                      email: '',
                      relatedType: '',
                    },
                  ],
                })
              }
            >
              Add Manager
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} minHeight="2rem">
        {modal}
        {managers && managers.length > 0 ? (
          <Box>
            {managers.map((manager) => (
              <Box key={manager.id} marginBottom={2}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Box>
                      <Typography>{manager.name}</Typography>
                    </Box>
                    <Box>
                      {manager.managerType == 'entity'
                        ? manager.signerName
                        : 'Individual'}
                    </Box>
                  </Grid>
                  <Grid>
                    <Button color="primary" onClick={() => showModal(manager)}>
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : (
          <Box textAlign="center">
            No syndicate managers have been added yet
          </Box>
        )}
      </Box>
      {hiddenManagers && hiddenManagers.length > 0 && (
        <>
          <Box marginTop={2} minHeight="2rem" className={classes.root}>
            <Accordion
              variant="outlined"
              defaultExpanded={managers.length == 0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Archived Managers
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.root}>
                  {hiddenManagers.map((manager) => (
                    <Box key={manager.id} marginBottom={2}>
                      <ActionWithConfirm
                        onAction={() => onUnarchive(manager.id)}
                        buttonProps={{ variant: 'outlined' }}
                        showWarningText={false}
                        action="Unarchive"
                      >
                        {({ actionButton }) => (
                          <Grid container justifyContent="space-between">
                            <Grid>
                              <Box>
                                <Typography>{manager.name}</Typography>
                              </Box>
                              <Box>
                                {manager.managerType == 'entity'
                                  ? manager.signerName
                                  : 'Individual'}
                              </Box>
                            </Grid>
                            <Grid>{actionButton}</Grid>
                          </Grid>
                        )}
                      </ActionWithConfirm>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ManagerList;
