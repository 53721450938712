import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, useTheme, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { MdDashboard } from 'react-icons/md';

import Logo from 'images/logo.svg';

import UserMenu from '../../layouts/UserMenu';
import styled from '@emotion/styled'; // required because Box refs are not fixed until material ui 5
import NavigationMenu from '../../layouts/NavigationMenu';

const StyledBox = styled(Box)``;

const useStyles = makeStyles((theme) => ({
  scrolled: {
    position: 'fixed',
    top: 0,
    left: 0,
    animation: `$moveDown 500ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes moveDown': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-5rem)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  logo: {
    cursor: 'pointer',
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    margin: `0 ${theme.spacing(1)}`,
    '&:focus': {
      opacity: 1,
    },
    '&:hover': {
      color: theme.palette.link.main,
    },
    '&:active': {
      color: theme.palette.accent.main,
    },
  },
  wrapper: {
    lineHeight: 1,
    fontSize: theme.typography.fontSize,
  },
  textColorPrimary: {
    '&.Mui-selected': {
      color: theme.palette.link.main,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles((theme) => ({
  root: {
    height: '100%',
  },
  indicator: {
    backgroundColor: theme.palette.link.main,
    height: '4px',
  },
}))(Tabs);

const UserNavigation = ({ sticky, navBarRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState(false);

  useEffect(() => {
    const pathSegments = pathname.split('/');
    let navSegment = pathSegments[2];
    if (
      navSegment === 'opportunities' ||
      navSegment === 'opportunity' ||
      navSegment === 'investors' ||
      navSegment === 'admin'
    ) {
      if (navSegment === 'opportunity') navSegment = 'opportunities';
      setCurrentTab(navSegment);
    } else {
      setCurrentTab(false);
    }
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const theme = useTheme();

  return (
    <StyledBox
      borderBottom={`1px solid ${theme.palette.divider}`}
      className={clsx({
        [classes.scrolled]: sticky,
      })}
      width="100%"
      style={{
        backgroundColor: theme.palette.background.paper,
      }}
      zIndex={theme.zIndex.appBar}
      ref={navBarRef}
    >
      <Grid container alignItems="center">
        <Grid
          xs={12}
          sm="auto"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box
            marginRight={1}
            marginLeft={1}
            title="SmartCapital"
            className={classes.logo}
          >
            <Link
              onClick={() => {
                history.push('/user/dashboard');
              }}
              style={{ display: 'flex' }}
            >
              <img alt="SmartCapital" src={Logo} height="38px" width="38px" />
            </Link>
          </Box>
          <NavigationMenu />
        </Grid>
        <Grid xs>
          <Box marginLeft={1} height="100%">
            <StyledTabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <StyledTab
                value="dashboard"
                label={
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid>
                      <MdDashboard size="1.25em" />
                    </Grid>
                    <Grid>Dashboard</Grid>
                  </Grid>
                }
                onClick={() => history.push('/user/dashboard')}
              />
              <StyledTab
                value="actions"
                label={
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid>
                      <MdDashboard size="1.25em" />
                    </Grid>
                    <Grid>Actions</Grid>
                  </Grid>
                }
                onClick={() => history.push('/user/actions')}
              />
            </StyledTabs>
          </Box>
        </Grid>
        <Grid
          xs={2}
          sm="auto"
          sx={{
            display: { xs: 'inherit', sm: 'flex' },
            'flex-grow': { xs: '0', sm: '1' },
            justifyContent: 'flex-end',
          }}
        >
          <UserMenu url={(path) => `/user${path}`} />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default withRouter(UserNavigation);
