import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import BusinessIcon from '@mui/icons-material/Business';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShareIcon from '@mui/icons-material/Share';
import SubjectIcon from '@mui/icons-material/Subject';
import { withRouter, useHistory } from 'react-router-dom';
import { useProfile, useSelectedGroup } from 'hooks/useAppState';
import UserOnboardingMessage from 'components/Message/UserOnboardingMessage';
import StructureSetup from 'views/ManagementStructure/StructureSetup';

const useStyles = makeStyles((theme) => ({
  step: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
    transition: '.2s all ease-out',
  },
  stepComplete: {
    backgroundColor: theme.palette.success.light,
    cursor: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
}));

const Step = ({
  Icon,
  complete,
  onClick,
  title,
  subtitle,
  Container = Box,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Box
        className={`${classes.step} ${complete && classes.stepComplete}`}
        onClick={complete ? () => {} : onClick}
      >
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs="auto">
              {complete ? (
                <CheckIcon fontSize="large" />
              ) : (
                <Icon
                  fontSize="large"
                  color={complete ? 'success.main' : 'primary'}
                />
              )}
            </Grid>
            <Grid item xs="auto">
              <Typography variant="h6">{title}</Typography>
              <Typography variant="subtitle2">{subtitle}</Typography>
            </Grid>
            {!complete && (
              <Grid item xs="auto" style={{ marginLeft: 'auto' }}>
                <NavigateNextIcon fontSize="large" color="primary" />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Grid>
  );
};

const UserSetup = () => {
  const history = useHistory();

  const { firstName, setupMessageDismissed } = useProfile((state) => ({
    firstName: state.firstName,
    setupMessageDismissed: state.setupMessageDismissed,
  }));
  const {
    slug,
    managementEntityStepComplete,
    opportunityStepComplete,
    investorStepComplete,
  } = useSelectedGroup((state) => ({
    slug: state.slug,
    managementEntityStepComplete: state.managementEntityStepComplete,
    opportunityStepComplete: state.opportunityStepComplete,
    investorStepComplete: state.investorStepComplete,
  }));
  const url = (url = '') => `/${slug}${url}`;

  return (
    <Box>
      <Box marginBottom={3}>
        <Typography variant="h6">Setup Guide</Typography>
      </Box>
      {!setupMessageDismissed && (
        <UserOnboardingMessage dismissField="setupMessageDismissed">
          <Typography variant="h5" gutterBottom>
            Welcome {firstName}
          </Typography>
          <Typography variant="body1">
            This is a quick setup guide. Follow the steps below to quickly get
            your investment group up and running and investing.
          </Typography>
        </UserOnboardingMessage>
      )}
      <Grid container spacing={3}>
        <Step
          {...{
            Container: StructureSetup,
            Icon: SubjectIcon,
            title: 'Define Management Structure',
            subtitle: 'This must be setup before investments can be accepted',
            complete: managementEntityStepComplete,
          }}
        ></Step>
        <Step
          {...{
            Icon: BusinessIcon,
            title: 'Create Investment Opportunity',
            subtitle:
              'This can be a startup, property, or other type of investment.',
            onClick: () => history.push(url('/opportunity/create')),
            complete: opportunityStepComplete,
          }}
        ></Step>
        <Step
          {...{
            Icon: ShareIcon,
            title: 'Invite Investors',
            subtitle:
              'We’ve made it easy for you to onboard your investor network.',
            onClick: () => history.push(url('/investors')),
            complete: investorStepComplete,
          }}
        ></Step>
      </Grid>
    </Box>
  );
};

export default withRouter(UserSetup);
