import React from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box } from '@mui/material';

const DocumentIcon = ({ count }) => {
  return (
    <Box position="relative">
      <InsertDriveFileOutlinedIcon
        color="primary"
        style={{ fontSize: '60px' }}
      />
      <Box
        position="absolute"
        top={20}
        textAlign="center"
        width="100%"
        fontWeight="bold"
        fontSize="1.25em"
      >
        {count || ''}
      </Box>
    </Box>
  );
};

export default DocumentIcon;
