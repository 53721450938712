import { Popover, Button, IconButton, Menu } from '@mui/material';

import { MoreHoriz, Tune } from '@mui/icons-material';

import React, { useState } from 'react';

const ActionsMenu = ({ id, children }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleOpen} edge="start" size="large">
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        id={`more-actions-${id}`}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <div onClick={handleClose}>{children}</div>
      </Menu>
    </>
  );
};

export const FilterMenu = ({
  children,
  label,
  id,
  buttonProps = {},
  ...otherProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={onClick}
        {...buttonProps}
        endIcon={<Tune />}
      >
        {label}
      </Button>
      {anchorEl && (
        <Popover
          id={id}
          open
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...otherProps}
        >
          {children && typeof children === 'function'
            ? children({
                onClose,
                onClick,
              })
            : children}
        </Popover>
      )}
    </>
  );
};

export default ActionsMenu;
