import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingSpinner } from 'components/ui/Loading';
import TabPanel from 'components/ui/TabPanel/TabPanel';
import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import { useProfile } from 'hooks/useAppState';
import { DialogWithActions } from 'components/ui/DialogWithTitle';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useSyndicate from 'hooks/useSyndicate';
import { useHistory } from 'react-router-dom';
import { documentStates } from 'hooks/useSyndicateDocumentPoll';
import FilingInfo from './FilingInfo';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { useStateFiling } from 'hooks/forms/useFormStateRegistration';
import SyndicateFilingItem from './SyndicateFilingItem';
import SyndicateFormationDate from './SyndicateFormationDate';

const { CHECKING_STATUS, COMPLETED } = documentStates;

const SyndicateFiling = ({ investment, setInvestment }) => {
  const { syndicate, setSyndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  if (!syndicate) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <SyndicateFilingHelper {...{ investment, syndicate, setSyndicate }} />
    </Box>
  );
};

const SyndicateFilingHelper = ({ investment, syndicate, setSyndicate }) => {
  const history = useHistory();
  const {
    roundName,
    syndicateName,
    acceptChecks,
    acceptWires,
    paymentCheck,
    paymentWire,
  } = investment;
  const {
    id,
    articlesOfOrganizationId,
    serviceAgreementId,
    formSs4Id,
    einId,
    formDId,
    formIdId,
    formIdAcceptedId,
    formDFiledId,
    blueSkyFilingId,
    primaryManager,
    dateFiledReviewed,
    powerOfAttorneyId,
    dateFiledOfferingAuthorization,
    formDSignatureId,
    formDRelated,
    formationDate,
  } = syndicate || {};

  const theme = useTheme();
  const isAdmin = useProfile((state) => state.isAdmin);
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const [filings, setFilings] = useState();
  const { onListUpdate } = useArrayUpdate(setFilings);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicateFilingsClient.index({
          syndicateId: id,
        });
        setFilings(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const {
    groupManager: { email },
  } = primaryManager || {};

  const formSs4 = useSyndicateDocument({
    id: formSs4Id,
    documentName: 'Form - SS4',
    linkEmail: email,
    role: 'manager',
    canChange: isAdmin,
  });

  const articlesOfOrganization = useSyndicateDocument({
    id: articlesOfOrganizationId,
    documentName: 'Articles of Organization',
    canChange: isAdmin,
    role: 'manager',
  });

  const ein = useSyndicateDocument({
    id: einId,
    documentName: 'EIN',
    canChange: isAdmin,
    role: 'manager',
  });

  const formDSignature = useSyndicateDocument({
    id: formDSignatureId,
    documentName: 'Form D',
    canChange: isAdmin,
    role: 'manager',
    linkEmail: email,
  });

  const formDAccepted = useSyndicateDocument({
    id: formDId,
    documentName: 'Form D - Accepted',
    canChange: isAdmin,
    role: 'manager',
  });
  const blueSkyFiling = useSyndicateDocument({
    id: blueSkyFilingId,
    documentName: 'Blue Sky',
    canChange: isAdmin,
    role: 'manager',
  });
  const powerOfAttorney = useSyndicateDocument({
    id: powerOfAttorneyId,
    documentName: 'Power of Attorney',
    role: 'manager',
    canChange: isAdmin,
  });
  const formID = useSyndicateDocument({
    id: formIdId,
    documentName: 'Form ID',
    role: 'manager',
    canChange: isAdmin,
  });
  const formIDAccepted = useSyndicateDocument({
    id: formIdAcceptedId,
    documentName: 'Form ID - Accepted',
    role: 'manager',
    canChange: isAdmin,
  });
  const formDFiled = useSyndicateDocument({
    id: formDFiledId,
    documentName: 'Form D - Filed',
    role: 'manager',
    canChange: isAdmin,
  });

  const [filing, showFiling] = useState(false);

  if (!filings) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <Box>
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid xs>
            <Box>
              {syndicateName ? (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {syndicateName}
                </Typography>
              ) : (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {roundName}
                </Typography>
              )}
            </Box>
            <Box style={{ padding: '1rem', paddingLeft: '' }}>
              <SyndicateFormationDate
                {...{
                  syndicateFormationDate: formationDate,
                  id,
                  onChange: setSyndicate,
                  isAdmin,
                  showLabel: false,
                }}
              />
            </Box>
          </Grid>
          <Grid>
            {filing && (
              <DialogWithActions
                fullWidth
                {...{
                  title: `Filing Info`,
                  onClose: () => showFiling(false),
                }}
              >
                <FilingInfo {...{ investment, syndicate }} />
              </DialogWithActions>
            )}
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() => showFiling(true)}
                color="grey"
              >
                Filing Info
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid xs={12}>
            {serviceAgreementId &&
              !dateFiledOfferingAuthorization &&
              articlesOfOrganizationId &&
              articlesOfOrganization.stepName != CHECKING_STATUS &&
              articlesOfOrganization.stepName != COMPLETED && (
                <Box
                  marginBottom={4}
                  bgcolor={theme.palette.primary.main}
                  color="white"
                  padding={2}
                >
                  <Grid container spacing={2}>
                    <Grid
                      style={{
                        color: theme.palette.accent.main,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={75}
                        color="inherit"
                        size={65}
                      />
                    </Grid>

                    <Grid xs>
                      <Typography variant="subtitle1">Filing Status</Typography>
                      <Typography variant="subtitle2">
                        SmartCapital is currently filing the Articles of
                        Organization and formation date for the Syndicate.
                        Filings typically take one business day to process. Once
                        filed, the Syndicate can begin accepting investments.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}

            {dateFiledReviewed &&
              !(
                (acceptChecks && paymentCheck) ||
                (acceptWires && paymentWire)
              ) && (
                <Box
                  marginBottom={4}
                  bgcolor={theme.palette.primary.main}
                  color="white"
                  padding={2}
                  onClick={() => {
                    const url = history.location.pathname;
                    const temp = url.replace(
                      new RegExp(`/agreements$`),
                      '/banking',
                    );
                    history.push(temp);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Grid container spacing={2}>
                    <Grid>
                      <Box fontSize={65} lineHeight={0}>
                        <AccountBalanceIcon
                          color="secondary"
                          fontSize="inherit"
                        />
                      </Box>
                    </Grid>

                    <Grid xs>
                      <Typography variant="subtitle1">
                        It is a great idea to go setup banking now! You cannot
                        collect funds until banking is setup.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
          </Grid>
        </Grid>
      </Box>

      {serviceAgreementId && (
        <>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="State Filings" value={0} />
            <Tab label="Federal Filings" value={1} />
          </Tabs>
          <TabPanel value={currentTab} index={0}>
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <StateRegistrationForm
                {...{ syndicate, onChange: setSyndicate }}
              />
            </Box>

            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: articlesOfOrganization,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
            {filings &&
              filings
                .filter((x) => x.form == 'cfgs')
                .sort((a, b) => (a.year > b.year ? -1 : 1))
                .map((filing) => {
                  return (
                    <Box
                      key={filing.unique_id}
                      borderBottom="1px solid lightgray"
                      style={{ padding: '1rem', paddingLeft: '' }}
                    >
                      <SyndicateFilingItem
                        {...{ filing, onChange: onListUpdate }}
                      />
                    </Box>
                  );
                })}
            {blueSkyFilingId && (
              <Box
                borderBottom="1px solid lightgray"
                style={{ padding: '1rem', paddingLeft: '' }}
              >
                <SyndicateDocumentItem
                  {...{
                    data: blueSkyFiling,
                    progressText: 'Currently Being Filed',
                  }}
                />
              </Box>
            )}
            {filings &&
              filings
                .filter((x) => x.form == 'periodic_report')
                .sort((a, b) => (a.year < b.year ? -1 : 1))
                .map((filing) => {
                  return (
                    <Box
                      key={filing.unique_id}
                      borderBottom="1px solid lightgray"
                      style={{ padding: '1rem', paddingLeft: '' }}
                    >
                      <SyndicateFilingItem
                        {...{ filing, onChange: onListUpdate }}
                      />
                    </Box>
                  );
                })}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem {...{ data: formSs4 }} />
            </Box>

            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: ein,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem {...{ data: powerOfAttorney }} />
            </Box>

            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: formID,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: formIDAccepted,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: formDSignature,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
            {formDRelated?.length > 0 &&
              formDRelated.map((relatedId, index) => (
                <FormDRelated
                  {...{ email, relatedId, index, canChange: isAdmin }}
                />
              ))}
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: formDFiled,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>

            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem
                {...{
                  data: formDAccepted,
                  progressText: 'Currently Being Filed',
                }}
              />
            </Box>
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export const StateRegistrationForm = ({ syndicate, onChange }) => {
  const { modal, showModal } = useStateFiling({
    data: syndicate,
    onChange,
  });
  const { stateRegistrationLink } = syndicate;
  return (
    <>
      {modal}
      <Grid container spacing={3}>
        <Grid xs>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Registration Website
          </Typography>
        </Grid>
        <Grid xs></Grid>
        <Grid>
          {stateRegistrationLink ? (
            <Box>
              <Button
                style={{ marginRight: '1rem' }}
                onClick={() => showModal({})}
              >
                Change
              </Button>
              <Button
                target="_blank"
                href={
                  stateRegistrationLink &&
                  stateRegistrationLink.indexOf('http') === -1
                    ? `https://${stateRegistrationLink}`
                    : stateRegistrationLink
                }
              >
                Review
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => showModal({})}
              >
                Add
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const FormDRelated = ({ relatedId, email, index, canChange }) => {
  const related = useSyndicateDocument({
    id: relatedId,
    documentName: `Form D - Related Persons - Page ${index + 1}`,
    linkEmail: email,
    canChange,
    role: 'manager',
  });

  return (
    <Box
      borderBottom="1px solid lightgray"
      style={{ padding: '1rem', paddingLeft: '' }}
    >
      <SyndicateDocumentItem {...{ data: related }} />
    </Box>
  );
};

export default SyndicateFiling;
