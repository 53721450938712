import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FormWizard from './FormWizard';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { useEditGroupManager } from 'hooks/forms/useFormGroupManager';
import getServerResponseErrors from 'api/getServerResponseErrors';
import groupManagersClient from 'api/groupManagersClient';

import syndicatesClient from 'api/syndicatesClient';
import { Skeleton } from '@mui/material';

const RelatedTypes = {
  director: 'Director',
  executive_officer: 'Executive Officer',
  promoter: 'Promoter',
};

const cmp = (a, b) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

const SyndicateFormManagers = ({
  groupId,
  data,
  onNext,
  onBack,
  onClose,
  buttonName,
}) => {
  const { id, syndicateManagers = [], primaryManager } = data || {};
  const [loading, setLoading] = useState(true);

  const [managerIds, setManagerIds] = useState([]);
  const [groupManagers, setGroupManagers] = useState(null);
  const [primary, setPrimary] = useState(primaryManager);
  const fetchCurrentManagers = async (currentGroupManagers) => {
    try {
      const response = await syndicatesClient.get_managers({ id });
      const list = response.data;
      setManagerIds(
        list.map((x) => {
          // if someone is deleted from the group manager default to the first one
          if (currentGroupManagers.find((y) => y.id === x.id)) {
            // if we have an id of a group and not a person
            // default to the first manager in the person list
            return currentGroupManagers.find((y) => y.id === x.id)
              .groupManagerPersons[0].id;
          }
          return x.groupManager.id;
        }),
      );
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  const fetchData = async () => {
    try {
      if (!loading) setLoading(true);
      const response = await groupManagersClient.index({ groupId });
      if (id) {
        await fetchCurrentManagers(response.data);
      } else {
        const allPersonIds = response.data.reduce(
          (acc, x) => [...acc, ...x.groupManagerPersons.map((y) => y.id)],
          [],
        );
        setManagerIds(
          syndicateManagers.filter((x) => allPersonIds.includes(x)),
        );
      }
      setGroupManagers(response.data);
      setTimeout(() => setLoading(false), 1000);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, groupId]);

  const { onListUpdate } = useArrayUpdate(setGroupManagers);

  const { modal, showModal } = useEditGroupManager({
    groupId,
    onChange: (item) => {
      onListUpdate(item);
      setManagerIds((cache) => {
        let temp = [...cache];
        // if the current selected person is removed then we need to set it to the first person
        // if group managers does not contain an id from the cache
        if (!item.groupManagerPersons.some((x) => cache.includes(x.id))) {
          // get manager ids that don't exist in groupManagers.groupManagerPersons
          const allIdsNotInThisGroup = (groupManagers || [])
            .filter((x) => x.id != item.id)
            .reduce((acc, x) => {
              acc = [...acc, ...x.groupManagerPersons.map((y) => y.id)];
              return acc;
            }, []);
          const idsNotInThisGroup = cache.filter((x) =>
            allIdsNotInThisGroup.includes(x),
          );
          const idsThatBelongToThisGroup = cache.filter(
            (x) => !allIdsNotInThisGroup.includes(x),
          );
          // filter ids still in the group
          const idsThatStillBelongToThisGroup = idsThatBelongToThisGroup.filter(
            (x) => item.groupManagerPersons.find((y) => y.id === x),
          );
          // if there are no ids that still belong to this group then we need to set the primary to the first person in the group
          if (idsThatStillBelongToThisGroup.length === 0) {
            temp = [...idsNotInThisGroup];
            temp.push(item.groupManagerPersons[0].id);
          }
          return temp;
        }
        return temp;
      });
      // if the primary is removed then we need to set it to the first person
      if (
        !primary ||
        (primary.groupManagerId == item.id &&
          !item.groupManagerPersons.find((x) => x.id == primary.id))
      ) {
        setPrimary(item.groupManagerPersons[0]);
      }
      setSelected(null);
    },
    fetchData,
  });

  const validate = () => {
    return {
      errors: [],
      values: {
        syndicateManagers: managerIds,
        primaryManager: primary,
      },
    };
  };
  const selectedManagers = managerIds
    .map((managerId) =>
      (groupManagers || [])
        .reduce((acc, x) => {
          acc = [...acc, ...x.groupManagerPersons];
          return acc;
        }, [])
        .find((x) => x.id === managerId),
    )
    .sort((a, b) => {
      return cmp(b.id == primary, a.id == primary) || cmp(a.name, b.name);
    })
    .filter((x) => x != null && x != undefined);
  useEffect(() => {
    if (selectedManagers.length == 1) {
      setPrimary(selectedManagers[0]);
    }
  }, [selectedManagers]);

  const [selected, setSelected] = useState(null);

  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Who is managing this syndicate?
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="subtitle2">
          A manager can be an individual or an entity such as a limited
          liability company, partnership, corporation, or trust.
        </Typography>
      </Box>
      <Box marginTop={2}>
        {modal}
        <Box display="flex" marginTop={2}>
          <Box width="20rem">
            <Select
              variant="outlined"
              fullWidth
              required
              displayEmpty
              renderValue={(val) => val?.name ?? 'Add Manager'}
              title={
                selectedManagers.length > 0
                  ? 'You can only add one manager'
                  : 'Add Manager'
              }
              value={selected}
              onChange={(e) => {
                e.target.value == -1
                  ? showModal({
                      groupManagerPersons: [
                        {
                          firstName: '',
                          lastName: '',
                          phone: '',
                          email: '',
                          relatedType: '',
                        },
                      ],
                    })
                  : setManagerIds((cache) => [...cache, e.target.value]);
                e.stopPropagation();
                e.preventDefault();
              }}
              disabled={selectedManagers.length > 0}
            >
              {(groupManagers || [])
                .filter(
                  (x) =>
                    !x.groupManagerPersons.some((y) =>
                      managerIds.includes(y.id),
                    ),
                )
                ?.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.groupManagerPersons[0].id}
                  >
                    {item.signerName || item.name}
                  </MenuItem>
                ))}
              <MenuItem key="manager" value={-1}>
                Create New Manager
              </MenuItem>
            </Select>
          </Box>
        </Box>

        {groupManagers && !loading && (
          <Box marginTop={2}>
            <Box marginTop={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Signer
              </Typography>
            </Box>

            {selectedManagers.length ? (
              selectedManagers
                .filter((x) => x?.id == primary?.id)
                .map((selectedPerson) => {
                  const manager = groupManagers.find((x) =>
                    x.groupManagerPersons
                      .map((y) => y.id)
                      .includes(selectedPerson.id),
                  );
                  return (
                    <Box key={manager.id} padding={1} marginTop={1}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                      >
                        <Grid md={3}>
                          <Box>
                            {manager.managerType == 'entity'
                              ? manager.name
                              : 'Individual'}
                          </Box>
                        </Grid>
                        <Grid md={4}>
                          {/* Dropdown for group manager person */}
                          {manager.managerType == 'entity' ? (
                            <>
                              <Select
                                id={`${manager.id}-person`}
                                value={primary?.id}
                                fullWidth
                                onChange={(e) => {
                                  setManagerIds((cache) => {
                                    const temp = [...cache];
                                    const currentIndex = temp.findIndex(
                                      (x) => x === selectedPerson.id,
                                    );
                                    temp[currentIndex] = e.target.value;
                                    return temp;
                                  });
                                  setPrimary(
                                    manager.groupManagerPersons.find(
                                      (x) => x.id == e.target.value,
                                    ),
                                  );
                                }}
                              >
                                {manager.groupManagerPersons.map((person) => (
                                  <MenuItem key={person.id} value={person.id}>
                                    {person.firstName} {person.lastName}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Box mt={1}>{primary.email}</Box>
                              <Box>{RelatedTypes[primary.relatedType]}</Box>
                            </>
                          ) : (
                            <>
                              <Box>
                                <Typography variant="subtitle2">
                                  {manager.groupManagerPersons[0].firstName}{' '}
                                  {manager.groupManagerPersons[0].lastName}
                                </Typography>
                              </Box>
                              <Box> {manager.groupManagerPersons[0].email}</Box>
                              <Box>
                                {' '}
                                {
                                  RelatedTypes[
                                    manager.groupManagerPersons[0].relatedType
                                  ]
                                }
                              </Box>
                            </>
                          )}
                        </Grid>
                        <Grid xs>
                          <Box textAlign="right">
                            {selectedManagers.length == 1 && (
                              <Button
                                style={{ marginRight: '1rem' }}
                                color="primary"
                                onClick={() =>
                                  setManagerIds((cache) => {
                                    const temp = [
                                      ...cache.filter(
                                        (x) => x != selectedPerson.id,
                                      ),
                                    ];
                                    return temp;
                                  })
                                }
                              >
                                Remove
                              </Button>
                            )}
                            <Button
                              color="primary"
                              onClick={() => showModal(manager)}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
            ) : (
              <Box>None Selected</Box>
            )}
          </Box>
        )}
        {loading && (
          <Box>
            <Skeleton
              animation="wave"
              height="58px"
              variant="rectangular"
              style={{ marginBottom: 10, marginTop: 16 }}
            />
            <Skeleton animation="wave" height="58px" variant="rectangular" />
          </Box>
        )}
        {
          <Box marginTop={2}>
            <FormWizard
              {...{
                form: <></>,
                validate,
                onNext,
                onBack,
                onClose,
                nextProps: {
                  disabled: managerIds.length == 0 || !primary,
                  disabledText: 'At least one manager must be added',
                },
                buttonName,
              }}
            />
          </Box>
        }
      </Box>
    </Box>
  );
};

export default SyndicateFormManagers;
