import React, { useContext, useEffect, useState } from 'react';
import userClient from 'api/user/userClient';
import {
  Paper,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Link,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useHistory } from 'react-router';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { SiWebpack } from 'react-icons/si';
import { STATUS_LOOKUP, TodoAction, useStatusColor } from './DashboardTodoItem';
import { useArrayUpdate } from 'hooks/useListUpdate';
import useTodoUndo from 'hooks/useTodoUndo';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';
import { StatusItem } from 'views/GroupInvestment/SyndicateTaxes/StatusCell';
import { CustomGridColumnMenu } from 'components/ui/CustomGridColumnMenu';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import SubscriptionWizard from 'views/UserInvestment/SubscriptionDetails/SubscriptionWizard';
import InterestWizard from 'views/UserInvestment/InterestWizard';
import { GroupContext } from 'components/UserAuth/UserAuth';
import { useSelectedGroup } from 'hooks/useAppState';

const getStatusValue = (interestAmount, status, isUndecided, statusName) => {
  return interestAmount && parseFloat(interestAmount) != 0 && status <= 1
    ? parseFloat(interestAmount) > 0 && isUndecided
      ? 'Maybe'
      : parseFloat(interestAmount) > 0
      ? 'Interested'
      : 'Passed'
    : statusName;
};
const ActionsCell = (props) => {
  const { row, updateInvestment, updateOpportunity, setSnackPack } = props;
  const {
    status: initialStatus,
    investment,
    opportunityPhoto,
    userInvestment: record,
  } = row;
  const { id, interestAmount, status: userStatus, isNew } = record || {};
  const [modal, showModal] = useState(isNew);
  const status = userStatus || initialStatus;
  const { dateClosed, syndicateId, id: investmentId } = investment;
  const canInvest = status > 0 && !dateClosed;
  const data = row;

  const onChange = (userInvestment) => {
    updateInvestment({ ...data, userInvestment });
    if (parseFloat(userInvestment?.interestAmount || 0) === -1) {
      setSnackPack((prev) => [
        ...prev,
        {
          ...data,
          userInvestment,
          key: new Date().getTime(),
        },
      ]);
    }
    if (!record) {
      updateOpportunity({ ...data, isDeleted: true });
    }
  };

  return (
    <>
      <TodoAction
        {...{ id, status, investmentId, interestAmount, onChange, showModal }}
      />
      {modal && (
        <DialogWithTitle
          open
          onClose={() => showModal(false)}
          title="Investment Interest"
          fullWidth
        >
          {canInvest ? (
            <SubscriptionWizard
              {...{
                onClose: () => showModal(false),
                onChange,
                investmentId,
                userInvestment: record,
                photo: opportunityPhoto,
                syndicateId,
              }}
            />
          ) : (
            <InterestWizard
              {...{
                onClose: () => showModal(false),
                onChange,
                photo: opportunityPhoto,
                investment,
                id,
              }}
            ></InterestWizard>
          )}
        </DialogWithTitle>
      )}
    </>
  );
};

const LinkCell = (props) => {
  const { row, field, url } = props;
  const history = useHistory();
  return (
    <Link onClick={() => history.push(url)} style={{ cursor: 'pointer' }}>
      <NameCell {...{ row, field }} />
    </Link>
  );
};

const NameCell = (props) => {
  const { row, field, fontSize } = props;
  return (
    <Box
      style={{
        whiteSpace: 'normal',
        lineHeight: 1.25,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        fontSize: fontSize || 'inherit',
      }}
      title={row[`${field}`]}
    >
      {row[`${field}`] || '-'}
    </Box>
  );
};

const AmountCell = (props) => {
  const { value, fontSize } = props;
  const theme = useTheme();
  return (
    <Box fontSize={fontSize} color={theme.palette.money.main}>
      {parseFloat(value || 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </Box>
  );
};

const StatusCell = (props) => {
  const { row, fontSize } = props;
  const { status: initialStatus, userInvestment } = row;
  const {
    status: userStatus,
    interestAmount,
    isUndecided,
  } = userInvestment || {};
  const status = userStatus || initialStatus;
  const selectedStatus = STATUS_LOOKUP[status];
  const { name: statusName } = selectedStatus || {};
  const statusColor = useStatusColor(status);

  return (
    <>
      {!interestAmount && (
        <StatusItem
          color={statusColor}
          content={statusName}
          styles={{ fontSize: fontSize || 'inherit' }}
        />
      )}
      {interestAmount && (
        <StatusItem
          color={statusColor}
          content={getStatusValue(
            interestAmount,
            status,
            isUndecided,
            statusName,
          )}
          styles={{ fontSize: fontSize || 'inherit' }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  moneyColor: {
    color: theme.palette.money.main,
  },
}));

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);

const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const DashboardTodoWidget = ({ showViewAll = true }) => {
  const [investments, setInvestments] = useState();
  const [opportunities, setOpportunities] = useState();
  const { getGroupSlug } = useContext(GroupContext) || {};
  const groupId = useSelectedGroup((state) => state.id);

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const isMedium = useMediaQuery('(min-width:1320px)');
  const isSmall = useMediaQuery(theme.breakpoints.between(942, 1030));
  const isTiny = useMediaQuery('(max-width:703px)');
  const isOverSmallTablet = useMediaQuery('(min-width:1200px)');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userClient.dashboard_active();
        const investments = response.data;
        if (investments.length < 3) {
          const opp = await userClient.dashboard_opportunities();
          setOpportunities(opp.data);
        }
        setInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    fetchData();
  }, []);

  const { onListUpdate: updateInvestment } = useArrayUpdate(setInvestments);
  const { onListUpdate: updateOpportunity } = useArrayUpdate(setOpportunities);
  const { Toast, setSnackPack } = useTodoUndo({ updateInvestment });
  const list = [...(investments || []), ...(opportunities || [])]
    .filter((x) => x.userInvestment?.interestAmount !== -1.0)
    .filter((x) => !groupId || x.groupId === groupId)
    .map((x) => ({
      ...x,
      internalId: x.userInvestment ? `inv-${x.id}` : `opp-${x.id}`,
    }));

  let columns = [
    {
      field: 'syndicateName',
      headerName: 'Syndicate',
      headerClassName: 'data-grid-header-no-padding',
      renderCell: (props) => (
        <NameCell {...{ ...props, fontSize: isTiny ? 12 : 'inherit' }} />
      ),
      flex: isTiny ? 0.75 : showViewAll ? 1 : 1.25,
    },
  ];

  if (isMedium) {
    columns.push({
      field: 'groupName',
      headerName: 'Group',
      headerClassName: 'data-grid-header-no-padding',
      renderCell: (props) => {
        const { row } = props;
        const { groupId } = row;
        const groupSlug = getGroupSlug(groupId);
        return <LinkCell {...{ ...props, url: `/${groupSlug}` }} />;
      },
      flex: showViewAll ? 0.6 : 0.75,
    });
  }
  if (isOverSmallTablet) {
    columns.push({
      field: 'opportunityName',
      headerName: 'Opportunity',
      headerClassName: 'data-grid-header-no-padding',
      renderCell: (props) => {
        const { row } = props;
        const { groupId, opportunityId } = row;
        const groupSlug = getGroupSlug(groupId);
        return (
          <LinkCell
            {...{ ...props, url: `/${groupSlug}/opportunity/${opportunityId}` }}
          />
        );
      },
      flex: 0.75,
    });
  }
  columns.push({
    field: 'parsedAmount',
    headerName: 'Amount',
    headerClassName: 'data-grid-header-no-padding',
    valueGetter: (params) => {
      const { row } = params;
      const { amount, userInvestment } = row;
      const { interestAmount } = userInvestment || {};
      const parsedAmount = parseFloat(
        (amount || 0) > 0 ? amount : interestAmount > 0 ? interestAmount : 0,
      );
      return parsedAmount;
    },
    renderCell: (props) => (
      <AmountCell {...{ ...props, fontSize: isTiny ? 12 : 'inherit' }} />
    ),
    flex: 0.5,
  });
  if (!isSmall) {
    columns.push({
      field: 'status',
      headerName: 'Status',
      headerClassName: 'data-grid-header-no-padding',
      renderCell: (props) => (
        <StatusCell {...{ ...props, fontSize: isTiny ? 12 : 'inherit' }} />
      ),
      type: 'singleSelect',
      valueGetter: (props) => {
        const { row } = props;
        const { status: initialStatus, userInvestment } = row;
        const {
          status: userStatus,
          interestAmount,
          isUndecided,
        } = userInvestment || {};
        const status = userStatus || initialStatus;
        const selectedStatus = STATUS_LOOKUP[status];
        const { name: statusName } = selectedStatus || {};
        var val = getStatusValue(
          interestAmount,
          status,
          isUndecided,
          statusName,
        );
        return val;
      },
      valueOptions: Object.values(STATUS_LOOKUP).map((x) => ({
        value: x.name,
        label: x.name,
      })),
      flex: 1.1,
    });
  }
  columns.push({
    field: 'actions',
    headerName: '',
    headerClassName: 'data-grid-header-no-padding',
    renderCell: (props) => (
      <ActionsCell
        {...props}
        {...{
          updateInvestment,
          updateOpportunity,
          setSnackPack,
        }}
      />
    ),
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableExport: true,
    flex: 1.5,
    align: 'right',
  });

  return (
    <>
      {Toast}
      <Grid
        container
        direction="column"
        alignItems="stretch"
        style={{ height: '100%' }}
      >
        <Grid style={{ height: '100%' }}>
          <Paper
            variant="outlined"
            alignItems="center"
            style={{ padding: 25, borderRadius: 19, height: '100%' }}
          >
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography style={{ fontSize: 24, fontWeight: 700 }}>
                Actions
              </Typography>
              {showViewAll && (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/user/actions`)}
                >
                  View All
                </Button>
              )}
            </Box>
            <Box height={showViewAll ? '267px' : 'auto'}>
              <DataGridPro
                className={classes.root}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  ColumnMenu: CustomGridColumnMenu,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'status',
                          operatorValue: 'not',
                          value: 'Passed',
                        },
                      ],
                    },
                  },
                }}
                rows={list}
                columns={columns}
                disableSelectionOnClick
                disableColumnSelector
                pagination={showViewAll}
                autoPageSize={showViewAll}
                autoHeight={!showViewAll}
                getRowId={(row) => row.internalId}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardTodoWidget;
