import React from 'react';
import { Grid } from '@mui/material';
import { FormikTextInput } from 'components/ui/CustomTextField';

const AddressFields = ({ prefix = '', required }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikTextInput
            name={`name`}
            label={`${prefix} Name`.trim()}
            variant="filled"
            required={required}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextInput
            name={`street`}
            label={`${prefix} Street Address`.trim()}
            variant="filled"
            required={required}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextInput
            name={`secondary`}
            label={`${prefix} Secondary Address (optional)`.trim()}
            variant="filled"
            placeholder="Building, Suite, Room, Apt. etc"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormikTextInput
            name={`city`}
            label={`${prefix} City`.trim()}
            variant="filled"
            required={required}
          />
        </Grid>
        <Grid item xs={7} md={4}>
          <FormikTextInput
            name={`state`}
            label={`${prefix} State/Region`.trim()}
            variant="filled"
            required={required}
          />
        </Grid>
        <Grid item xs={5} md={3}>
          <FormikTextInput
            name={`postal`}
            label={`${prefix} Postal Code`.trim()}
            variant="filled"
            required={required}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressFields;
