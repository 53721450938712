import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import FormWizard from './FormWizard';

import { FormikDatePicker } from 'components/ui/CustomDatePicker';

const useSyndicateFormClosingDates = ({ data }) => {
  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { initialClosing, finalClosing },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        initialClosing,
        finalClosing,
      },
    };
  };

  const form = (
    <Form initialValues={data} enableReinitialize setRef={setFormRef}>
      <Box marginTop={2}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          What are your closing dates for the Syndicate?
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Initial Closing Date
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="subtitle2">
          Initial Closing Date is the first date you can bind investors to their
          subscriptions, and wire the money to the opportunity.
        </Typography>
      </Box>
      <Box marginTop={2} maxWidth="15rem">
        <FormikDatePicker
          name="initialClosing"
          label="Initial Closing"
          variant="filled"
          required
        />
      </Box>
      <Box marginTop={4}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Final Closing Date
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="subtitle2">
          Final Closing Date is the last date you can bind investors to their
          subscriptions, and wire the money to the opportunity.
        </Typography>
      </Box>
      <Box marginTop={2} maxWidth="15rem">
        <FormikDatePicker
          name="finalClosing"
          label="Final Closing"
          variant="filled"
          required
        />
      </Box>
    </Form>
  );

  return { form, validate };
};

const SyndicateFormClosingDates = ({
  data,
  onNext,
  onBack,
  onClose,
  buttonName,
}) => {
  const { form, validate } = useSyndicateFormClosingDates({ data });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormClosingDates;
