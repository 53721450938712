import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography, Avatar, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { signOutCurrentUser } from 'redux/currentUser/actions';
import { useProfile } from 'hooks/useAppState';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import useEmailHelp from 'hooks/useEmailHelp';
import { MdHelp } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  groupNameContainer: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  userAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  userMenuContainer: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.link.main,
    },
    '&:active': {
      color: theme.palette.accent.main,
    },
  },
  open: {
    color: theme.palette.accent.main,
  },
}));

const UserMenu = ({ url }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isValid, name, photo, initials } = useProfile((state) => ({
    isValid: state.isValid,
    name: state.name,
    photo: state.photo,
    initials: state.initials,
  }));
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState();

  const handleSignOut = () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    dispatch(signOutCurrentUser());
    const redirectUrl = url('/sign-in');

    history.push(redirectUrl);
    // window.location.reload();
  };

  // const { toggleZendesk } = useZendesk({ name, email });
  const { helpComponent, toggleSupport } = useEmailHelp();
  if (!isValid) {
    return null;
  }
  return (
    <CenteredContentBox marginRight={1} marginLeft="auto">
      <Grid container alignItems="center">
        <Grid sx={{ display: { xs: 'none', sm: 'inherit' } }}>
          <Link
            onClick={toggleSupport}
            sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            title="Contact Support"
            className={classes.userMenuContainer}
          >
            <MdHelp id="live-support" />
          </Link>
          {helpComponent}
        </Grid>
        <Grid
          style={{ display: 'flex', alignItems: 'center' }}
          className={clsx(classes.userMenuContainer, {
            [classes.open]: Boolean(userMenuAnchorEl),
          })}
          onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}
        >
          <Grid container alignItems="center">
            <Grid sx={{ display: { xs: 'none', xl: 'block' } }} ml={1}>
              <Typography variant="subtitle1">{name}</Typography>
            </Grid>
            <Grid>
              <Box marginLeft={2} display="flex" alignItems="center">
                <Box marginRight={0.5}>
                  <Avatar src={photo} className={classes.userAvatar}>
                    {initials}
                  </Avatar>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={userMenuAnchorEl}
        keepMounted
        open={Boolean(userMenuAnchorEl)}
        onClose={() => setUserMenuAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ horizontal: 'right' }}
      >
        <MenuItem onClick={() => history.push('/user/profile')}>
          Profile
        </MenuItem>
        <MenuItem onClick={toggleSupport}>Contact Support</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </CenteredContentBox>
  );
};

export default UserMenu;
