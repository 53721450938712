import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Profile from 'views/Profile/Profile';

import WithNavBar from 'layouts/WithNavBar';

import UserDashboardIndex from './UserDashboardIndex';
import AuthenticatedRoute from 'components/Route/AuthenticatedRoute';
import SignIn from 'views/SignIn/SignIn';
import UserNavigation from './UserNavigation';
import UserDashboardTodo from './UserDashboardTodo';
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { setSelectedGroup } from 'redux/currentUser/actions';

const WithDashboardNavBar = (props) => {
  return WithNavBar({ ...props, NavBar: UserNavigation, ContentWrapper });
};

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
}));
const ContentWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} padding={2} paddingTop={4}>
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

const UserRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selected group
    dispatch(setSelectedGroup(null));
  }, [dispatch]);
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path="user/profile"
        component={Profile}
        layout={WithDashboardNavBar}
      />
      <AuthenticatedRoute
        isPublic
        path={['/user/sign-in']}
        component={SignIn}
      />
      <AuthenticatedRoute
        layout={WithDashboardNavBar}
        path="/user/dashboard"
        component={UserDashboardIndex}
      />
      <AuthenticatedRoute
        exact
        layout={WithDashboardNavBar}
        path="/user/actions"
        component={UserDashboardTodo}
      />
      <AuthenticatedRoute
        exact
        path="/user/profile"
        component={Profile}
        layout={WithDashboardNavBar}
      />

      <Redirect from="/user" to="/user/dashboard" />
    </Switch>
  );
};

export default UserRoutes;
