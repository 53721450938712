import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

import InvestmentInstructions from '../InvestmentInstructions';
import userInvestmentsClient from 'api/userInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';

const SubscriptionInvestment = ({ userInvestment, onClose, actionButton }) => {
  const { id, subscription } = userInvestment;
  const [instructions, setInstructions] = useState();
  const { signatures } = subscription || {};
  const managerSigned = signatures.find(
    (x) => x.memberType === 'manager',
  )?.dateSigned;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.instructions({
          id,
        });
        setInstructions(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    } else {
      setInstructions(null);
    }
  }, [id]);

  return (
    <Box>
      <Box marginTop={5}>
        {!instructions && <LoadingSpinner />}
        {instructions && (
          <InvestmentInstructions
            {...{ userInvestment, instructions }}
          ></InvestmentInstructions>
        )}
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>{!managerSigned && actionButton}</Grid>
          <Grid item>
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionInvestment;
