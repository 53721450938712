import React, { useCallback, useEffect, useState } from 'react';
import { Switch, useParams, Redirect, useHistory } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import OpportunitiesIndex from 'views/OpportunitiesIndex/OpportunitiesIndex';
import AdminDashboard from 'views/AdminDashboard/AdminDashboard';
import CreateOpportunity from 'views/CreateOpportunity/CreateOpportunity';
import EditOpportunity from 'views/EditOpportunity/EditOpportunity';
import InvestorsIndex from 'views/InvestorsIndex/InvestorsIndex';

import UserSetup from 'views/UserSetup/UserSetup';
import Profile from 'views/Profile/Profile';
import { useDispatch } from 'react-redux';
import { changePortal, updateCurrentUserData } from 'redux/currentUser/actions';
import SignIn from 'views/SignIn/SignIn';
import CustomRoute from './CustomRoute';
import RequestAccess from 'views/RequestAccess/RequestAccess';
import AcceptInvitation from 'views/Profile/AcceptInvitation';
import OpportunityShowcase from 'views/OpportunityDetails/OpportunityShowcase';
import WithNavBar from 'layouts/WithNavBar';
import GroupNavigation from './GroupNavigation';
import { Box, Button, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SyndicatesIndex from 'views/SyndicatesIndex/SyndicatesIndex';
import { useProfile, useSelectedGroup } from 'hooks/useAppState';
import userClient from 'api/user/userClient';
import { TourProvider } from '@reactour/tour';
import getServerResponseErrors from 'api/getServerResponseErrors';
import ManagementDashboard from 'views/ManagementDashboard/ManagementDashboard';
import SyndicateDetail from 'views/SyndicatesIndex/SyndicateDetail';
import UserDashboardTodo from 'views/UserDashboard/UserDashboardTodo';

const WithGroupNavBar = (props) => {
  return WithNavBar({ ...props, NavBar: GroupNavigation, ContentWrapper });
};

const handleMessageDismiss = async (id, dismissField, dispatch) => {
  try {
    const response = await userClient.updateUserData({
      id,
      data: {
        [dismissField]: true,
      },
    });
    await dispatch(updateCurrentUserData(response.data));
  } catch (e) {
    console.log(getServerResponseErrors(e));
  }
};

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
}));

const ContentWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} paddingTop={2}>
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

const GroupRoutes = () => {
  const [isLoading, setLoading] = useState(true);
  const { groupSlug } = useParams();
  const dispatch = useDispatch();
  const { isAdmin } = useSelectedGroup((state) => ({
    isAdmin: state.isAdmin,
  }));
  useEffect(() => {
    const fetchData = async (groupSlug) => {
      if (groupSlug) {
        setLoading(true);
        await dispatch(changePortal(groupSlug));
        setLoading(false);
      }
    };
    fetchData(groupSlug);
  }, [groupSlug, dispatch]);

  if (isLoading) {
    // show loading screen
    return null;
  }
  return (
    <Switch>
      <AuthenticatedRoute
        isPublic
        path={['/:groupSlug/sign-in']}
        component={SignIn}
      />
      <AuthenticatedRoute
        isPublic
        path={['/:groupSlug/accept-invitation']}
        component={AcceptInvitation}
      />
      <AuthenticatedRoute
        isPublic
        exact
        path={['/:groupSlug/request-access']}
        component={RequestAccess}
      />
      <AuthenticatedRoute
        exact
        path="/:groupSlug/profile"
        component={Profile}
        layout={WithGroupNavBar}
      />
      <AuthenticatedRoute
        exact
        layout={WithGroupNavBar}
        path="/:groupSlug/opportunity/create"
        component={CreateOpportunity}
      />
      <AuthenticatedRoute
        exact
        layout={WithGroupNavBar}
        path="/:groupSlug/opportunity/:id"
        component={OpportunityShowcase}
      />
      <CustomRoute
        layout={WithGroupNavBar}
        path="/:groupSlug/opportunity/:id/edit"
        component={EditOpportunity}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/admin'}
        component={AdminDashboard}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/management'}
        component={ManagementDashboard}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/investors'}
        component={InvestorsIndex}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/syndicates/:id'}
        component={({ match }) => {
          // console.log(match.params.id);
          return <SyndicateDetail id={match.params.id} />;
        }}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/syndicates'}
        component={SyndicatesIndex}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={'/:groupSlug/setup'}
        component={UserSetup}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={['/:groupSlug/opportunities']}
        component={OpportunitiesIndex}
      />
      <AuthenticatedRoute
        layout={WithGroupNavBar}
        path={['/:groupSlug/actions']}
        component={UserDashboardTodo}
      />
      <Redirect
        from="/:groupSlug"
        to={isAdmin ? '/:groupSlug/syndicates' : '/:groupSlug/opportunities'}
      />
    </Switch>
  );
};
const GroupRoutesWrapper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = useProfile((state) => state.id);
  const { slug } = useSelectedGroup((state) => ({
    slug: state.slug,
  }));
  const url = useCallback((url = '') => `/${slug}${url}`, [slug]);

  return (
    <TourProvider
      showCloseButton={false}
      disableFocusLock
      disableDotsNavigation
      disableKeyboardNavigation
      scrollSmooth
      onClickHighlighted={(e) => {
        e.stopPropagation();
      }}
      disableInteraction
      onClickMask={() => {}}
      prevButton={() => null}
      nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const last = currentStep === stepsLength - 1;
        return (
          <Box pl={1}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (last) {
                  handleMessageDismiss(
                    id,
                    'managerGuideDismissed',
                    dispatch,
                  ).finally(() => {
                    setIsOpen(false);
                  });
                } else {
                  if (currentStep === 1) {
                    history.push(url('/investors'));
                  } else if (currentStep === 3) {
                    history.push(url('/syndicates'));
                  }
                  setCurrentStep((s) => (s === stepsLength - 1 ? 0 : s + 1));
                }
              }}
            >
              {last ? 'Close' : 'Next'}
            </Button>
          </Box>
        );
      }}
    >
      <GroupRoutes />
    </TourProvider>
  );
};
export default GroupRoutesWrapper;
