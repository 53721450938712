import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import useSticky from 'hooks/useSticky';
import styled from '@emotion/styled'; // required because Box refs are not fixed until material ui 5

const StyledBox = styled(Box)``;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <CenteredContentBox>
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    </CenteredContentBox>
  );
};

const WithNavBar = ({ NavBar, children, ContentWrapper }) => {
  const [navBarHeight, setNavBarHeight] = useState();
  const { isSticky, element } = useSticky();
  const navBarRef = useRef(null);

  useEffect(() => {
    const height = navBarRef?.current?.getBoundingClientRect().height;
    setNavBarHeight(height);
  }, [isSticky]);
  return (
    <Box>
      <NavBar sticky={isSticky} navBarRef={navBarRef} />
      <StyledBox
        style={{
          marginTop: isSticky ? `${navBarHeight}px` : '',
          position: 'relative',
          height: `calc(100vh - ${navBarHeight}px`,
        }}
        ref={element}
      >
        <Box paddingBottom={10}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ContentWrapper>{children}</ContentWrapper>
          </ErrorBoundary>
        </Box>
      </StyledBox>
    </Box>
  );
};

export default WithNavBar;
