import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { FeaturedInvestorView } from 'views/FeaturedInvestors';

const OpportunitySectionFeatured = ({ opportunity }) => {
  const { featured = [] } = opportunity || {};

  if (!featured || featured.length === 0) {
    return null;
  }

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Key Investors
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3} alignItems="stretch">
          {featured.map((investor) => (
            <Grid key={investor.id} xs={12} sm={12} md={6}>
              <FeaturedInvestorView data={investor} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default OpportunitySectionFeatured;
