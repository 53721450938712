import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingSpinner } from 'components/ui/Loading';

import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import { useProfile } from 'hooks/useAppState';

import useSyndicate from 'hooks/useSyndicate';
import { SmartCapitalFeeTable } from 'views/UserInvestment/FeeTable';

const SyndicateSmartCapitalAgreements = ({ investment, setInvestment }) => {
  const { syndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  if (!syndicate) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <Box>
      <SyndicateSmartCapitalAgreementsHelper {...{ investment, syndicate }} />
    </Box>
  );
};

const SyndicateSmartCapitalAgreementsHelper = ({ investment, syndicate }) => {
  const { roundName, syndicateName } = investment;
  const {
    serviceDocumentSignatureId,
    statementOfWorkId,
    serviceAgreementId,
    primaryManager,
    dateFiledOfferingAuthorization,
  } = syndicate || {};

  const isAdmin = useProfile((state) => state.isAdmin);
  const canUpload = isAdmin && dateFiledOfferingAuthorization;

  const {
    groupManager: { email },
  } = primaryManager || {};

  const statementOfWork = useSyndicateDocument({
    id: statementOfWorkId,
    documentName: 'Statement of Work',
    role: 'manager',
    canChange: canUpload,
  });
  const serviceAgreement = useSyndicateDocument({
    id: serviceAgreementId,
    documentName: 'Service Agreement',
    role: 'manager',
    canChange: canUpload,
  });

  const serviceDocumentSignature = useSyndicateDocument({
    id: serviceDocumentSignatureId,
    documentName: 'Service Signature',
    linkEmail: email,
    role: 'manager',
    canChange: canUpload,
  });

  return (
    <Box>
      <Box>
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid xs={12} md={4}>
            <Box>
              {syndicateName ? (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {syndicateName}
                </Typography>
              ) : (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {roundName}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={4}
        borderBottom="1px solid lightgray"
        style={{ paddingBottom: '0.5rem' }}
      >
        <Typography color="primary">Service Fees</Typography>
      </Box>
      <Box mt={2}>
        <SmartCapitalFeeTable {...{ investment, syndicate }} />
      </Box>

      {serviceAgreementId && (
        <>
          <Box
            marginTop={4}
            borderBottom="1px solid lightgray"
            style={{ paddingBottom: '0.5rem' }}
          >
            <Typography color="primary">Service Agreements</Typography>
          </Box>
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem {...{ data: statementOfWork }} />
          </Box>
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem {...{ data: serviceAgreement }} />
          </Box>

          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem {...{ data: serviceDocumentSignature }} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SyndicateSmartCapitalAgreements;
