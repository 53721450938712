import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';

import { FormikCheckBox } from 'components/ui/CustomCheckBox';

const useFormInvestmentVisibility = ({ data, dateFiledOfferingAgreements }) => {
  const [formRef, setFormRef] = useState({});

  const validate = async () => {
    const {
      values: {
        showTargetAmount,
        showInterestAmount,
        showSubscribedAmount,
        showAcceptedAmount,
        showConfirmedAmount,
      },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        showTargetAmount,
        showInterestAmount,
        showSubscribedAmount,
        showAcceptedAmount,
        showConfirmedAmount,
      },
    };
  };

  const form = (
    <Form initialValues={data} enableReinitialize setRef={setFormRef}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <Box>
            <Typography>Investor View Controls</Typography>
            <Typography variant="caption">
              Select what amounts, if any, you would like to display to
              investors viewing the opportunity. The amounts can communicate to
              investors how much the syndicate is targeting to invest, how much
              interest has been collected, and the amount invested. If you
              wouldn&apos;t like investors to see an amount, then leave the
              display setting unchecked.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={6}>
          <FormikCheckBox
            name="showTargetAmount"
            variant="filled"
            label="Display Targeted Amount"
          />
        </Grid>
        <Grid xs={6}>
          <FormikCheckBox
            name="showInterestAmount"
            variant="filled"
            label="Display Interested Amount"
          />
        </Grid>
        {dateFiledOfferingAgreements && (
          <Grid xs={6}>
            <FormikCheckBox
              name="showSubscribedAmount"
              variant="filled"
              label="Display Proposed Amount"
            />
          </Grid>
        )}
        {dateFiledOfferingAgreements && (
          <Grid xs={6}>
            <FormikCheckBox
              name="showAcceptedAmount"
              variant="filled"
              label="Display Invested Amount"
            />
          </Grid>
        )}
        {/* <Grid xs={4}>
          <FormikCheckBox
            name="showConfirmedAmount"
            variant="filled"
            label="Display Collected Amount"
          />
        </Grid> */}
      </Grid>
    </Form>
  );

  return { form, validate };
};

export default useFormInvestmentVisibility;
