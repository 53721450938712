import React from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SaveFormButton from 'components/Form/SaveFormButton';
import Wizard from 'components/ui/wizard/Wizard';

const FormWizard = ({
  onNext = null,
  onBack = null,
  onClose,
  form,
  validate,
  buttonName = 'Next',
  nextProps = null,
  extraButtons = null,
  extraInfo = null,
}) => {
  const handleNext = async (onError) => {
    const { values, errors } = await validate();
    if (errors.length > 0) {
      onError(errors);
      return false;
    }
    return (await onNext(values, onError)) ?? true;
  };
  const handleBack = async () => {
    const { values } = await validate();
    await onBack(values);
  };

  return (
    <Wizard
      content={
        <Box position="relative">
          <Box>{form}</Box>
        </Box>
      }
      footer={
        <Box marginTop={4}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
          >
            <Grid>
              {onClose && (
                <Button variant="contained" onClick={onClose} color="grey">
                  Close
                </Button>
              )}
            </Grid>
            {extraInfo && <Grid>{extraInfo}</Grid>}
            <Grid>
              {extraButtons}
              {onBack && (
                <Button
                  variant="outlined"
                  onClick={handleBack}
                  style={{ marginRight: '1rem' }}
                >
                  Back
                </Button>
              )}
              {onNext && (
                <SaveFormButton
                  onSave={handleNext}
                  name={buttonName}
                  {...nextProps}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default FormWizard;
