import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import ManagementSettings from './ManagementSettings';
import { useProfile, useSelectedGroup } from 'hooks/useAppState';
import UserOnboardingMessage from 'components/Message/UserOnboardingMessage';

const ManagementDashboard = () => {
  const { firstName, adminMessageDismissed } = useProfile((state) => ({
    firstName: state.firstName,
    adminMessageDismissed: state.adminMessageDismissed,
  }));
  const id = useSelectedGroup((state) => state.id);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  useEffect(() => {
    setShowWelcomeMessage(!adminMessageDismissed);
  }, [adminMessageDismissed]);
  // const getInitialTabIndexFromUrl = () => {
  //   const pathSegments = get(props, 'location.pathname').split('/');
  //   switch (pathSegments[pathSegments.length - 1]) {
  //     case 'opportunities':
  //       return 0;
  //     case 'investors':
  //       return 1;
  //     case 'settings':
  //       return 2;
  //     default:
  //       return 0;
  //   }
  // };
  return (
    <Box>
      {showWelcomeMessage && (
        <UserOnboardingMessage dismissField="adminMessageDismissed">
          <Typography variant="h5" gutterBottom>
            Welcome {firstName}
          </Typography>
          <Typography variant="body1">
            This is your admin view. You can update group specific information
            and create/edit your management entity here.
          </Typography>
        </UserOnboardingMessage>
      )}
      <ManagementSettings id={id} />
    </Box>
  );
};

export default withRouter(ManagementDashboard);
