import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import qs from 'query-string';

import GenericPortal from 'views/GenericPortal';

const SignUpConfirmation = () => {
  const { errors } = qs.parse(window.location.search);
  useEffect(() => {
    if (errors) return;
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [errors]);
  return (
    <GenericPortal title={'Account Confirmation'}>
      {errors ? (
        <Box textAlign="center">
          <Box marginBottom={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Confirmation invalid
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {errors}
            </Typography>
          </Box>
          <Box>
            <Button color="primary" variant="contained" href="/sign-in">
              Go to Sign In
            </Button>
          </Box>
        </Box>
      ) : (
        <Box textAlign="center">
          <Box marginBottom={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Your account has been confirmed.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Please wait while we redirect you to sign in
            </Typography>
          </Box>
          <CircularProgress />
        </Box>
      )}
    </GenericPortal>
  );
};

export default SignUpConfirmation;
