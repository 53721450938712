import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Form from 'components/Form/Form';
import AddressFields from 'components/ui/AddressFields';

const EditMailingAddress = ({
  name = 'Address',
  nameLabel,
  initialValues,
  setRef,
}) => {
  const { secondary, country, attn } = initialValues || {};
  const defaultValues = initialValues || {};
  return (
    <Form initialValues={defaultValues} setRef={setRef} enableReinitialize>
      <Box marginBottom={6}>
        <Box marginBottom={2}>
          <Typography gutterBottom>{name}</Typography>
        </Box>
        <Box marginBottom={3}>
          <Grid container spacing={3}>
            <Grid>
              <AddressFields
                prefix={nameLabel}
                showAdditional={secondary || country || attn}
                required
              ></AddressFields>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Form>
  );
};
export default EditMailingAddress;
