import React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const SummaryItem = ({
  Icon,
  iconFontSize = 46,
  name,
  value,
  valueProps,
  titleProps,
  summaryTitle,
}) => {
  return (
    <Grid>
      <Paper
        variant="outlined"
        alignItems="center"
        style={{ padding: 25, borderRadius: 19, height: 100 }}
        title={summaryTitle}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: '100%' }}
            >
              {Icon && (
                <Icon
                  fontSize={iconFontSize}
                  style={{ marginRight: 25, fill: 'url(#mygradient)' }}
                />
              )}
              <Typography
                style={{ fontSize: 24, fontWeight: 700 }}
                {...titleProps}
              >
                {name}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography style={{ fontSize: 24 }} {...valueProps}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default SummaryItem;
