import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box, Typography, Link, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DialogWithTitle from 'components/ui/DialogWithTitle';
import ChangeDocument from 'views/SyndicateWizard/ChangeDocument';
import { Alert } from '@mui/material';
import isEqual from 'lodash/isEqual';
import useSyndicateDocumentPoll, {
  documentStates,
} from 'hooks/useSyndicateDocumentPoll';

const {
  CHECKING_STATUS,
  NOT_READY,
  AWAITING_UPLOAD,
  NO_DOC,
  NEEDS_SIGNATURE,
  CONFIRMING_SIGNATURE,
  AWAITING_SIGNATURE,
  COMPLETED,
  NO_ID,
  EXPIRED,
} = documentStates;

const steps = {
  [NO_ID]: {
    name: NO_ID,
    start: 0,
    end: 0,
    shouldProgress: true,
  },
  [CHECKING_STATUS]: {
    name: CHECKING_STATUS,
    start: 0,
    end: 20,
    shouldProgress: true,
    progressText: 'Checking Status ...',
  },
  [NOT_READY]: {
    name: NOT_READY,
    start: 20,
    end: 50,
    shouldProgress: true,
    progressText: 'Generating ...',
  },
  [AWAITING_UPLOAD]: {
    name: AWAITING_UPLOAD,
    start: 50,
    end: 50,
    shouldProgress: false,
    progressText: 'Awaiting Document Upload',
  },
  [NO_DOC]: {
    name: NO_DOC,
    start: 50,
    end: 95,
    shouldProgress: true,
  },
  [NEEDS_SIGNATURE]: {
    name: NEEDS_SIGNATURE,
    start: 50,
    end: 50,
    shouldProgress: false,
  },
  [CONFIRMING_SIGNATURE]: {
    name: CONFIRMING_SIGNATURE,
    start: 50,
    end: 95,
    shouldProgress: true,
  },
  [AWAITING_SIGNATURE]: {
    name: AWAITING_SIGNATURE,
    start: 80,
    end: 80,
    shouldProgress: false,
  },
  [COMPLETED]: {
    name: COMPLETED,
    start: 101,
    end: 101,
    shouldProgress: false,
  },
  [EXPIRED]: {
    name: EXPIRED,
    start: 0,
    end: 0,
    shouldProgress: false,
  },
};

const useSyndicateDocument = ({
  id,
  data = null,
  documentName = null,
  canChange = false,
  onUpdate = null,
  role = 'person',
}) => {
  const [wizard, setWizard] = useState(false);

  const {
    data: briefcase,
    documentState,
    error,
    refreshData,
    openLink,
  } = useSyndicateDocumentPoll({
    id,
    data,
    role,
  });

  const step = steps[documentState];
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const { start, end, shouldProgress } = step;
    let progressTimer;
    const increaseBufferAndProgress = () => {
      progressTimer = setTimeout(() => {
        setProgress((p) => {
          const next = p + Math.random() * 2;
          if (next < end) {
            return next;
          }
          return start;
        });
        increaseBufferAndProgress();
      }, 1000);
    };
    setProgress(start);
    if (shouldProgress) {
      increaseBufferAndProgress();
    }
    return () => {
      clearTimeout(progressTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentState]);

  useEffect(() => {
    if (onUpdate && briefcase && !isEqual(data, briefcase)) {
      onUpdate(briefcase);
    }
  }, [data, briefcase, onUpdate, id]);

  const buttons = useMemo(() => {
    const {
      title,
      // signatureRequired = false,
      documentData,
      externalIntegration,
    } = briefcase || {};
    const canSign =
      documentState == NEEDS_SIGNATURE || documentState == CONFIRMING_SIGNATURE;
    const isExpired = documentState == EXPIRED;
    const isReady = !(
      documentState == NO_ID ||
      documentState == CHECKING_STATUS ||
      documentState == NOT_READY
    );
    const hasDocument = externalIntegration || documentData;
    return (
      <>
        {wizard && (
          <DialogWithTitle
            open
            onClose={() => setWizard(null)}
            title={`${title}`}
            maxWidth="sm"
          >
            <ChangeDocument
              onChange={refreshData}
              onClose={() => setWizard(null)}
              {...{
                data: briefcase,
                signatureWarning: false,
              }}
            ></ChangeDocument>
          </DialogWithTitle>
        )}
        {canChange && isReady && (
          <Button
            variant={documentData ? 'outlined' : 'contained'}
            color="primary"
            onClick={() => setWizard(true)}
            disabled={!isReady}
          >
            {documentData ? 'Change' : 'Upload'}
          </Button>
        )}
        {hasDocument && briefcase && !isExpired && (
          <Button
            variant={canSign ? 'contained' : 'outlined'}
            color="primary"
            target="_blank"
            disabled={!isReady}
            onClick={openLink}
            style={{ marginLeft: '1rem' }}
          >
            {canSign ? 'Sign' : 'Review'}
          </Button>
        )}
      </>
    );
  }, [briefcase, canChange, documentState, openLink, refreshData, wizard]);

  const { progressText: stepText } = step || {};
  const { title, signatures = [] } = briefcase || {};
  const missingSignatures = signatures.filter((x) => !x.dateSigned);
  return {
    name: documentName || title,
    error,
    buttons,
    stepText,
    stepName: documentState,
    progress,
    refreshData,
    missingSignatures,
  };
};

export const SyndicateDocumentItem = ({ data, progressText = null }) => {
  const {
    name,
    error,
    refreshData,
    progress,
    buttons,
    stepText,
    stepName,
    missingSignatures,
  } = data;

  return (
    <>
      <Grid container spacing={3}>
        <Grid>
          <Box width="20rem">
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
          </Box>
        </Grid>
        <Grid xs>
          <Box marginTop={1}>
            {error ? (
              <>
                <Alert severity="warning">
                  <div>
                    There was an error fetching the document. Click{' '}
                    <Link style={{ cursor: 'pointer' }} onClick={refreshData}>
                      here
                    </Link>{' '}
                    to retry
                  </div>
                </Alert>
              </>
            ) : stepName != COMPLETED ? (
              <>
                <LinearProgress variant="determinate" value={progress} />
                <Box textAlign="center" marginTop={1}>
                  {stepName == EXPIRED ? (
                    <Typography variant="subtitle2">
                      Document has Expired
                    </Typography>
                  ) : stepName == CONFIRMING_SIGNATURE ? (
                    <Typography variant="subtitle2">
                      Awaiting Signature Confirmation
                    </Typography>
                  ) : stepName == NEEDS_SIGNATURE ? (
                    <Typography variant="subtitle2">
                      Signature Required
                    </Typography>
                  ) : stepName === AWAITING_SIGNATURE ? (
                    <>
                      <Typography variant="subtitle2">
                        Waiting for Signature(s)
                      </Typography>
                      <Typography variant="subtitle2">
                        {(missingSignatures || [])
                          .map((x) => x.signerName)
                          .join(', ')}
                      </Typography>
                    </>
                  ) : stepName != NO_ID ? (
                    <Typography variant="subtitle2">
                      {progressText || stepText}
                    </Typography>
                  ) : null}
                </Box>
              </>
            ) : (
              ''
            )}
          </Box>
        </Grid>

        <Grid>
          {stepName !== NO_ID ? (
            <Box width="20rem" textAlign="right">
              {buttons}
            </Box>
          ) : (
            <Box width="20rem" textAlign="right">
              Not Filed Yet
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default useSyndicateDocument;
