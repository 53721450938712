import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import makeStyles from '@mui/styles/makeStyles';

import { FcBriefcase, FcRatings, FcMoneyTransfer } from 'react-icons/fc';
import { BsArrowRight } from 'react-icons/bs';

const useStyles = makeStyles((theme) => ({
  typeGraphic: {
    marginBottom: theme.spacing(1),
    img: {
      height: '5rem',
    },
  },
  notselected: {
    height: '100%',
    // border: '1px solid',
    // borderRadius: '0.75rem',
    borderColor: theme.palette.grey[400],
  },
}));

const syndicationSteps = [
  {
    id: 'create',
    title: 'Create Syndicate',
    description:
      'Each time you want to invest in a company, you make a new Syndicate for each round.',
    image: <FcRatings size={100} />,
  },
  {
    id: 'collect',
    title: 'Collect Investments',
    description:
      'After the syndicate is created and signed, you can then accept investments from members in your group.',
    image: <FcMoneyTransfer size={100} />,
  },
  {
    id: 'close',
    title: 'Close & Invest',
    description:
      'Once you have finished colleting investments you will close the syndicate and invest in the company specified.',
    image: <FcBriefcase size={100} />,
  },
];

const SyndicateFormIntro = ({ onNext }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box marginTop={2} textAlign="center">
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          justifyContent="center"
        >
          {syndicationSteps.map((step) => (
            <>
              <Grid key={step.id} md={3} sm={3}>
                <Box display="flex">
                  <Box variant="outlined" className={classes.notselected}>
                    <Box padding={1}>
                      <Box className={classes.typeGraphic}>{step.image}</Box>
                      <Box>
                        <Typography variant="h6">{step.title}</Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant="caption"
                          style={{ fontSize: '0.8rem' }}
                        >
                          {step.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {step.id !== 'close' && (
                <Grid md={1} sm={1}>
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <BsArrowRight size={50} />
                  </Box>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Box>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={() => onNext()}>
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default SyndicateFormIntro;
