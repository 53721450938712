import React, { useEffect, useState } from 'react';
import { Box, Table, TableRow, TableCell, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import makeStyles from '@mui/styles/makeStyles';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { LoadingArea } from 'components/ui/Loading';
import { useSelectedGroup } from 'hooks/useAppState';
import syndicatesClient from 'api/syndicatesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { FaEquals, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      color: 'darkgrey',
    },
  },
}));

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const getFees = (userInvestments, syndicate, unitPrice, blueSkyFees) => {
  const {
    managementFeeBase,
    managementFeePerMember,
    managementFeePercent,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeePercent,
    smartcapitalFeeOverride,
    blueSkyBaseFee,
    otherFee,
  } = syndicate || {};

  const blueSkyFee = blueSkyFees
    ? blueSkyFees
        .map((x) => parseFloat(x.estimate))
        .reduce((acc, x) => acc + x) + parseFloat(blueSkyBaseFee)
    : 0;

  const accepted = userInvestments.filter((x) => x.acceptedUnits > 0);
  const feeCount = accepted.length;
  const feeAmount = accepted.reduce(
    (acc, x) =>
      acc +
      (parseFloat(x.amountReceived) > 0
        ? parseFloat(x.amountReceived) - parseFloat(x.refundAmount)
        : x.acceptedUnits > 0
        ? x.acceptedUnits * unitPrice
        : x.requestedUnits * unitPrice),
    0,
  );

  const remaining = accepted.reduce(
    (acc, x) =>
      acc +
      (x.acceptedUnits > 0
        ? x.acceptedUnits * unitPrice
        : x.requestedUnits * unitPrice) -
      (parseFloat(x.amountReceived) - parseFloat(x.refundAmount)),
    0,
  );

  const smartcapitalFee = smartcapitalFeeOverride
    ? parseFloat(smartcapitalFeeOverride)
    : parseFloat(smartcapitalFeeBase) +
      feeCount * parseFloat(smartcapitalFeePerMember) +
      feeAmount * (parseFloat(smartcapitalFeePercent) / 100);
  const syndicateFee =
    parseFloat(managementFeeBase || 0) +
    feeCount * parseFloat(managementFeePerMember || 0) +
    feeAmount * (parseFloat(managementFeePercent || 0) / 100) +
    parseFloat(otherFee || 0);

  const totalFee = syndicateFee || 0;
  const totalInvestment = feeAmount > 0 ? feeAmount - totalFee : null;

  return {
    feeAmount,
    feeCount,
    smartcapitalFee,
    syndicateFee,
    totalInvestment,
    totalFee,
    blueSkyEstimate: blueSkyFee,
    remaining,
  };
};

export const SyndicateFeeTable = ({
  investment,
  syndicate,
  userInvestments,
}) => {
  const classes = useStyles();
  const { groupName } = useSelectedGroup((state) => ({
    groupName: state.name,
  }));
  const [groupDetails, showGroupDetails] = useState(false);

  const {
    managementFeeBase,
    managementFeePerMember,
    managementFeePercent,
    otherFee,
    feeDate,
    investmentDate,
    investmentAmount,
    remainingAmount,
  } = syndicate || {};

  let { finalClosing } = syndicate || {};
  const { unitPrice, dateClosed } = investment;
  if (!dateClosed) {
    finalClosing = null;
  }
  if (!syndicate || !investment) {
    return (
      <Box minWidth="20rem" minHeight="10rem" position="relative">
        <CenteredContentBox>
          <LoadingArea open />
        </CenteredContentBox>
      </Box>
    );
  }

  const { feeAmount, feeCount, syndicateFee, totalInvestment } = getFees(
    userInvestments,
    syndicate,
    unitPrice,
  );
  const investmentTotal =
    finalClosing && investmentAmount > 0 ? investmentAmount : totalInvestment;
  return (
    <Table size="small">
      <TableRow>
        <TableCell style={{ paddingLeft: '0.5rem' }}>
          {finalClosing ? 'Collected' : 'Accepted'} Amount
        </TableCell>
        <TableCell align={finalClosing ? 'inherit' : 'right'}>
          <Box color="#007D05" fontWeight="bold">
            {feeAmount ? formatter.format(feeAmount) : '-'}
          </Box>
        </TableCell>
        {finalClosing && (
          <TableCell align="right" title="Final Closing Date">
            {moment(finalClosing).format('MM/DD/YYYY')}
          </TableCell>
        )}
      </TableRow>
      <TableRow style={{ cursor: 'pointer' }} className={classes.root}>
        <TableCell style={{ paddingLeft: '0' }}>
          <Box>
            <Box
              color="darkgray"
              marginTop={1}
              onClick={() => showGroupDetails((x) => !x)}
            >
              {syndicateFee >= 0 ? (
                <>
                  {groupDetails ? (
                    <ArrowDropDownIcon
                      style={{ verticalAlign: 'middle', paddingLeft: 0 }}
                    />
                  ) : (
                    <ArrowRightIcon style={{ verticalAlign: 'middle' }} />
                  )}
                  &nbsp;
                </>
              ) : (
                <Box paddingLeft={1} component="span">
                  &nbsp;
                </Box>
              )}
              {groupName} Fees
            </Box>
          </Box>
        </TableCell>
        <TableCell align={finalClosing ? 'inherit' : 'right'}>
          {syndicateFee >= 0 ? formatter.format(syndicateFee) : 'Custom'}
        </TableCell>
        {finalClosing && (
          <TableCell title={feeDate ? 'Fee Paid on Date' : ''} align="right">
            {feeDate ? moment(feeDate).format('MM/DD/YYYY') : ''}
          </TableCell>
        )}
      </TableRow>
      {syndicateFee >= 0 && groupDetails && (
        <>
          <TableRow className={classes.root}>
            <TableCell style={{ paddingLeft: '1.7rem' }}>Base Fee</TableCell>
            <TableCell align={finalClosing ? 'inherit' : 'right'}>
              {managementFeeBase ? formatter.format(managementFeeBase) : '-'}
            </TableCell>
            {finalClosing && <TableCell />}
          </TableRow>
          <TableRow className={classes.root}>
            <TableCell style={{ paddingLeft: '1.7rem' }}>
              Per Investor Fee
            </TableCell>
            <TableCell align={finalClosing ? 'inherit' : 'right'}>
              {managementFeePerMember
                ? formatter.format(managementFeePerMember * feeCount)
                : '-'}
            </TableCell>
            {finalClosing && <TableCell />}
          </TableRow>
          <TableRow className={classes.root}>
            <TableCell style={{ paddingLeft: '1.7rem' }}>% Raise Fee</TableCell>
            <TableCell align={finalClosing ? 'inherit' : 'right'}>
              {managementFeePercent > 0
                ? formatter.format((managementFeePercent * feeAmount) / 100)
                : '-'}
            </TableCell>
            {finalClosing && <TableCell />}
          </TableRow>
          {otherFee > 0 && (
            <TableRow className={classes.root}>
              <TableCell style={{ paddingLeft: '1.7rem' }}>
                Other Fees
              </TableCell>
              <TableCell align={finalClosing ? 'inherit' : 'right'}>
                {formatter.format(otherFee)}
              </TableCell>
              {finalClosing && <TableCell />}
            </TableRow>
          )}
        </>
      )}
      {finalClosing && (
        <TableRow className={classes.root}>
          <TableCell style={{ paddingLeft: '1.7rem' }}>
            <Box>
              <Box color="darkgray" marginTop={1}>
                Cash Remaining
              </Box>
            </Box>
          </TableCell>
          <TableCell align={finalClosing ? 'inherit' : 'right'}>
            {formatter.format(remainingAmount)}
          </TableCell>
          {finalClosing && <TableCell />}
        </TableRow>
      )}
      <TableRow>
        <TableCell
          style={{ paddingLeft: '0.5rem', borderTop: '2px solid black' }}
        >
          {finalClosing ? 'Total' : 'Estimated'}{' '}
          {finalClosing ? 'Invested' : 'Investment'}
        </TableCell>
        <TableCell
          align={finalClosing ? 'inherit' : 'right'}
          style={{ borderTop: '2px solid black' }}
        >
          <Box color="#1546A6" fontWeight="bold">
            {investmentTotal ? formatter.format(investmentTotal) : '-'}
          </Box>
        </TableCell>
        {finalClosing && (
          <TableCell
            align="right"
            style={{ borderTop: '2px solid black' }}
            title="Investment Date"
          >
            <Box>
              {moment(investmentDate || dateClosed).format('MM/DD/YYYY')}
            </Box>
          </TableCell>
        )}
      </TableRow>
    </Table>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const SmartCapitalFeeTable = ({ syndicate, investment }) => {
  const [blueSkyFees, setBlueSkyFees] = useState();
  const [userInvestments, setUserInvestments] = useState([]);
  const [showSCFees, setShowSCFees] = useState(true);
  const [showBlueSkyFees, setShowBlueSkyFees] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.investments(syndicate?.id);
        setUserInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicate?.id) {
      fetchData(syndicate?.id);
    }
  }, [syndicate]);
  const {
    id,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeeOverride,
    smartcapitalFeePercent,
    blueSkyBaseFee,
    blueSky = [],
  } = syndicate || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.blueSkyFees({ id });
        // save affirm
        setBlueSkyFees(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id && blueSky && blueSky.length > 0) {
      fetchData();
    }
  }, [id, blueSky]);
  if (
    !syndicate ||
    (!blueSkyFees && blueSky?.length > 0) ||
    (!userInvestments && userInvestments?.length > 0)
  ) {
    return (
      <Box minWidth="20rem" minHeight="10rem" position="relative">
        <CenteredContentBox>
          <LoadingArea open />
        </CenteredContentBox>
      </Box>
    );
  }

  const { unitPrice, dateClosed } = investment;

  const { feeCount, smartcapitalFee, blueSkyEstimate, feeAmount } = getFees(
    userInvestments,
    syndicate,
    unitPrice,
    blueSkyFees,
  );

  const totalFee = smartcapitalFee + blueSkyEstimate;
  const totalPercent = feeAmount * (parseFloat(smartcapitalFeePercent) / 100);
  return (
    <Box>
      <Box fontSize={16}>
        <Box
          color="darkgray"
          marginTop={1}
          onClick={() => setShowSCFees((x) => !x)}
        >
          <Grid container justifyContent="space-between">
            <Grid>
              {showSCFees ? (
                <ArrowDropDownIcon
                  style={{ verticalAlign: 'middle', paddingLeft: 0 }}
                />
              ) : (
                <ArrowRightIcon style={{ verticalAlign: 'middle' }} />
              )}
              &nbsp;SmartCapital Fees
            </Grid>
            <Grid>
              {smartcapitalFee ? formatter.format(smartcapitalFee) : '-'}
            </Grid>
          </Grid>
        </Box>
        {showSCFees && (
          <Box paddingLeft={3.5}>
            {smartcapitalFeeOverride ? (
              <>
                <Box color="darkgray" marginTop={1}>
                  <Grid container justifyContent="space-between">
                    <Grid>Custom Fee</Grid>
                    <Grid>{formatter.format(smartcapitalFeeOverride)}</Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <Box color="darkgray" marginTop={1}>
                  <Grid container justifyContent="space-between">
                    <Grid>Base Fee</Grid>
                    <Grid>
                      {smartcapitalFeeBase
                        ? formatter.format(smartcapitalFeeBase)
                        : '-'}
                    </Grid>
                  </Grid>
                </Box>
                <Box color="darkgray" marginTop={1}>
                  <Grid container justifyContent="space-between">
                    <Grid>Per Investor Fee</Grid>
                    <Grid>
                      <Box alignItems="center" display="flex">
                        {smartcapitalFeePerMember ? (
                          <>
                            <Box mx={0.5}>
                              {formatter.format(smartcapitalFeePerMember)} per
                              investor
                            </Box>
                            <FaTimes />
                            <Box mx={0.5}>
                              {feeCount} investor{feeCount > 1 && 's'}
                            </Box>
                            <FaEquals />
                            <Box marginLeft={0.5}>
                              {formatter.format(
                                smartcapitalFeePerMember * feeCount,
                              )}
                            </Box>
                          </>
                        ) : (
                          '-'
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box color="darkgray" marginTop={1}>
                  <Grid container justifyContent="space-between">
                    <Grid>Percent Of Raise Fee</Grid>
                    <Grid>
                      <Box alignItems="center" display="flex">
                        <>
                          <Box mx={0.5}>
                            {formatter.format(feeAmount)} total investment
                          </Box>
                          <FaTimes />
                          <Box mx={0.5}>{smartcapitalFeePercent}%</Box>
                          <FaEquals />
                          <Box marginLeft={0.5}>
                            {formatter.format(totalPercent)}
                          </Box>
                        </>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        )}
        <Box
          color="darkgray"
          marginTop={1}
          onClick={() => setShowBlueSkyFees((x) => !x)}
        >
          <Grid container justifyContent="space-between">
            <Grid>
              {showBlueSkyFees ? (
                <ArrowDropDownIcon
                  style={{ verticalAlign: 'middle', paddingLeft: 0 }}
                />
              ) : (
                <ArrowRightIcon style={{ verticalAlign: 'middle' }} />
              )}
              &nbsp;Blue Sky Fees
            </Grid>
            <Grid>{formatter.format(blueSkyEstimate || 0)}</Grid>
          </Grid>
        </Box>
        {showBlueSkyFees && (
          <Box paddingLeft={3.5}>
            <Box color="darkgray" marginTop={1}>
              <Grid container justifyContent="space-between">
                <Grid>Base Fee</Grid>
                <Grid>{formatter.format(blueSkyBaseFee || 0)}</Grid>
              </Grid>
            </Box>
            {blueSkyFees?.map((stateFee) => {
              const { id, estimate, state } = stateFee;
              return (
                <Box key={id} color="darkgray" marginTop={1}>
                  <Grid container justifyContent="space-between">
                    <Grid>{state}</Grid>
                    <Grid>{formatter.format(estimate)}</Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box marginTop={1} fontSize={16}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>{dateClosed ? 'Total' : 'Estimated'} Fees</Grid>
          <Grid>
            <Box
              fontWeight="bold"
              fontSize={18}
              color={theme.palette.money.main}
            >
              {totalFee ? formatter.format(totalFee) : '-'}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
