import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import makeStyles from '@mui/styles/makeStyles';

import defaultPress from 'images/default-press.svg';
import DialogWithTitle from 'components/ui/DialogWithTitle';

import EditArticle from 'views/EditOpportunity/components/EditArticle';

const OpportunitySectionPress = ({ opportunity }) => {
  const { articles = [] } = opportunity || {};

  if (!articles || articles.length === 0) {
    return null;
  }

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Press
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3} alignItems="stretch">
          {articles.map((article) => (
            <Grid key={article.id} xs={12} sm={12} md={6}>
              <ArticleView data={article} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  image: {
    textAlign: 'center',
    margin: 'auto',
    height: '5rem',
    '& img': {
      borderRadius: '5px',
      height: '5rem',
    },
  },
}));

export const ArticleView = ({ data, canEdit = false, onChange = () => {} }) => {
  const classes = useStyles();
  const { photo, name, document, link, description } = data;

  const [editModal, showEdit] = useState(false);
  return (
    <Box
      padding={2}
      border="1px solid lightgray"
      height="100%"
      bgcolor="white"
      position="relative"
    >
      {editModal ? (
        <DialogWithTitle
          open
          onClose={() => showEdit(false)}
          fullWidth
          title="Edit Article"
        >
          <EditArticle
            onChange={onChange}
            onClose={() => showEdit(false)}
            {...{ data }}
          />
        </DialogWithTitle>
      ) : null}
      <Grid container spacing={1}>
        <Grid>
          <Box>
            <div className={classes.image}>
              {photo ? (
                <img
                  alt=""
                  src={photo}
                  style={{ objectFit: 'cover', width: '5rem', height: '5rem' }}
                ></img>
              ) : (
                <img alt="" src={defaultPress} style={{ paddingTop: '1rem' }} />
              )}
            </div>
          </Box>
        </Grid>
        <Grid xs>
          <Box padding={2}>
            <a
              href={document || link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2">{name}</Typography>
            </a>
            <Typography variant="caption">{description}</Typography>
          </Box>
        </Grid>
      </Grid>
      {canEdit && (
        <Box marginTop={4}>
          &nbsp;
          <Box
            marginTop={2}
            paddingBottom={3}
            textAlign="center"
            bottom="0"
            left="0"
            width="100%"
            position="absolute"
          >
            <Button
              color="primary"
              variant="outlined"
              style={{ cursor: 'pointer' }}
              onClick={() => showEdit((x) => !x)}
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OpportunitySectionPress;
