import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Box, Tabs } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import TabPanel from 'components/ui/TabPanel/TabPanel';

import { LoadingArea, LoadingSpinner } from 'components/ui/Loading';
import getServerResponseErrors from 'api/getServerResponseErrors';

import GroupInvestmentDocuments from 'views/GroupInvestment/GroupInvestmentDocuments';

import UserInvestments from 'views/UserInvestment/UserInvestments';
import useStateNotification from 'hooks/useStateNotification';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import SyndicateAgreements from 'views/Syndicate/SyndicateAgreements';
import SyndicateFiling from 'views/Syndicate/SyndicateFiling';
import SyndicateOverview from 'views/Syndicate/SyndicateOverview';
import SyndicateBanking from 'views/Syndicate/SyndicateBanking';
import useSyndicate from 'hooks/useSyndicate';
import SyndicateTaxes from 'views/GroupInvestment/SyndicateTaxes/SyndicateTaxes';
import SyndicateSmartCapitalAgreements from 'views/Syndicate/SyndicateSmartCapitalAgreements';
import { CustomTab } from './EditOpportunityAbout';
import { NewFeatureFlags } from 'utils/consts';
import moment from 'moment';
import SyndicateSettings from 'views/Syndicate/SyndicateSettings';

const StyledTab = withStyles({
  wrapper: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
})((props) => <CustomTab {...props} />);

const InvestmentTab = ({ investment, setInvestment, tab, subtab, url }) => {
  const history = useHistory();
  const { id, roundName, syndicateId, syndicateName, syndicateData } =
    investment;

  const name = syndicateName || syndicateData?.syndicateName;
  return (
    <Box>
      <Tabs
        orientation="vertical"
        onChange={() => {
          history.push(`${url}`);
        }}
        TabIndicatorProps={{ style: { display: 'none' } }}
        value={`${tab}`}
      >
        <StyledTab
          value={`${id}`}
          textColor="primary"
          label={
            <Box color="primary">{name ? name : <>{roundName} Interest</>}</Box>
          }
        />
      </Tabs>
      {tab == id && (
        <InvestmentDetailTabs
          {...{ investment, setInvestment, tab, subtab, url, syndicateId }}
        />
      )}
    </Box>
  );
};

const InvestmentDetailTabs = ({
  investment,
  setInvestment,
  subtab,
  url,
  syndicateId,
}) => {
  const history = useHistory();
  const { isAdmin } = investment;
  const { syndicate } = useSyndicate({
    investment,
    setInvestment,
  });

  const { useSmartCapitalAccount } = syndicate || {};
  return (
    <Box marginLeft={1}>
      {!syndicate && syndicateId ? (
        <LoadingSpinner />
      ) : (
        <Tabs
          value={subtab ? `${subtab}` : ``}
          orientation="vertical"
          onChange={(_e, newValue) => {
            history.push(`${url}${url.endsWith('/') ? '' : '/'}${newValue}`);
          }}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {syndicateId && (
            <StyledTab
              label="Overview"
              value="overview"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {syndicateId && (
            <StyledTab
              label="Investment"
              value=""
              style={{ marginLeft: '1rem' }}
            />
          )}
          {isAdmin && syndicateId && (
            <StyledTab
              label="Filings"
              value="filings"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {isAdmin && syndicateId && (
            <StyledTab
              label="Agreements"
              value="agreements"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {isAdmin && syndicateId && !useSmartCapitalAccount && (
            <StyledTab
              label="Banking"
              value="banking"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {syndicateId && (
            <StyledTab
              label="Documents"
              value="documents"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {isAdmin && syndicateId && (
            <StyledTab
              label="Taxes"
              value="taxes"
              style={{ marginLeft: '1rem' }}
            />
          )}
          {isAdmin && syndicateId && (
            <StyledTab
              label="SmartCapital"
              value="smartcapital"
              style={{ marginLeft: '1rem' }}
            />
          )}

          {isAdmin && (
            <StyledTab
              label="Settings"
              value="settings"
              style={{ marginLeft: '1rem' }}
              isNew={
                NewFeatureFlags.syndicateSettings.enabled &&
                moment(NewFeatureFlags.syndicateSettings.expiration) > moment()
              }
            />
          )}
        </Tabs>
      )}
    </Box>
  );
};

export const InvestmentRouter = ({ id, tab, path }) => {
  const [investment, setInvestment] = useStateNotification('investments');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.get({ id });
        setInvestment(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, setInvestment]);
  if (!investment) {
    return (
      <Box position="relative" height="100%">
        <LoadingArea open />
      </Box>
    );
  }
  const { id: investmentId } = investment;

  return (
    <TabPanel key={id} value={tab} index={`${id}`}>
      <Box padding={3} border="1px solid lightgray" bgcolor="white">
        <Switch>
          <Route path={`${path}/documents`}>
            <GroupInvestmentDocuments
              {...{
                investment,
                setInvestment,
                canEdit: true,
              }}
            />
          </Route>
          <Route path={`${path}/agreements`}>
            <SyndicateAgreements
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/filings`}>
            <SyndicateFiling
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/banking`}>
            <SyndicateBanking
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/taxes`}>
            <SyndicateTaxes
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/smartcapital`}>
            <SyndicateSmartCapitalAgreements
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/overview`}>
            <SyndicateOverview
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route path={`${path}/settings`}>
            <SyndicateSettings
              {...{
                investment,
                setInvestment,
              }}
            />
          </Route>
          <Route>
            <UserInvestments
              {...{
                investmentId,
              }}
            />
          </Route>
        </Switch>
      </Box>
    </TabPanel>
  );
};

export default InvestmentTab;
