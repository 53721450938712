import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { useArrayUpdate } from 'hooks/useListUpdate';
import { useEditGroupLocation } from 'hooks/forms/useFormGroupLocation';
import groupLocationsClient from 'api/groupLocationsClient';
import DisplayAddress from 'components/ui/DisplayAddress';

const BusinessAddressList = ({ groupId }) => {
  const [list, setList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupLocationsClient.index({ groupId });
        setList(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const { onListUpdate } = useArrayUpdate(setList);

  const { modal, showModal } = useEditGroupLocation({
    groupId,
    onChange: onListUpdate,
  });

  return (
    <Box position="relative">
      <Box>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography variant="h5">Business Address</Typography>
          </Grid>
          <Grid>
            <Button onClick={() => showModal({})}>Add Address</Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} minHeight="2rem">
        {modal}
        {list && list.length > 0 ? (
          <Box>
            {list.map((item) => (
              <Box key={item.id} marginBottom={2}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Box>
                      <Typography>{item.name}</Typography>
                    </Box>
                    <Box>
                      <DisplayAddress address={item.location} />
                    </Box>
                  </Grid>
                  <Grid>
                    <Button color="primary" onClick={() => showModal(item)}>
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : (
          <Box textAlign="center">
            No syndicate managers have been added yet
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BusinessAddressList;
