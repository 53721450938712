import React, { useState } from 'react';
import * as yup from 'yup';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import getServerResponseErrors from 'api/getServerResponseErrors';
import Form from 'components/Form/Form';

import userInvestmentsClient from 'api/userInvestmentsClient';

const useStyles = makeStyles((theme) => ({
  unitChoice: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
  },
}));

const InterestWizard = ({
  investment,
  photo,
  userInvestment,
  onChange,
  isMaybe,
  onClose,
}) => {
  const { hasRequestedLead, interestAmount, isUndecided } =
    userInvestment || {};
  const [isLead, setLead] = useState(hasRequestedLead);
  const classes = useStyles();

  const { id } = userInvestment || {};
  const { id: investmentId, status } = investment;
  const canInvest = status == 'Accepting Investments';

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { interestAmount },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const upsert = id
        ? userInvestmentsClient.update
        : userInvestmentsClient.create;

      const response = await upsert({
        id,
        investmentId,
        data: {
          hasRequestedLead: isLead,
          interestAmount,
          isUndecided: isMaybe,
        },
      });
      // onChange(response.data);
      onChange(response.data);
      if (isMaybe || !canInvest) {
        onClose();
      }
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const defaultValues = {
    ...userInvestment,
    interestAmount: interestAmount > 0 ? interestAmount : 0,
  };
  return (
    <Box>
      <Box textAlign="center">
        <Box>
          {photo && <img alt="" src={photo} style={{ height: '5rem' }} />}
        </Box>
        <Box marginTop={2}>
          <Typography variant="h6" color="primary">
            {isUndecided
              ? 'How much were you thinking about investing?'
              : 'How much are you interested in investing?'}{' '}
          </Typography>
          {/* <Typography variant="caption">
            This is not a binding commitment
          </Typography> */}
        </Box>
      </Box>
      <Box marginTop={2}>
        <Form
          initialValues={defaultValues}
          enableReinitialize
          setRef={setFormRef}
        >
          <Box className={classes.unitChoice}>
            <Box maxWidth="20rem" textAlign="center" margin="auto">
              <FormikCurrencyInput
                variant="outlined"
                name="interestAmount"
                label="Interest Amount"
                validation={yup.number().min(1)}
              />
            </Box>
          </Box>

          <Box marginTop={2}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLead}
                    onChange={(e) => setLead(e.target.checked)}
                    style={{ backgroundColor: 'white' }}
                  />
                }
                label={
                  <Typography variant="body">
                    Interested in being a lead investor?
                  </Typography>
                }
              />
            </Box>
            <Box paddingLeft={4}>
              <Typography variant="caption">
                Lead investors are expected to help manage and promote this
                opportunity
              </Typography>
            </Box>
          </Box>
        </Form>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid></Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={canInvest && !isMaybe ? 'Next' : 'Submit Interest'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InterestWizard;
