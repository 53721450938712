import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';

import taxDocumentsClient from 'api/taxDocumentsClient';
import { useConfirmModal } from 'components/ui/ActionWithConfirm';
import { useDocument } from 'components/ui/DocumentPicker';
import { FormikSelect } from 'components/ui/CustomSelect';
import moment from 'moment';

const useFormTaxDocument = ({ data, finalClosingYear }) => {
  const { id, document } = data || {};
  const [formRef, setFormRef] = useState({});

  const getLink = useCallback(async () => {
    try {
      const response = await taxDocumentsClient.link({ id });
      return response.data;
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  }, [id]);
  const { currentDocument, documentPicker } = useDocument(
    document,
    'file',
    getLink,
  );

  const validate = useCallback(async () => {
    const {
      values: { form, formName, description, year },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        form,
        formName: form == 'other' ? formName : null,
        year,
        description,
        document: currentDocument ? currentDocument.data : null,
        documentFileName: currentDocument ? currentDocument.name : null,
      },
    };
  }, [currentDocument, formRef]);

  const form = useMemo(() => {
    const { id, year, form } = data || {};
    const defaultYear = moment().year() - 1;

    const years = [];
    for (let i = defaultYear; i >= finalClosingYear; i--) {
      years.push(i);
    }

    return (
      <Box>
        <Form
          key={id}
          initialValues={{
            ...data,
            year: year || defaultYear,
            form: form || 'other',
          }}
          enableReinitialize
          setRef={setFormRef}
        >
          {({ values }) => {
            return (
              <>
                <Box>
                  <Grid container spacing={3}>
                    <Grid xs={4}>
                      <FormikSelect
                        name="year"
                        variant="filled"
                        label="Tax Year"
                        required
                        defaultValue={defaultYear}
                        disabled={id || year}
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </FormikSelect>
                    </Grid>
                    {values.form !== 'other' && (
                      <Grid xs={4}>
                        <FormikSelect
                          name="form"
                          variant="filled"
                          label="Form Type"
                          required
                          disabled
                        >
                          <MenuItem value="form_1065">
                            Federal - Form 1065
                          </MenuItem>
                          <MenuItem value="federal_k1">
                            Schedule K-1 - Federal
                          </MenuItem>
                          <MenuItem value="state_k1">
                            Schedule K-1 - Colorado
                          </MenuItem>
                          <MenuItem value="colorado_form_106">
                            Colorado - DR 0106
                          </MenuItem>

                          <MenuItem value="other">Other</MenuItem>
                        </FormikSelect>
                      </Grid>
                    )}
                    {values.form === 'other' && (
                      <Grid xs={4}>
                        <FormikTextInput
                          name="formName"
                          label="Form Name"
                          required
                        />
                      </Grid>
                    )}
                    <Grid xs={12}>
                      <FormikTextInput name="description" label="Notes" />
                    </Grid>
                  </Grid>
                </Box>

                <Box>{documentPicker}</Box>
              </>
            );
          }}
        </Form>
      </Box>
    );
  }, [data, documentPicker, finalClosingYear]);
  return { form, validate };
};

const useEditTaxDocument = ({
  syndicateId,
  userInvestmentId,
  onChange,
  finalClosingYear,
}) => {
  const [modal, showModal] = useState();
  const { form, validate } = useFormTaxDocument({
    userInvestmentId,
    data: modal,
    finalClosingYear,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = modal?.id
          ? taxDocumentsClient.update
          : taxDocumentsClient.create;

        const response = await upsert({
          syndicateId,
          id: modal?.id,
          data: {
            userInvestmentId,
            ...values,
          },
        });
        const data = response.data;
        onChange(data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);

        return false;
      }
      return true;
    },
    [modal?.id, onChange, syndicateId, userInvestmentId, validate],
  );

  const onDelete = useCallback(
    async (onError) => {
      try {
        await taxDocumentsClient.destroy({ id: modal?.id });
        onChange({ id: modal?.id, isDeleted: true }, 'Delete Successful');
        setTimeout(() => {
          // showConfirm(false);
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    },
    [modal?.id, onChange],
  );

  const { confirmModal, actionButton, showConfirm } = useConfirmModal({
    onAction: onDelete,
  });
  useEffect(() => {
    showConfirm(false);
  }, [modal, showConfirm]);

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid>
            <Button variant="contained" onClick={() => showModal()}>
              Cancel
            </Button>
          </Grid>
          {modal?.id &&
            modal.form != 'schedule_k1' &&
            modal.form != 'form_1065' && <Grid>{actionButton}</Grid>}
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={modal?.id ? 'Save' : 'Add Document'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${modal.id ? 'Edit' : 'Add'} Document`,
          // header,
          footer,
          onClose: () => showModal(),
          fullWidth: true,
        }}
      >
        {confirmModal}
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [actionButton, confirmModal, form, modal, onSave]);

  return { modal: modalElement, showModal, form, onSave, onDelete };
};

// eslint-disable-next-line import/no-unused-modules
export default useEditTaxDocument;
