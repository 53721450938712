import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as yup from 'yup';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';

import { validateForm } from 'components/Form/SaveFormButton';
import FormikNameEmailList from 'components/ui/FormikNameEmailList';
import useFormWireInstructions from 'hooks/forms/useFormWireInstructions';
import useFormCheckInstructions from './useFormCheckInstructions';
import useFormAddress from './useFormAddress';
import { LoadingArea } from 'components/ui/Loading';
import managementStructuresClient from 'api/managementStructuresClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useSelectedGroup } from 'hooks/useAppState';

const useFormManagementStructure = ({ data, isEdit = false }) => {
  const { id, structureType } = data || {};
  const groupId = useSelectedGroup((state) => state.id);

  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: {
        carryAmount,
        unitPrice,
        minimumUnits,
        maximumUnits,
        managers,
        managementFeeBase,
        managementFeePerMember,
      },
      errors,
    } = await validateForm(formRef);

    const {
      values: { id: businessId, ...business },
      errors: businessErrors,
    } = await businessValidate();

    const {
      values: {
        bank_attributes: { id: bankId, ...bank },
        recipient_attributes: { id: recipientId, ...recipient },
        ...wireValues
      },
      errors: wireErrors,
    } = await wireValidate();
    const {
      values: {
        mailing_attributes: { id: mailingId, ...mailing },
        ...checkValues
      },
      errors: checkErrors,
    } = await checkValidate();

    return {
      errors: [...errors, ...wireErrors, ...checkErrors, ...businessErrors],
      values: {
        structureType,
        carryAmount,
        unitPrice,
        minimumUnits,
        maximumUnits,
        managers,
        managementFeeBase,
        managementFeePerMember,
        ...wireValues,
        ...checkValues,
        bank_attributes: {
          id: id ? bankId : null,
          ...bank,
        },
        business_attributes: {
          id: id ? businessId : null,
          ...business,
        },
        recipient_attributes: {
          id: id ? recipientId : null,
          ...(recipient || business),
        },
        mailing_attributes: {
          id: id ? mailingId : null,
          ...(mailing || business),
        },
      },
    };
  };

  const [structures, setStructures] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await managementStructuresClient.index({
          groupId,
        });
        setStructures(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (!id && groupId) {
      fetchData();
    }
  }, [id, groupId]);

  const [structure, setStructure] = useState();

  const formData = (id ? data : structure) || {};

  const { business: businessAddress } = formData;

  const { form: businessForm, validate: businessValidate } = useFormAddress({
    data: businessAddress,
    prefix: '',
  });

  const { form: wireForm, validate: wireValidate } = useFormWireInstructions({
    data: formData,
  });
  const { form: checkForm, validate: checkValidate } = useFormCheckInstructions(
    { data: formData },
  );

  const form =
    !id && !structures ? (
      <Box>
        <LoadingArea open />
      </Box>
    ) : (
      <>
        <Box>
          <Typography variant="h6">Management Structure</Typography>
        </Box>
        {!id && structures && structures.length > 0 && !isEdit && (
          <Box>
            <Box>
              <Typography variant="caption">
                Choose from an existing managment structures or create a new
                management structure
              </Typography>
            </Box>
            <Box marginTop={2}>
              {!structures && <CircularProgress></CircularProgress>}
              {structures && (
                <>
                  <InputLabel id="select-structure">
                    Select Management Structure
                  </InputLabel>
                  <Select
                    labelId="select-structure"
                    fullWidth
                    onChange={(e) =>
                      setStructure(
                        structures.find((x) => x.id == e.target.value) || {
                          id: -1,
                        },
                      )
                    }
                    value={structure}
                  >
                    {structures.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                    <MenuItem value={-1}>Create New</MenuItem>
                  </Select>
                </>
              )}
            </Box>
          </Box>
        )}
        {(id || structures.length == 0 || structure || isEdit) && (
          <>
            {!id && structure?.id > 0 && (
              <Box marginTop={2}>
                <Typography variant="caption">
                  The below fields were copied from the management structure you
                  selected. You should review and make any changes necessary for
                  this specific syndicate.
                </Typography>
              </Box>
            )}
            <Box marginTop={4}>
              <Form
                key={id || structure?.id}
                setRef={setFormRef}
                initialValues={formData}
                enableReinitialize
              >
                {structureType === 'series' && (
                  <Box marginBottom={4}>
                    <Box marginBottom={2}>
                      <Typography>Master Entity</Typography>
                    </Box>
                    <Box marginBottom={3}>
                      <Grid container spacing={3}>
                        <Grid md={8}>
                          <FormikTextInput
                            name="masterEntity"
                            label="Master Entity Name"
                            variant="filled"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                <Box marginBottom={4}>
                  <Box marginBottom={2}>
                    <Typography>Managers</Typography>
                    <Typography variant="caption">
                      A Manager can be an individual or a management entity such
                      as a fund, corporation, or trust
                    </Typography>
                  </Box>
                  <Box>
                    <FormikNameEmailList
                      name="managers"
                      label="Manager"
                      addLabel="Manager"
                      required
                    ></FormikNameEmailList>
                  </Box>
                </Box>
                <Box marginBottom={4}>
                  <Box marginBottom={2}>
                    <Typography>Unit Information</Typography>
                    <Typography variant="caption">
                      This section controls the the default price per unit for
                      investors to invest in syndicates, as well as default the
                      minimum and maximum amount of units the investor can
                      purchase. On each syndicate you can override these.
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid md={3}>
                        <FormikCurrencyInput
                          name="unitPrice"
                          label="Price Per Unit"
                          variant="filled"
                          required
                          validation={yup.number().moreThan(0)}
                        />
                      </Grid>
                      <Grid md={3}>
                        <FormikTextInput
                          name="minimumUnits"
                          label="Minimum Units"
                          variant="filled"
                          type="number"
                          defaultValue={1}
                          required
                          validation={yup.number().integer().moreThan(0)}
                        />
                      </Grid>
                      <Grid md={3}>
                        <FormikTextInput
                          name="maximumUnits"
                          label="Maximum Units"
                          variant="filled"
                          type="number"
                          defaultValue={0}
                          required
                          validation={yup.number().integer().min(0)}
                          helperText="0 for unlimited units"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box marginBottom={4}>
                  <Box marginBottom={2}>
                    <Typography>Management Carried Interest</Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid md={2}>
                        <FormikTextInput
                          name="carryAmount"
                          label="Interest %"
                          variant="filled"
                          type="number"
                          defaultValue="20"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box marginBottom={4}>
                  <Box marginBottom={2}>
                    <Typography variant="h6">Management Fees</Typography>
                    <Typography variant="caption">
                      These are the fees that you will charge per syndication
                      that will be paid to your management structure. For each
                      syndicate you can charge a base fee of some amount, and a
                      fee per investor that invests. These are default values
                      for your management structure and can be changed on a per
                      syndicate basis. These will also be in addition to any fee
                      that SmartCapital charges.
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid md={4}>
                        <FormikCurrencyInput
                          name="managementFeeBase"
                          label="Syndicate Base Fee"
                          variant="filled"
                          defaultValue={0}
                          validation={yup.number().min(0)}
                        />
                      </Grid>
                      <Grid md={4}>
                        <FormikCurrencyInput
                          name="managementFeePerMember"
                          label="Syndicate Per Investor Fee"
                          variant="filled"
                          defaultValue={0}
                          validation={yup.number().min(0)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Form>
            </Box>
            <Box marginTop={4}>
              <Box>
                <Typography>Management Address</Typography>
              </Box>
              <Box marginTop={2}>{businessForm}</Box>
            </Box>
            <Box marginTop={4}>
              <Box>
                <Typography>Check Instructions</Typography>
              </Box>
              <Box marginTop={2}>
                <Box>{checkForm}</Box>
              </Box>
            </Box>
            <Box marginTop={4}>
              <Box>
                <Typography gutterBottom variant="h6">
                  Wire Instructions
                </Typography>
              </Box>
              <Box>{wireForm}</Box>
            </Box>
          </>
        )}
      </>
    );

  return { form, validate };
};

export default useFormManagementStructure;
