import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingArea, LoadingSpinner } from 'components/ui/Loading';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useSelectedGroup } from 'hooks/useAppState';
import { useListNotification } from 'hooks/useStateNotification';
import AddIcon from '@mui/icons-material/Add';
import { currencyFormatter } from 'views/SyndicatesIndex/SyndicatesIndex';

import useDataUserInvestments from '../UserInvestment/useDataUserInvestments';
import { Masonry } from '@mui/lab';

// https://kovart.github.io/dashed-border-generator/
const dashedline =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgray' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";

const SyndicateInfoDisplay = ({ name, value }) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box fontWeight="bold">{name}:</Box>
      <Box fontWeight="bold" color={theme.palette.money.main}>
        {currencyFormatter.format(value || 0)}
      </Box>
    </Box>
  );
};
const SyndicateInvestments = ({ investment, syndicate }) => {
  const { userInvestments, isLoading } = useDataUserInvestments(
    investment,
    () => {},
    syndicate?.dateFiledOfferingAuthorization,
  );
  const { unitPrice } = investment;
  if (isLoading)
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  const interested = userInvestments.filter((x) => x.interestAmount > 0);
  const totalInterest = interested.reduce(
    (acc, x) => acc + parseFloat(x.interestAmount),
    0,
  );

  const proposed = userInvestments.filter((x) => x.requestedUnits > 0);
  const totalProposed = proposed.reduce(
    (acc, x) => acc + x.requestedUnits * unitPrice,
    0,
  );

  const invested = userInvestments.filter((x) => x.acceptedUnits > 0);
  const totalInvested = invested.reduce(
    (acc, x) =>
      acc +
      (parseFloat(x.amountReceived) > 0
        ? parseFloat(x.amountReceived) - parseFloat(x.refundAmount)
        : x.acceptedUnits * unitPrice),
    0,
  );
  return (
    <Grid container direction="column">
      <Grid xs>
        <SyndicateInfoDisplay name="Interested" value={totalInterest} />
      </Grid>
      <Grid xs>
        <SyndicateInfoDisplay name="Proposed" value={totalProposed} />
      </Grid>
      <Grid xs>
        <SyndicateInfoDisplay name="Amount Invested" value={totalInvested} />
      </Grid>
    </Grid>
  );
};
const EditOpportunitySyndicates = ({ opportunity }) => {
  const theme = useTheme();
  const { groupId, slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const { id } = opportunity;
  const history = useHistory();
  const [investments, setInvestments] = useListNotification(
    'investments',
    (x) => x.groupId == groupId && x.opportunityId == id,
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await opportunitiesClient.investments({ id, groupId });
        setInvestments(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id && groupId) {
      fetchData();
    }
  }, [id, groupId, setInvestments]);

  return !investments ? (
    <Box position="relative" height="100%">
      <LoadingArea open />
    </Box>
  ) : (
    <Box>
      <Masonry columns={3} spacing={3}>
        {(investments.length === 0 ||
          !investments.find((x) => !x.dateClosed)) && (
          <Grid xs={4}>
            <Box
              height="8rem"
              bgcolor="white"
              padding={3}
              borderRadius={1}
              style={{
                cursor: 'pointer',
                backgroundImage: `url("${dashedline}")`,
              }}
              onClick={() => {
                window.dispatchEvent(new CustomEvent('SC_CreateSyndicate'));
              }}
            >
              <Box fontSize={65} lineHeight={0} textAlign="center">
                <AddIcon color="secondary" fontSize="inherit" />
              </Box>
              <Box textAlign="center">Start New Syndicate</Box>
            </Box>
          </Grid>
        )}
        {investments.map((investment) => {
          const {
            id,
            syndicateName,
            roundName,
            dateClosed,
            status,
            syndicateData,
          } = investment;
          const name = syndicateName || syndicateData?.syndicateName;
          return (
            <Grid key={id} xs={4}>
              <Grid
                bgcolor="white"
                padding={2}
                border="1px solid black"
                borderRadius={1}
                flexDirection="column"
                justifyContent="space-between"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/${slug}/syndicates/${id}`)}
              >
                <Grid container spacing={2}>
                  <Grid xs>
                    <Box fontWeight="bold">
                      {name ? name : 'Collecting Interest'}
                    </Box>
                    <Box>{roundName}</Box>
                  </Grid>
                  <Grid>
                    {dateClosed ? (
                      <Box color="gray">{status}</Box>
                    ) : (
                      <Box color={theme.palette.primary.main}>{status}</Box>
                    )}
                  </Grid>
                </Grid>
                <SyndicateInvestments
                  investment={investment}
                  syndicate={syndicateData}
                />
              </Grid>
            </Grid>
          );
        })}
      </Masonry>
    </Box>
  );
};

export default EditOpportunitySyndicates;
