import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';

import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import useTableSort from 'hooks/useTableSort';
import UserInvestmentRow from './UserInvestmentRow';
import { TablePageActions } from 'views/InvestorsIndex/InvestorTable';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const UserInvestmentTable = ({
  filteredData,
  investment,
  onListUpdate,
  syndicate,
  filterTerms,
  groupId,
  updateNotes,
}) => {
  const {
    id: groupInvestmentId,
    unitPrice,
    isAdmin,
    earlyPayment,
  } = investment;
  const { dateFiledOfferingAuthorization } = syndicate || {};

  const columns = useMemo(() => {
    const interested = filteredData.filter((x) => x.data.interestAmount > 0);
    const interestCount = interested.length;
    const interestAmount = interested.reduce(
      (acc, x) => acc + parseFloat(x.data.interestAmount),
      0,
    );

    const proposed = filteredData.filter((x) => x.data.requestedUnits > 0);
    const proposedCount = proposed.length;
    const proposedAmount = proposed.reduce(
      (acc, x) => acc + x.data.requestedUnits * unitPrice,
      0,
    );

    const invested = filteredData.filter((x) => x.data.acceptedUnits > 0);
    const investedCount = invested.length;
    const investedAmount = invested.reduce(
      (acc, x) =>
        acc +
        (parseFloat(x.data.amountReceived) > 0
          ? parseFloat(x.data.amountReceived) - parseFloat(x.data.refundAmount)
          : x.data.acceptedUnits * unitPrice),
      0,
    );

    const activityCount = filteredData.filter((x) => x.lastAction).length;

    // const noCount = filteredData.filter((x) => x.data.interestAmount < 0)
    //   .length;
    // const maybeCount = filteredData.filter((x) => x.data.isUndecided).length;

    const columns = [
      {
        id: 'investor',
        align: 'left',
        padding: '0',
        label: (
          <Box marginTop={1} display="flex" marginLeft={-1}>
            <Box>
              <PeopleOutlineIcon style={{ fontSize: '3rem' }} color="primary" />
            </Box>
            <Box paddingLeft={2}>
              <Box fontWeight="bold">Investors</Box>
              <Box whiteSpace="nowrap">({filteredData.length})</Box>
            </Box>
          </Box>
        ),
      },
      {
        id: 'lastAction',
        align: 'center',
        padding: '0',
        label: (
          <Box paddingLeft="1.5rem">
            <Box fontWeight="bold">Activity</Box>
            <Box>({activityCount})</Box>
          </Box>
        ),
      },
      {
        id: 'interest',
        align: 'center',
        padding: '0',
        label: (
          <Box paddingLeft="1.5rem">
            <Box fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
              Interested
            </Box>
            <Box>
              <span style={{ color: '#007D05' }}>
                {interestAmount > 0 ? formatter.format(interestAmount) : '-'}
              </span>
              &nbsp;({interestCount})
            </Box>
          </Box>
        ),
      },
      ...(dateFiledOfferingAuthorization
        ? [
            {
              id: 'subscribed',
              align: 'center',
              padding: '0',
              label: (
                <Box paddingLeft="1.5rem">
                  <Box fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
                    Proposed
                  </Box>
                  <Box>
                    <span style={{ color: '#007D05' }}>
                      {proposedAmount > 0
                        ? `${formatter.format(proposedAmount)}`
                        : '-'}
                    </span>
                    &nbsp;({proposedCount})
                  </Box>
                </Box>
              ),
            },
            {
              id: 'confirmed',
              align: 'center',
              padding: '0',
              label: (
                <Box paddingLeft="1.5rem">
                  <Box fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
                    Invested
                  </Box>
                  <Box>
                    <span style={{ color: '#007D05' }}>
                      {investedAmount > 0
                        ? formatter.format(investedAmount)
                        : '-'}
                    </span>
                    &nbsp;({investedCount})
                  </Box>
                </Box>
              ),
            },
          ]
        : []),
    ];
    return columns;
  }, [dateFiledOfferingAuthorization, filteredData, unitPrice]);

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(10);
  const { pagedItems, headerCells, itemCount, sorted } = useTableSort({
    columns,
    rows: filteredData,
    page,
    pageCount,
    filterTerms,
  });

  useEffect(() => {
    setPage(0);
  }, [filterTerms, sorted]);

  const sortedRows = useMemo(() => {
    const renderItem = (item) => (
      <UserInvestmentRow
        key={item.id}
        {...{
          isMissing: item.isFake,
          hasSyndicate: !!dateFiledOfferingAuthorization,
          unitPrice,
          data: item,
          onChange: onListUpdate,
          canEdit: isAdmin,
          earlyPayment,
          syndicateId: syndicate?.id,
          groupId,
          groupInvestmentId,
          updateNotes,
        }}
        // hidden={table ? false : true}
      />
    );
    if (pagedItems && pagedItems.length > 0) {
      return pagedItems.map(renderItem);
    }
    return [];
  }, [
    pagedItems,
    dateFiledOfferingAuthorization,
    unitPrice,
    onListUpdate,
    isAdmin,
    earlyPayment,
    syndicate?.id,
    groupId,
    groupInvestmentId,
    updateNotes,
  ]);

  return (
    <Box>
      <Table size="small" component="div">
        <TableHead component="div">
          <TableRow component="div">
            {headerCells}
            {isAdmin && (
              <TableCell size="small" padding="none" component="div" />
            )}
          </TableRow>
        </TableHead>
        <TableBody component="div">{sortedRows}</TableBody>
      </Table>
      <TablePagination
        component="div"
        count={itemCount}
        page={page}
        onPageChange={(_e, newPage) => setPage(newPage)}
        rowsPerPage={pageCount}
        onRowsPerPageChange={(event) => {
          setPageCount(parseInt(event.target.value, 10));
          setPage(0);
        }}
        ActionsComponent={TablePageActions}
      />
    </Box>
  );
};

export default UserInvestmentTable;
