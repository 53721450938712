import React from 'react';
import { Typography, IconButton, Dialog } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Header from './wizard/Header';
import Content from './wizard/Content';
import Footer from './wizard/Footer';

const styles = (theme) => ({
  header: {
    margin: 0,
    background: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },
});

const DialogContent = ({ children, ...others }) => {
  const Content = withStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      ...others,
    },
  }))(MuiDialogContent);
  return <Content>{children}</Content>;
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.header} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogWithTitle = ({
  children,
  onClose,
  title,
  maxWidth = 'md',
  open = true,
  content = null,
  ...rest
}) => {
  return (
    <Dialog
      onClose={onClose}
      {...rest}
      maxWidth={maxWidth}
      open={open}
      scroll="paper"
    >
      {open && (
        <>
          <DialogTitle onClose={onClose}>{title}</DialogTitle>
          <DialogContent {...content}>{children}</DialogContent>
        </>
      )}
    </Dialog>
  );
};

export const DialogWithActions = ({
  children,
  onClose,
  title,
  header = null,
  footer = null,
  maxWidth = 'md',
  open = true,
  bodyOverrides = null,
  footerOverrides = null,
  headerOverrides = null,
  titleOverrides = null,
  scroll = 'paper',
  ...rest
}) => {
  return (
    <Dialog
      onClose={onClose}
      {...rest}
      maxWidth={maxWidth}
      open={open}
      scroll={scroll}
    >
      {open && (
        <>
          <DialogTitle {...titleOverrides} onClose={onClose}>
            {title}
          </DialogTitle>
          {header && <Header {...headerOverrides}>{header}</Header>}
          <Content {...bodyOverrides} padding={3}>
            {children}
          </Content>
          {footer && <Footer {...footerOverrides}>{footer}</Footer>}
        </>
      )}
    </Dialog>
  );
};

export default DialogWithTitle;
