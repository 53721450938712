import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DisplayAddress from 'components/ui/DisplayAddress';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';

const FilingInfo = ({ syndicate }) => {
  const { id, syndicateName, business } = syndicate;

  const [managers, setManagers] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.get_managers({ id });
        const list = response.data;
        setManagers(list.map((x) => x.groupManager));
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <NameValue {...{ name: 'Syndicate Name', value: syndicateName }} />
        </Grid>
        <Grid xs={12}>
          <NameValue
            {...{
              name: 'Manager Name',
              value:
                managers &&
                managers[0] &&
                (managers[0].signerName || managers[0].name),
            }}
          />
        </Grid>
        <Grid xs={12}>
          <NameValue
            {...{
              name: 'Business Address',
              value: <DisplayAddress address={business} />,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const NameValue = ({ name, value }) => {
  return (
    <Grid xs={12}>
      <Grid container spacing={1}>
        <Grid xs>
          <b>{name}:</b>
        </Grid>
        <Grid alignContent="flex-end" style={{ textAlign: 'end' }}>
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilingInfo;
