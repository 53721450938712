import React from 'react';
import { Box } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '54vh',
  },
});
const Content = withStyles(styles)(({ classes, children, ...others }) => {
  return (
    <Box {...others} className={classes.content}>
      {children}
    </Box>
  );
});
export default Content;
