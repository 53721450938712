import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import {
  CustomNoRowsOverlay,
  useTableStyles,
} from 'views/SyndicatesIndex/SyndicatesIndex';
import { CustomGridColumnMenu } from 'components/ui/CustomGridColumnMenu';

const OpportunitySectionFinancials = ({ opportunity }) => {
  const { previousFunding, previousValuation, revenueData } = opportunity || {};
  const classes = useTableStyles();
  const {
    showCurrentYearly,
    showCurrentMrr,
    showCurrentArr,
    showCurrentTtmr,
    showHistoricalYearly,
    showHistoricalMrr,
    showHistoricalArr,
    showProjectedYearly,
    showProjectedMrr,
    showProjectedArr,
  } = revenueData || {};
  let { years = [] } = revenueData || {};
  const showTable =
    showCurrentYearly ||
    showCurrentMrr ||
    showCurrentArr ||
    showHistoricalYearly ||
    showHistoricalMrr ||
    showHistoricalArr ||
    showProjectedYearly ||
    showProjectedMrr ||
    showProjectedArr;
  const showAnyProjected =
    showProjectedYearly || showProjectedMrr || showProjectedArr;
  const showAnyHistorical =
    showHistoricalYearly || showHistoricalMrr || showHistoricalArr;
  let currentActualYear;
  let tableYears = [...years];
  if (tableYears.length > 0) {
    const currentYear = moment().year();
    const currentYearIndex = tableYears.findIndex((x) => x.id == currentYear);
    if (currentYearIndex > -1) {
      const currentYearValue = tableYears[currentYearIndex];
      currentActualYear = { ...currentYearValue };
      delete currentActualYear.projected;
      const currentProjectedYear = { ...currentYearValue };
      delete currentProjectedYear.actual;
      if (!currentProjectedYear.projected) {
        tableYears.splice(currentYearIndex, 1);
      } else {
        tableYears = [
          ...tableYears.slice(0, currentYearIndex),
          currentProjectedYear,
          ...tableYears.slice(currentYearIndex + 1),
        ];
      }
    }

    if (!showAnyProjected) {
      tableYears = tableYears.filter((x) => x.id < currentYear);
    }
    if (!showAnyHistorical) {
      tableYears = tableYears.filter((x) => x.id > currentYear);
    }
  }
  const getVal = (val) => {
    if (!val) {
      return '-';
    }
    return moment(val).format('YYYY MM/DD');
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Date',
      width: 150,
      valueGetter: (params) => {
        const isCurrent = moment().year() == params.row.id;
        if (isCurrent && Object.keys(params.row?.actual || {}).length > 0) {
          return params.row.actual?.revenueAsOfDate
            ? getVal(params.row.actual?.revenueAsOfDate)
            : `${params.row.id} (Actual)`;
        }
        return `${params.row.id} (${
          !moment().year(params.row.id).isBefore(moment())
            ? 'Projected'
            : 'Actual'
        })`;
      },
      renderCell: (params) => (
        <Typography variant="body2">{params.value}</Typography>
      ),
      headerClassName: 'custom-table-header',
    },
  ];
  const getCellRevenueDisplay = ({
    year,
    valGetter,
    showHistorical,
    showProjected,
  }) => {
    const isFuture = moment().year(year).isAfter(moment(), 'year');
    const isCurrent = moment().year(year).isSame(moment(), 'year');
    const isHistorical = !isFuture && !isCurrent;
    if (isHistorical && !showHistorical) {
      return null;
    } else if ((isCurrent || isFuture) && !showProjected) {
      return null;
    }
    return valGetter();
  };
  if (showCurrentYearly || showHistoricalYearly || showProjectedYearly) {
    columns.push({
      field: 'py',
      headerName: 'Yearly Revenue',
      flex: 1,
      minWidth: 150,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {currencyFormatter(params.value)}
          </Typography>
        );
      },
      valueGetter: (params) => {
        let isProjected = moment().year(params.row.id).isAfter(moment());
        let isCurrent = moment().year(params.row.id).isSame(moment(), 'year');
        if (!isProjected && isCurrent) {
          isProjected = Object.keys(params.row?.projected || {}).length > 0;
        }
        return getCellRevenueDisplay({
          year: params.row.id,
          valGetter: () =>
            isProjected
              ? params.row.projected?.yearly || 0
              : params.row.actual?.yearly || 0,
          showHistorical: showHistoricalYearly,
          showProjected: showProjectedYearly,
        });
      },
    });
  }
  if (showCurrentMrr || showHistoricalMrr || showProjectedMrr) {
    columns.push({
      field: 'pmrr',
      headerName: 'MRR',
      minWidth: 150,
      flex: 1,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {currencyFormatter(params.value)}
          </Typography>
        );
      },
      valueGetter: (params) => {
        let isProjected = moment().year(params.row.id).isAfter(moment());
        let isCurrent = moment().year(params.row.id).isSame(moment(), 'year');
        if (!isProjected && isCurrent) {
          isProjected = Object.keys(params.row?.projected || {}).length > 0;
        }
        return getCellRevenueDisplay({
          year: params.row.id,
          valGetter: () =>
            isProjected
              ? params.row.projected?.mrr || 0
              : params.row.actual?.mrr || 0,
          showHistorical: showHistoricalMrr,
          showProjected: showProjectedMrr,
        });
      },
    });
  }
  if (showCurrentArr || showHistoricalArr || showProjectedArr) {
    columns.push({
      field: 'parr',
      headerName: 'ARR',
      flex: 1,
      minWidth: 150,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {currencyFormatter(params.value)}
          </Typography>
        );
      },
      valueGetter: (params) => {
        let isProjected = moment().year(params.row.id).isAfter(moment());
        let isCurrent = moment().year(params.row.id).isSame(moment(), 'year');
        if (!isProjected && isCurrent) {
          isProjected = Object.keys(params.row?.projected || {}).length > 0;
        }
        return getCellRevenueDisplay({
          year: params.row.id,
          valGetter: () =>
            isProjected
              ? params.row.projected?.arr || 0
              : params.row.actual?.arr || 0,
          showHistorical: showHistoricalArr,
          showProjected: showProjectedArr,
        });
      },
    });
  }
  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Financials
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Box>
          <Typography>Previous Funding Information</Typography>
        </Box>

        <NameValue
          name="Total Previous Funding"
          value={previousFunding}
          required
        />
        <NameValue
          name="Total Valuation at Last Funding"
          value={previousValuation}
        />
      </Box>
      <Box marginTop={4}>
        <Box marginTop={2}>
          <Typography>Revenue Information</Typography>
        </Box>
        {currentActualYear?.actual?.revenueAsOfDate && (
          <NameValue
            name="Revenue as of Date"
            value={moment(currentActualYear?.actual?.revenueAsOfDate).format(
              'M/D/YYYY',
            )}
            formatter={(value) => value}
          />
        )}
        {showCurrentYearly && currentActualYear?.actual?.yearly && (
          <NameValue
            name="Yearly Revenue"
            value={currentActualYear?.actual?.yearly}
          />
        )}
        {showCurrentMrr && currentActualYear?.actual?.mrr && (
          <NameValue
            name="Monthly Recurring Revenue"
            value={currentActualYear?.actual?.mrr}
          />
        )}
        {showCurrentArr && currentActualYear?.actual?.arr && (
          <NameValue
            name="Annual Recurring Revenue"
            value={currentActualYear?.actual?.arr}
          />
        )}
        {showCurrentTtmr && currentActualYear?.actual?.ttmr && (
          <NameValue
            name="Trailing Twelve Month Revenue (TTMR)"
            value={currentActualYear?.actual?.ttmr}
          />
        )}
        {showTable && tableYears.length > 0 && (
          <DataGridPro
            sx={{
              backgroundColor: '#ffffff',
              marginTop: '1rem',
            }}
            getRowId={(row) =>
              row.id == moment().year()
                ? row.actual
                  ? `${row.id} (Actual)`
                  : `${row.id} (Projected)`
                : row.id
            }
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              ColumnMenu: CustomGridColumnMenu,
            }}
            rows={tableYears}
            className={classes.root}
            columns={columns}
            autoHeight
            disableColumnPinning
            hideFooterRowCount
            disableSelectionOnClick
            disableColumnSelector
          />
        )}
      </Box>
    </>
  );
};

var currencyFormatter = (value) =>
  !value
    ? '-'
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value);
const NameValue = ({
  name,
  value,
  required,
  formatter = currencyFormatter,
  last,
}) => {
  if ((!value || value == 0) && !required) return null;
  return (
    <Box
      marginTop={2}
      paddingBottom={1}
      style={{ borderBottom: !last ? '1px solid lightgray' : 'none' }}
    >
      <Grid container spacing={3}>
        <Grid xs>{name}:</Grid>
        <Grid xs>
          <Box fontWeight="bold">{value ? formatter(value) : '-'}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OpportunitySectionFinancials;
