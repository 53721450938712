import React from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

import useFormTargetAmount from 'hooks/forms/useFormTargetAmount';

const EditTargetAmount = ({ data, onChange, onClose }) => {
  const { id } = data;

  const { form, validate } = useFormTargetAmount({ data });

  const onSave = async (onError) => {
    try {
      const { values, errors } = await validate();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await groupInvestmentsClient.update({
        id,
        data: values,
      });
      onChange(response.data);
      onClose();
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <>
      <Box marginBottom={4}>
        <Box marginBottom={2}>{form}</Box>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid>
            <Button variant="contained" onClick={onClose} color="grey">
              Cancel
            </Button>
          </Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name={id ? 'Save' : 'Add'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditTargetAmount;
