import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import syndicatesClient from 'api/syndicatesClient';
import { documentStates } from 'hooks/useSyndicateDocumentPoll';
import { FaInfoCircle } from 'react-icons/fa';

const { COMPLETED, AWAITING_SIGNATURE } = documentStates;

const SyndicateAuthorizationDocuments = ({
  actionButton,
  syndicate,
  setSyndicate,
}) => {
  const theme = useTheme();

  const { id, filingAuthorizationId, formSs4Id, primaryManager } =
    syndicate || {};
  const {
    groupManager: { email },
  } = primaryManager || { groupManager: {} };

  const filingAuthorization = useSyndicateDocument({
    id: filingAuthorizationId,
    documentName: 'Filing Authorization',
    role: 'manager',
  });
  const formSs4 = useSyndicateDocument({
    id: formSs4Id,
    documentName: 'Form - SS4',
    linkEmail: email,
    role: 'manager',
  });
  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.file_formation_authorization({
        id,
      });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };
  return (
    <Box>
      <Box
        marginBottom={2}
        bgcolor={theme.palette.primary.main}
        color="white"
        padding={2}
      >
        <Grid container spacing={2}>
          <Grid
            style={{
              color: theme.palette.accent.main,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaInfoCircle size={30} />
          </Grid>

          <Grid xs>
            <Typography variant="subtitle2">
              To form the LLC, you as a manager need to authorize SmartCapital
              to file the Articles of Organization with the State of Colorado
              and apply for an Employer Identification Number (EIN) with the
              Internal Revenue Service (IRS). Please complete and sign the forms
              below to complete this authorization.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box padding={3} pt={1}>
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: filingAuthorization }} />
        </Box>
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: formSs4 }} />
        </Box>
      </Box>

      <Box padding={2} marginTop={2} paddingBottom={4}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid>{actionButton}</Grid>
          <Grid>
            <SaveFormButton
              onSave={onSave}
              name="Continue"
              disabled={
                (filingAuthorization.stepName != COMPLETED &&
                  filingAuthorization.stepName != AWAITING_SIGNATURE) ||
                (formSs4.stepName != COMPLETED &&
                  formSs4.stepName != AWAITING_SIGNATURE)
              }
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SyndicateAuthorizationDocuments;
