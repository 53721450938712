import React from 'react';
import { Box, Typography } from '@mui/material';

import { DocumentsTable } from 'views/Documents';

const OpportunitySectionDocuments = ({ opportunity }) => {
  const { documents = [] } = opportunity || {};

  if (!documents || documents.length === 0) {
    return null;
  }

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Documents
        </Typography>
      </Box>
      <Box marginTop={4}>
        <DocumentsTable documents={documents} />
      </Box>
    </>
  );
};

export default OpportunitySectionDocuments;
