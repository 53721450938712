import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    bgcolor: theme.palette.grey[100],
  },
}));

const Blank = (props) => {
  const classes = useStyles();
  return <Box className={classes.root}>{props.children}</Box>;
};

Blank.propTypes = {
  children: PropTypes.any,
};

export default Blank;
