import React, { useCallback, useMemo, useState } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import UserInvestmentProfile from './UserInvestmentProfile';
import { FormikTextInput } from 'components/ui/CustomTextField';
import { CustomCurrencyInput } from 'components/ui/CustomCurrencyInput';
import * as yup from 'yup';
import { useDocument } from 'components/ui/DocumentPicker';

const useFormUploadSignature = ({ unitPrice, data }) => {
  const { subscription } = data || {};
  const [formRef, setFormRef] = useState({});

  const { currentDocument, documentPicker } = useDocument(subscription);
  const validate = useCallback(async () => {
    const {
      values: { acceptedUnits },
      errors,
    } = await validateForm(formRef);

    if (!currentDocument || !currentDocument.uri) {
      errors.push('You must upload a document');
    }

    return {
      errors,
      values: {
        acceptedUnits,
        attachment: currentDocument ? currentDocument.data : null,
        attachmentFileName: currentDocument ? currentDocument.name : null,
      },
    };
  }, [currentDocument, formRef]);
  const form = useMemo(
    () => (
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        {({ values }) => {
          const { acceptedUnits } = values;
          return (
            <>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormikTextInput
                    label="Accepted Units"
                    name="acceptedUnits"
                    type="number"
                    variant="filled"
                    required
                    validation={yup.number().moreThan(0)}
                  />
                </Grid>
                <Grid xs={6}>
                  <CustomCurrencyInput
                    label="Accepted Amount"
                    // InputProps={{ inputProps: { min: 0, max: requestedUnits } }}
                    value={acceptedUnits * unitPrice}
                    variant="filled"
                    disabled
                  />
                </Grid>
              </Grid>
              <Box>{documentPicker}</Box>
            </>
          );
        }}
      </Form>
    ),
    [data, documentPicker, unitPrice],
  );

  return { form, validate, currentDocument };
};

const UserInvestmentSignatureUpload = ({
  unitPrice,
  userInvestment,
  onChange,
  onClose,
}) => {
  const { id } = userInvestment || {};
  const { form, validate, currentDocument } = useFormUploadSignature({
    unitPrice,
    data: userInvestment,
  });

  const onSave = async (onError) => {
    try {
      const { values, errors } = await validate();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await userInvestmentsClient.uploadSignature({
        id,
        data: values,
      });

      onChange(response.data);
      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Box>
              <UserInvestmentProfile {...{ userInvestment }} />
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box padding={1}>{form}</Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid container spacing={2} xs justifyContent="flex-end">
            {/* <Grid>
              <Button
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
                disabled={!link}
              >
                Review Document {hasSigned ? ' ' : 'and Sign '}
                {!link && <LoadingSpinner size={20}></LoadingSpinner>}
              </Button>
            </Grid> */}
            <Grid>
              <SaveFormButton
                onSave={onSave}
                name="Upload"
                variant="contained"
                disabled={!currentDocument}
              ></SaveFormButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserInvestmentSignatureUpload;
