import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';

const FormItem = (props) => {
  return <Box {...props}>{props.children}</Box>;
};

FormItem.propTypes = {
  children: PropTypes.any,
};

export default FormItem;
