import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FaMoneyCheckAlt, FaPiggyBank } from 'react-icons/fa';
import { MdGroupWork } from 'react-icons/md';
import userClient from 'api/user/userClient';
import SummaryItem from './SummaryItem';

const SummaryWidget = ({ investments }) => {
  const theme = useTheme();
  const [groups, setGroups] = useState([]);
  const shouldUseSmallerFont = useMediaQuery(
    theme.breakpoints.between(960, 1300),
  );
  useEffect(() => {
    const fetchData = async () => {
      const result = await userClient.dashboard_groups();
      const { data } = result;
      if (data && data.length > 0) {
        setGroups(data);
      }
    };
    fetchData();
  }, []);

  const amount = (investments || []).reduce((prev, val) => {
    const { amount } = val;
    if (parseFloat(amount) > 0) {
      return prev + parseFloat(amount);
    }
    return prev;
  }, 0);
  return (
    <>
      <Grid
        container
        spacing={5}
        direction="column"
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <SummaryItem
          name="Invested"
          summaryTitle="The total amount of money you have invested"
          value={amount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          valueProps={{
            style: {
              fontSize: (amount || 0) < 1000000 ? 24 : 20, // when digits are under 6 use the default 24, otherwise use 20
              color: theme.palette.money.main,
            },
          }}
          iconFontSize={!shouldUseSmallerFont ? 46 : 30}
          titleProps={{
            style: {
              fontSize: !shouldUseSmallerFont ? 24 : 18,
              fontWeight: 700,
            },
          }}
          Icon={FaMoneyCheckAlt}
        />
        <SummaryItem
          name="Investments"
          summaryTitle="The number of investments you have made"
          value={investments?.length || 0}
          Icon={FaPiggyBank}
          valueProps={{
            style: { fontSize: 48, fontWeight: 300, lineHeight: 1 },
          }}
          iconFontSize={!shouldUseSmallerFont ? 46 : 30}
          titleProps={{
            style: {
              fontSize: !shouldUseSmallerFont ? 24 : 18,
              fontWeight: 700,
            },
          }}
        />
        <SummaryItem
          name="Groups"
          summaryTitle="The amount of groups you are a part of"
          value={groups?.length}
          Icon={MdGroupWork}
          valueProps={{
            style: { fontSize: 48, fontWeight: 300, lineHeight: 1 },
          }}
          iconFontSize={!shouldUseSmallerFont ? 46 : 30}
          titleProps={{
            style: {
              fontSize: !shouldUseSmallerFont ? 24 : 18,
              fontWeight: 700,
            },
          }}
        />
      </Grid>
    </>
  );
};
export default SummaryWidget;
