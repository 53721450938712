import React, { useCallback, useMemo, useState } from 'react';
import {
  MenuItem,
  Box,
  Button,
  Typography,
  Paper,
  IconButton,
  useTheme,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import {
  FormikCountrySelect,
  FormikRegionSelect,
} from 'components/ui/RegionSelect';
import { FormikSelect } from 'components/ui/CustomSelect';
import { FormikPhoneInput } from 'components/ui/CustomPhone';
import groupManagersClient from 'api/groupManagersClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import { FieldArray } from 'formik';
import { Add, DeleteForever } from '@mui/icons-material';
import useDeleteConfirm from 'hooks/useDeleteConfirm';
import { IoInformationCircleOutline } from 'react-icons/io5';

const RelatedPersonCard = ({
  item,
  managerType,
  onDelete,
  canDelete = true,
  index,
}) => (
  <Paper
    variant="outlined"
    key={item.id}
    style={{
      padding: '1rem',
      width: '100%',
      height: 'auto',
      marginBottom: '1rem',
    }}
  >
    <Grid container spacing={2} key={index}>
      <Grid xs={3}>
        <FormikTextInput
          name={`groupManagerPersons[${index}].firstName`}
          label="First Name"
          variant="filled"
          required
        />
      </Grid>
      <Grid xs={4}>
        <FormikTextInput
          name={`groupManagerPersons[${index}].lastName`}
          label="Last Name"
          variant="filled"
          required
        />
      </Grid>
      <Grid xs={4}>
        <FormikTextInput
          name={`groupManagerPersons[${index}].title`}
          label="Title"
          variant="filled"
        />
      </Grid>
      {managerType === 'entity' && canDelete && (
        <Grid
          xs={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton size="small" onClick={() => onDelete(item)}>
            <DeleteForever />
          </IconButton>
        </Grid>
      )}
      <Grid xs={3}>
        <Box flexGrow={1} display="flex" alignItems="center">
          <FormikSelect
            name={`groupManagerPersons[${index}].relatedType`}
            variant="filled"
            required
            label="Relationship"
          >
            <MenuItem value="executive_officer">Executive Officer</MenuItem>
            <MenuItem value="director">Director</MenuItem>
            <MenuItem value="promoter">Promoter</MenuItem>
          </FormikSelect>
        </Box>
      </Grid>
      <Grid xs={3}>
        <FormikPhoneInput
          name={`groupManagerPersons[${index}].phone`}
          label="Phone"
          variant="filled"
          onlyCountries={['us', 'ca']}
          disableCountryCode
          disableDropdown
          autoComplete="off"
          required
        />
      </Grid>
      <Grid xs={6}>
        <FormikTextInput
          name={`groupManagerPersons[${index}].email`}
          label="Email"
          variant="filled"
          required
        />
      </Grid>
    </Grid>
  </Paper>
);

const useFormGroupManager = ({ data }) => {
  const { id } = data || {};
  const [formRef, setFormRef] = useState({});

  const validate = useCallback(async () => {
    const {
      values: {
        managerType,
        name,
        phone,
        email,
        secondary,
        street,
        city,
        state,
        postal,
        country,
        groupManagerPersons,
      },
      errors,
    } = await validateForm(formRef);
    if (
      managerType === 'entity' &&
      (!groupManagerPersons || groupManagerPersons?.length === 0)
    ) {
      errors.push({
        groupManagerPersons: 'At least one related person is required',
      });
    }
    console.log(errors);
    const temp = [...(groupManagerPersons || [])];
    // find ids of persons that are not in the list
    const ids = temp.map((item) => item.id);
    const persons =
      data?.groupManagerPersons?.filter((item) => !ids.includes(item.id)) || [];
    persons.forEach((item) => temp.push({ ...item, _destroy: true }));
    return {
      errors,
      values: {
        managerType,
        name,
        phone,
        email,
        secondary,
        street,
        city,
        state,
        postal,
        country,
        groupManagerPersons: temp,
      },
    };
  }, [data?.groupManagerPersons, formRef]);

  const form = useMemo(
    () => (
      <Form
        key={id}
        initialValues={data}
        validateOnChange={false}
        enableReinitialize
        setRef={setFormRef}
      >
        {({ values }) => {
          const { country } = values || {};
          return (
            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <FormikSelect
                  name="managerType"
                  variant="filled"
                  label="Manager Type"
                  onChange={(e) => {
                    formRef.current.setFieldValue(
                      'managerType',
                      e.target.value,
                    );
                    if (e.target.value === 'individual') {
                      formRef.current.setFieldValue('groupManagerPersons', [
                        { ...formRef.current.values.groupManagerPersons[0] },
                      ]);
                    }
                  }}
                  required
                >
                  <MenuItem value="individual">Individual</MenuItem>
                  <MenuItem value="entity">Entity</MenuItem>
                </FormikSelect>
              </Grid>
              {values.managerType == 'entity' && (
                <Grid xs={12} md={8}>
                  <FormikTextInput
                    name="name"
                    label="Entity Name"
                    variant="filled"
                    required
                  />
                </Grid>
              )}

              <Grid xs={12}>
                <FormikTextInput
                  name="street"
                  label="Street Address"
                  variant="filled"
                  required
                />
              </Grid>

              <Grid xs={12}>
                <FormikTextInput
                  name="secondary"
                  label="Secondary Address (optional)"
                  variant="filled"
                  placeholder="Building, Suite, Room, Apt. etc"
                />
              </Grid>
              <Grid xs={12} md={5}>
                <FormikTextInput
                  name="city"
                  label="City"
                  variant="filled"
                  required
                />
              </Grid>
              <Grid xs={7} md={4}>
                <FormikRegionSelect
                  country={country || 'US'}
                  name="state"
                  label="State/Region"
                  variant="filled"
                  required
                />
              </Grid>
              <Grid xs={5} md={3}>
                <FormikTextInput
                  name="postal"
                  label="Postal Code"
                  variant="filled"
                  required
                />
              </Grid>

              <Grid xs={12} md={5} style={{ display: 'none' }}>
                <FormikCountrySelect
                  name="country"
                  label="Country"
                  variant="filled"
                  defaultValue="US"
                  disabled
                />
              </Grid>

              <FieldArray
                name="groupManagerPersons"
                render={({ push, remove }) => (
                  <Grid xs={12}>
                    <Box
                      mt={values.managerType == 'entity' ? 2 : 0}
                      display="flex"
                      alignItems="center"
                    >
                      {values.managerType == 'entity' && (
                        <>
                          <Box mr={2}>
                            <Typography variant="h6">Director(s)</Typography>
                          </Box>
                          <Button
                            size="small"
                            style={{ marginRight: '0.75rem' }}
                            onClick={() =>
                              push({
                                firstName: '',
                                lastName: '',
                                phone: '',
                                email: '',
                                relatedType: '',
                                title: '',
                              })
                            }
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ lineHeight: 1.25 }}
                            >
                              Add{' '}
                              <Add
                                style={{
                                  fontSize: '1.25rem',
                                }}
                              />
                            </Box>
                          </Button>
                          <Tooltip
                            arrow
                            title={
                              <Typography variant="subtitle1">
                                Add all individuals circulating and managing the
                                syndicate. All managers must be reported to the
                                Securities and Exchange Commission (SEC).
                              </Typography>
                            }
                          >
                            <Box display="flex" alignItems="center">
                              <IoInformationCircleOutline
                                style={{
                                  fontSize: '1.25rem',
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        overflowY: 'auto',
                        maxHeight: '30rem',
                      }}
                    >
                      {values.groupManagerPersons?.map((person, index) => (
                        <RelatedPersonCard
                          {...{
                            item: person,
                            managerType: values.managerType,
                            index,
                            onDelete: () => {
                              remove(index);
                            },
                            canDelete: values.groupManagerPersons.length > 1,
                          }}
                        />
                      ))}
                    </Box>
                  </Grid>
                )}
              />
            </Grid>
          );
        }}
      </Form>
    ),
    [data, formRef, id],
  );
  return { form, validate };
};

export const useEditGroupManager = ({ groupId, onChange, fetchData }) => {
  const theme = useTheme();
  const [modal, showModal] = useState();

  const onDelete = useCallback(
    async (onError) => {
      try {
        await groupManagersClient.destroy({ id: modal?.id });
        fetchData();
        showModal(false);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    },
    [modal?.id, fetchData],
  );

  const { modal: deleteModal, showModal: showDeleteModal } = useDeleteConfirm({
    onConfirm: onDelete,
    headerName: 'Manager',
  });

  const { form, validate } = useFormGroupManager({
    data: modal,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        console.log(`errors`, errors);
        console.log(`errors.length`, errors.length);
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = modal?.id
          ? groupManagersClient.update
          : groupManagersClient.create;
        if (values.managerType == 'individual' && !values.name) {
          values.name = `${values.groupManagerPersons[0].firstName} ${values.groupManagerPersons[0].lastName}`;
        }
        const response = await upsert({
          groupId,
          id: modal?.id,
          data: {
            ...values,
            group_manager_persons_attributes: values.groupManagerPersons,
          },
        });
        const data = response.data;
        onChange(data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [groupId, modal?.id, onChange, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <>
        {deleteModal}
        <Box padding={2}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid>
              <Button
                variant="contained"
                onClick={() => showModal()}
                color="grey"
              >
                Cancel
              </Button>
            </Grid>
            {modal?.id && (
              <Grid>
                {modal.hasSyndicates ? (
                  <SaveFormButton
                    style={{
                      color: theme.palette.accent.main,
                      borderColor: theme.palette.accent.main,
                    }}
                    onSave={onDelete}
                    name={`Archive`}
                  ></SaveFormButton>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      color: theme.palette.accent.main,
                      borderColor: theme.palette.accent.main,
                    }}
                    onClick={() => showDeleteModal(true)}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            )}
            <Grid>
              <SaveFormButton onSave={onSave} name="Save"></SaveFormButton>
            </Grid>
          </Grid>
        </Box>
      </>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${modal.id ? 'Edit' : 'Add'} Group Manager`,
          // header,
          footer,
          bodyOverrides: {
            style: {
              overflowY: 'visible',
              maxHeight: 'unset',
            },
          },
          scroll: 'body',
          onClose: () => showModal(),
        }}
      >
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [
    deleteModal,
    form,
    modal,
    onSave,
    onDelete,
    showDeleteModal,
    theme.palette.accent.main,
  ]);

  return { modal: modalElement, showModal, form, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormGroupManager;
