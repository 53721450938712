import React, { useRef } from 'react';

import { Box } from '@mui/material';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userClient from 'api/user/userClient';

import GenericPortal from 'views/GenericPortal';
import { useDispatch, useSelector } from 'react-redux';

import SaveFormButton from 'components/Form/SaveFormButton';

import useFormUserProfile from 'hooks/forms/useFormUserProfile';
import { updateCurrentUserData } from 'redux/currentUser/actions';

const UserSetup = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.currentUser.data);

  const submitButton = useRef(null);
  const onSubmit = () => {
    submitButton.current.click();
  };

  const { form, validate } = useFormUserProfile({ data, onSubmit });
  const onSave = async (onError) => {
    try {
      const { values, errors } = await validate();

      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const response = await userClient.updateUserData({
        data: {
          ...values,
          isSetup: true,
        },
      });
      dispatch(updateCurrentUserData(response.data));

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <GenericPortal maxWidth="md" title="Profile Setup">
      <Box>{form}</Box>

      <Box marginTop={5} textAlign="center">
        <SaveFormButton
          onSave={onSave}
          variant="contained"
          color="primary"
          name="Save Profile"
          buttonRef={submitButton}
        ></SaveFormButton>
      </Box>
    </GenericPortal>
  );
};

export default UserSetup;
