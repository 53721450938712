import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import getServerResponseErrors from 'api/getServerResponseErrors';

import Form from 'components/Form/Form';

import groupUsersClient from 'api/groupUsersClient';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import {
  CustomTextField,
  FormikTextInput,
} from 'components/ui/CustomTextField';

const EditInvestor = ({ data, onChange, onClose }) => {
  const { id, user } = data;
  const { firstName, lastName, email, phone } = user || {};
  const [isAdmin, setAdmin] = useState(data.isAdmin);

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          firstName,
          lastName,
          phone,
          // email
        },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const response = await groupUsersClient.update({
        id,
        data: {
          firstName: firstName ? firstName : null,
          lastName: lastName ? lastName : null,
          // email: email ? email : null,
          phone: phone ? phone : null,
          isAdmin,
        },
      });
      onChange(response.data);
      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <Box borderRadius={1}>
      <Box>
        <Typography>User Profile</Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3}>
          <Grid sm={4} xs={6}>
            <CustomTextField
              name="firstName"
              label="First Name"
              variant="filled"
              disabled
              value={firstName}
            />
          </Grid>
          <Grid sm={4} xs={6}>
            <CustomTextField
              name="lastName"
              label="Last Name"
              variant="filled"
              disabled
              value={lastName}
            />
          </Grid>
          <Grid sm={4} xs={6}>
            <CustomTextField
              name="phone"
              label="Phone"
              variant="filled"
              disabled
              value={phone}
            />
          </Grid>
          <Grid xs={12}>
            <CustomTextField
              variant="filled"
              name="email"
              label="Email"
              value={email}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Typography>Custom Overrides</Typography>
      </Box>
      <Box marginTop={2}>
        <Form initialValues={data} enableReinitialize setRef={setFormRef}>
          <Grid container spacing={3}>
            <Grid sm={4} xs={6}>
              <FormikTextInput
                name="firstName"
                label="First Name"
                helperText="leave blank to use profile value"
              />
            </Grid>
            <Grid sm={4} xs={6}>
              <FormikTextInput
                name="lastName"
                label="Last Name"
                helperText="leave blank to use profile value"
              />
            </Grid>
            <Grid sm={4} xs={6}>
              <FormikTextInput
                name="phone"
                label="Phone"
                helperText="leave blank to use profile value"
              />
            </Grid>

            <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAdmin}
                    onChange={(e) => setAdmin(e.target.checked)}
                  />
                }
                label="Is Admin"
              />
            </Grid>
          </Grid>
          <Box marginTop={5}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid>
                <Button variant="contained" onClick={onClose} color="grey">
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <SaveFormButton onSave={onSave}></SaveFormButton>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default EditInvestor;
