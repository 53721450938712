import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, useTheme, Link, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { IoRocketSharp } from 'react-icons/io5';
import { FiUsers, FiSliders } from 'react-icons/fi';
import { useSelectedGroup } from 'hooks/useAppState';
import Logo from 'images/logo.svg';
import UserMenu from '../../layouts/UserMenu';
import styled from '@emotion/styled'; // required because Box refs are not fixed until material ui 5
import NavigationMenu from 'layouts/NavigationMenu';
import { FaCog, FaPiggyBank } from 'react-icons/fa';
import { BsListCheck } from 'react-icons/bs';

const StyledBox = styled(Box)``;

const useStyles = makeStyles((theme) => ({
  scrolled: {
    position: 'fixed',
    top: 0,
    left: 0,
    animation: `$moveDown 500ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes moveDown': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-5rem)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  logo: {
    cursor: 'pointer',
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    margin: `0 ${theme.spacing(1)}`,
    '&:focus': {
      opacity: 1,
    },
    '& :hover': {
      color: theme.palette.secondary.main,
    },
    '&:active': {
      color: theme.palette.accent.main,
    },
  },
  wrapper: {
    lineHeight: 1,
    fontSize: theme.typography.fontSize,
  },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles(() => ({
  root: {
    height: '100%',
  },
}))(Tabs);

const GroupNavigation = ({ sticky, navBarRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState(false);
  const { isAdmin, slug } = useSelectedGroup((state) => ({
    isAdmin: state.isAdmin,
    slug: state.slug,
  }));
  const url = useCallback((url = '') => `/${slug}${url}`, [slug]);

  const theme = useTheme();
  const showAdmin = true; // useMediaQuery(theme.breakpoints.up('md'));
  const isExtraLargeUp = useMediaQuery(theme.breakpoints.up('xl'));
  const isExtraSmallDown = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    const pathSegments = pathname.split('/');
    let navSegment = pathSegments[2];
    if (
      navSegment === 'opportunities' ||
      navSegment === 'opportunity' ||
      navSegment === 'investors' ||
      navSegment === 'syndicates' ||
      navSegment === 'management' ||
      navSegment === 'admin' ||
      navSegment === 'actions'
    ) {
      if (navSegment === 'opportunity') navSegment = 'opportunities';
      setCurrentTab(navSegment);
    } else {
      setCurrentTab(false);
    }
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <StyledBox
      borderBottom={`1px solid ${theme.palette.divider}`}
      className={clsx({
        [classes.scrolled]: sticky,
      })}
      width="100%"
      style={{
        backgroundColor: theme.palette.background.paper,
      }}
      zIndex={theme.zIndex.appBar}
      ref={navBarRef}
    >
      <Grid
        container
        alignItems="center"
        flexWrap={isExtraSmallDown ? 'wrap' : 'nowrap'}
      >
        <Grid
          xs={12}
          sm="auto"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box
            marginRight={1}
            marginLeft={1}
            title="SmartCapital"
            className={classes.logo}
          >
            <Link
              onClick={() => {
                history.push('/user/dashboard');
              }}
              style={{ display: 'flex' }}
            >
              <img alt="SmartCapital" src={Logo} height="38px" width="38px" />
            </Link>
          </Box>
          <NavigationMenu />
        </Grid>
        <Grid xs={10} sm={6} md={8} lg={9} xl>
          <Box marginLeft={1} height="100%">
            <StyledTabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {isAdmin && showAdmin && (
                <StyledTab
                  value="syndicates"
                  label="Syndicates"
                  icon={<FaPiggyBank size="1.25em" />}
                  iconPosition="start"
                  onClick={() => history.push(url('/syndicates'))}
                  alignItems="center"
                />
              )}
              {isAdmin && showAdmin && (
                <StyledTab
                  data-tour="investor-tab"
                  value="investors"
                  label="Investors"
                  icon={<FiUsers size="1.25em" />}
                  iconPosition="start"
                  onClick={() => history.push(url('/investors'))}
                />
              )}
              {isAdmin && showAdmin && (
                <StyledTab
                  value="management"
                  label="Management"
                  icon={<FiSliders size="1.25em" />}
                  iconPosition="start"
                  onClick={() => history.push(url('/management'))}
                />
              )}
              <StyledTab
                value="opportunities"
                label="Opportunities"
                icon={<IoRocketSharp size="1.25em" />}
                iconPosition="start"
                onClick={() => history.push(url('/opportunities'))}
              />
              <StyledTab
                value="actions"
                label="Actions"
                icon={<BsListCheck size="1.25em" />}
                iconPosition="start"
                onClick={() => history.push(url('/actions'))}
              />
              {isAdmin && showAdmin && (
                <StyledTab
                  value="admin"
                  data-tour="group-tab"
                  label={isExtraLargeUp ? 'Group Settings' : 'Settings'}
                  icon={<FaCog size="1.25em" />}
                  iconPosition="start"
                  onClick={() => history.push(url('/admin'))}
                />
              )}
            </StyledTabs>
          </Box>
        </Grid>
        <Grid
          xs={2}
          sm="auto"
          sx={{
            display: { xs: 'inherit', sm: 'flex' },
            'flex-grow': { xs: '0', sm: '1' },
            justifyContent: 'flex-end',
          }}
        >
          <UserMenu url={url} />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default withRouter(GroupNavigation);
