import React, { useEffect, useState } from 'react';
import { isEqual, isNil, transform, isObject } from 'lodash';
import { Box } from '@mui/material';
import {
  FaFileInvoiceDollar,
  FaDollarSign,
  FaCheckDouble,
  FaBriefcase,
} from 'react-icons/fa';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import SyndicateFormFees from './SyndicateFormFees';
import SyndicateFormUnitPricing from './SyndicateFormUnitPricing';
import SyndicateFormClosingDates from './SyndicateFormClosingDates';
import SyndicateFormReview from './SyndicateFormReview';
import Header from 'components/ui/wizard/Header';
import CustomizedSteppers from 'components/ui/CustomStepper';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';
import { useSelectedGroup } from 'hooks/useAppState';

import EventIcon from '@mui/icons-material/Event';
import SyndicateFormBanking from './SyndicateFormBanking';
import SyndicateFormCarry from './SyndicateFormCarry';
import { HiOutlineReceiptTax } from 'react-icons/hi';
import syndicatesClient from 'api/syndicatesClient';
import SyndicateFederalDocuments from './SyndicateFederalDocuments';
import SyndicateSignatures from './SyndicateSignatures';

const defaultSteps = [
  {
    id: 'fees',
    name: 'Manager Fees',
    icon: <FaFileInvoiceDollar />,
  },
  {
    id: 'carry',
    name: 'Manager Carry',
    icon: <FaFileInvoiceDollar />,
  },
  {
    id: 'pricing',
    name: 'Investment Minimums',
    icon: <FaDollarSign />,
  },
  {
    id: 'closing',
    name: 'Investment Dates',
    icon: <EventIcon />,
  },
  {
    id: 'banking',
    name: 'Banking',
    icon: <AttachMoneyIcon />,
  },
  {
    id: 'review',
    name: 'Review',
    icon: <FaCheckDouble />,
  },
  {
    id: 'taxDocuments',
    name: 'Offering Authorization',
    icon: <HiOutlineReceiptTax />,
  },
  {
    id: 'agreements',
    name: 'Agreements',
    icon: <FaBriefcase />,
  },
];
function deepOmit(obj) {
  function omitFromObject(obj) {
    // the inner function which will be called recursivley
    return transform(obj, function (result, value, key) {
      // transform to a new object
      if (isNil(value)) return;

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
}

const SyndicateOfferingFlow = ({
  syndicate,
  setSyndicate,
  investment,
  setInvestment,
  opportunityId,
  onClose,
  onComplete,
  onBack,
  defaultStep,
}) => {
  const {
    business: defaultAddress,
    groupId,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeePercent,
  } = useSelectedGroup((state) => ({
    business: state.business,
    groupId: state.id,
    smartcapitalFeeBase: state.smartcapitalFeeBase,
    smartcapitalFeePerMember: state.smartcapitalFeePerMember,
    smartcapitalFeePercent: state.smartcapitalFeePercent,
  }));

  const [data, setData] = useState();

  const [isReviewing, setReviewing] = useState(false);
  const [step, setStep] = useState(
    isReviewing && !defaultStep ? 'review' : defaultStep || 'fees',
  );
  const [steps, setSteps] = useState(defaultSteps);

  useEffect(() => {
    if (step == 'review') {
      setReviewing(true);
    }
  }, [step]);
  const goStep = async (step, val, isBack) => {
    if (val) {
      const temp = deepOmit(val);
      setData((cache) => ({ ...cache, ...temp, lastOfferingStep: step }));
    }
    setStep((prevState) => {
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(prevState);
      if (!isBack && prevIndex !== -1) {
        updatedSteps[prevIndex] = {
          ...updatedSteps[prevIndex],
          completed: true,
        };
      }
      setSteps(updatedSteps);
      return isReviewing ? 'review' : step;
    });
  };
  const nextStep = (step) => {
    return async (val) => {
      console.log('nextStep', step, val);
      await goStep(step, val);
    };
  };
  const prevStep = (step) => {
    return async (val) => {
      await goStep(step, val, true);
    };
  };

  useEffect(() => {
    if (data) {
      return;
    }
    if (syndicate) {
      const {
        id,
        dateFiledOfferingAuthorization,
        dateFiledReviewed,
        dateFiledFormationService,
        dateFiledFormationAuthorization,
        dateFiledOfferingAgreements,
        dateFiledOfferingReview,
        lastOfferingStep,
        primaryManager,
        business,
        blueSky,

        managementFeeBase,
        managementFeePerMember,
        managementFeePercent,
        carryAmount,
        unitPrice,
        minimumUnits,
        initialClosing,
        finalClosing,
        syndicateName,
        registeredState,
      } = syndicate;
      setData({
        registeredState,
        id,
        dateFiledOfferingAuthorization,
        dateFiledReviewed,
        dateFiledFormationService,
        dateFiledFormationAuthorization,
        dateFiledOfferingAgreements,
        dateFiledOfferingReview,
        lastOfferingStep,
        primaryManager: primaryManager ? primaryManager.groupManager : null,
        business,
        blueSky,
        shouldFile: blueSky && blueSky.length > 0,

        managementFeeBase,
        managementFeePerMember,
        managementFeePercent,
        carryAmount,
        unitPrice,
        minimumUnits,
        initialClosing,
        finalClosing,
        syndicateName,

        // useSmartCapitalAccount,
      });
      let lastStep = lastOfferingStep || 'fees';
      if (!dateFiledOfferingReview) {
        setStep(lastStep);
      } else if (!dateFiledOfferingAuthorization) {
        setStep('taxDocuments');
        lastStep = 'agreements';
      } else if (!dateFiledOfferingAgreements) {
        setStep('agreements');
        lastStep = 'agreements';
      }
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(lastStep);
      for (let index = 0; index < prevIndex; index++) {
        updatedSteps[index] = {
          ...updatedSteps[index],
          completed: true,
        };
      }
      setSteps(updatedSteps);
    }
  }, [data, defaultAddress, opportunityId, steps, syndicate]);
  useEffect(() => {
    const { id } = syndicate || {};
    if (id && data && !isEqual(data, syndicate)) {
      const saveProgress = async () => {
        try {
          const response = await syndicatesClient.update({
            id,
            data: {
              ...data,
            },
          });
          setSyndicate(response.data);
        } catch (e) {
          console.log(getServerResponseErrors(e));
        }
      };
      saveProgress();
    }
  }, [data, setInvestment, setSyndicate, syndicate]);
  if (!data) {
    return <LoadingSpinner />;
  }
  const onSaveReview = async (onError) => {
    const { id: syndicateId } = syndicate || {};
    try {
      const response = await syndicatesClient.file_offering_review({
        id: syndicateId,
      });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const handleComplete = (...args) => {
    setSteps(defaultSteps);
    onComplete(...args);
  };
  const handleClose = (...args) => {
    setSteps(defaultSteps);
    onClose(...args);
  };

  let stepContent = <Box>Initial Step</Box>;
  switch (step) {
    case 'fees':
      stepContent = (
        <SyndicateFormFees
          {...{
            data,
            onNext: nextStep('carry'),
            smartcapitalFeeBase,
            smartcapitalFeePerMember,
            smartcapitalFeePercent,
            onClose: handleClose,
            groupId,
          }}
        />
      );
      break;
    case 'carry':
      stepContent = (
        <SyndicateFormCarry
          {...{
            data,
            onNext: nextStep('pricing'),
            smartcapitalFeeBase,
            smartcapitalFeePerMember,
            smartcapitalFeePercent,
            onBack: isReviewing ? goStep : prevStep('fees'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'pricing':
      stepContent = (
        <SyndicateFormUnitPricing
          {...{
            data,
            onNext: nextStep('closing'),

            onBack: isReviewing ? goStep : prevStep('carry'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'closing':
      stepContent = (
        <SyndicateFormClosingDates
          {...{
            data,
            onNext: nextStep('banking'),

            onBack: isReviewing ? goStep : prevStep('pricing'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'banking':
      stepContent = (
        <SyndicateFormBanking
          {...{
            investment,
            setInvestment,
            groupId,
            data: { ...data, id: syndicate.id },
            onNext: nextStep('review'),

            onBack: isReviewing ? goStep : prevStep('closing'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'review':
      stepContent = (
        <SyndicateFormReview
          {...{
            investment,
            data,
            setStep,
            onComplete: async () => {
              await onSaveReview().then(() => {
                setReviewing(false);
                nextStep('taxDocuments');
              });
            },
            onClose: handleClose,
            onBack,
            showFormationInfo: false,
            smartcapitalFeeBase,
            smartcapitalFeePerMember,
            smartcapitalFeePercent,
          }}
        />
      );
      break;
    case 'taxDocuments':
      stepContent = (
        <SyndicateFederalDocuments
          {...{
            syndicate,
            setSyndicate,
            showFiled: false,
          }}
        />
      );
      break;
    case 'agreements':
      stepContent = (
        <SyndicateSignatures
          {...{
            investment,
            syndicate,
            setSyndicate: handleComplete,
          }}
        />
      );
      break;

    default:
      break;
  }
  return (
    <>
      {step !== 'intro' && (
        <Header>
          <CustomizedSteppers
            steps={steps}
            activeStep={steps.map((x) => x.id).indexOf(step)}
          />
        </Header>
      )}
      {stepContent}
    </>
  );
};

export default SyndicateOfferingFlow;
