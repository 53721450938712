import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import imageAccredited from 'images/investor-accredited-1.svg';
import { useTheme } from '@mui/material';
import { Avatar, Chip } from '@mui/material';

const InvestorName = ({
  photo,
  initials,
  name,
  email,
  phone,
  isAdmin,
  isSuperadmin,
  isAccredited,
}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1} display="flex" alignItems="center">
      <Grid item>
        <Box style={{ position: 'relative' }}>
          {isAdmin || isSuperadmin ? (
            <Chip
              style={{
                position: 'absolute',
                margin: '0 auto',
                bottom: '-20%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                zIndex: 1,
                height: '0.75rem',
                fontSize: '0.5rem',
              }}
              color={isAdmin ? 'primary' : isSuperadmin ? 'secondary' : null}
              size="small"
              label={isAdmin ? 'Admin' : isSuperadmin ? 'Super' : null}
            />
          ) : null}
          <Avatar
            src={photo}
            style={{ width: theme.spacing(4), height: theme.spacing(4) }}
          >
            {initials}
          </Avatar>
        </Box>
      </Grid>

      <Grid item>
        <Box>
          {name != email && (
            <Typography>
              {name}
              {isAccredited && (
                <>
                  &nbsp;
                  <Tooltip title="Accredited">
                    <img
                      src={imageAccredited}
                      style={{ maxHeight: '0.75rem' }}
                      alt="Accredited"
                    />
                  </Tooltip>
                </>
              )}
              {!!phone && (
                <Typography
                  variant="caption"
                  sx={{ display: { xs: 'none', lg: 'block' } }}
                >
                  &nbsp;|&nbsp;{phone}
                </Typography>
              )}
            </Typography>
          )}
        </Box>
        <Typography style={{ maxWidth: '18rem' }} variant="subtitle2" noWrap>
          {email}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(InvestorName);
