import React, { useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { GiReceiveMoney, GiPayMoney } from 'react-icons/gi';

import { DialogWithActions } from 'components/ui/DialogWithTitle';

import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import { LoadingSpinner } from 'components/ui/Loading';
import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import Form from 'components/Form/Form';
import {
  CustomCurrencyInput,
  FormikCurrencyInput,
} from 'components/ui/CustomCurrencyInput';
import CustomizedSteppers from 'components/ui/CustomStepper';
import { FaBriefcase, FaEquals, FaMinus, FaPiggyBank } from 'react-icons/fa';
import useDataUserInvestments from 'views/UserInvestment/useDataUserInvestments';
import { FormFeesFields } from './SyndicateFormFees';
import { preciseCurrency } from './ReviewCards/formatterUtils';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';

const defaultSteps = [
  {
    id: 0,
    name: 'Closing',
    icon: <GiReceiveMoney />,
  },
  {
    id: 1,
    name: 'Fees',
    icon: <GiPayMoney />,
  },
  {
    id: 2,
    name: 'Investment',
    icon: <FaBriefcase />,
  },
  {
    id: 3,
    name: 'Cash',
    icon: <FaPiggyBank />,
  },
];

const CloseSyndicateButton = ({
  syndicate,
  id,
  setInvestment,
  setSyndicate,
}) => {
  const [modal, showModal] = useState(false);
  return (
    <>
      {modal && (
        <CloseWarning
          {...{
            syndicate,
            id,
            setInvestment,
            setSyndicate,
            onClose: () => showModal(false),
          }}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => showModal(true)}
      >
        Close Syndicate
      </Button>
    </>
  );
};

const CloseWarning = ({ syndicate, setInvestment, setSyndicate, onClose }) => {
  const [error, setError] = useState();
  const theme = useTheme();
  const { id, groupInvestment: investment, otherFee } = syndicate;
  const [syndicateFee, setSyndicateFee] = useState();
  const [investmentAmount, setInvestmentAmount] = useState();
  const [status, setStatus] = useState();

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([...defaultSteps]);
  const [checked, setChecked] = useState(false);
  const { userInvestments, isLoading } = useDataUserInvestments(
    investment,
    () => {},
    syndicate?.dateFiledOfferingAuthorization,
  );
  const { managementFeeBase, managementFeePerMember, managementFeePercent } =
    syndicate;
  const [managementFee, setManagementFee] = useState(
    managementFeeBase == 0 &&
      managementFeePerMember == 0 &&
      managementFeePercent == 0
      ? 'no'
      : managementFeeBase > 0 ||
        managementFeePerMember > 0 ||
        managementFeePercent > 0 ||
        managementFeeBase == null
      ? 'yes'
      : 'custom',
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.investmentStatus({
          id,
        });
        setStatus(response.data);
        setLoading(false);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, syndicate, syndicate.groupInvestment.Id]);
  const goStep = async (isBack) => {
    setStep((prevState) => {
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(prevState);
      if (!isBack) {
        updatedSteps[prevIndex] = {
          ...updatedSteps[prevIndex],
          completed: true,
        };
      }
      setSteps(updatedSteps);
      return prevState + (isBack ? -1 : 1);
    });
  };

  const nextStep = async () => {
    const { isValid, formErrors } = await validateForm(formRef);
    if (!isValid) {
      if (step === 0) {
        if (!formErrors.finalClosing) {
          setError();
          await goStep();
        } else {
          setError('Please correct the errors in the form above.');
        }
      } else if (step === 1) {
        if (
          !(
            formErrors.feeDate ||
            formErrors.managementFeeBase ||
            formErrors.managementFeePerMember ||
            formErrors.managementFeePercent
          )
        ) {
          setError();
          await goStep();
        } else {
          setError('Please correct the errors in the form above.');
        }
      } else if (step === 2) {
        if (!(formErrors.investmentAmount || formErrors.investmentDate)) {
          setError();
          await goStep();
        } else {
          setError('Please correct the errors in the form above.');
        }
      }
    } else {
      setError();
      await goStep();
    }
  };
  const prevStep = () => {
    goStep(true);
  };
  const accepted = userInvestments?.filter((x) => x.acceptedUnits > 0);
  const totalReceived = accepted?.reduce(
    (acc, x) =>
      acc +
      (parseFloat(x.amountReceived) > 0
        ? parseFloat(x.amountReceived) - parseFloat(x.refundAmount)
        : 0),
    0,
  );
  const { hasInvestments, canClose } = status || {};
  const [formRef, setFormRef] = useState({});
  useEffect(() => {
    const { values, touched, setFieldValue } = formRef.current || {};
    if (accepted?.length > 0) {
      const syndicateFee =
        parseFloat(values?.managementFeeBase || 0) +
        accepted.length * parseFloat(values?.managementFeePerMember || 0) +
        totalReceived * (parseFloat(values?.managementFeePercent || 0) / 100) +
        parseFloat(otherFee || 0);
      setSyndicateFee(syndicateFee);
      // If the invested amount is dirty, don't change it
      if (!touched?.investmentAmount && setFieldValue) {
        setFieldValue('investmentAmount', totalReceived - syndicateFee);
      }
    }
    if (step === 3) {
      setInvestmentAmount(values?.investmentAmount);
    }
  }, [accepted, formRef, otherFee, step, totalReceived]);
  const cashRemaining = totalReceived - syndicateFee - investmentAmount;
  const handleClose = async (onError, minDelay) => {
    try {
      const {
        values: {
          finalClosing,
          feeDate,
          investmentDate,
          managementFeeBase: feeBase,
          managementFeePerMember: feePerMember,
          managementFeePercent: feePercent,
        },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      // throw error if finalClosing is before feeDate or investmentDate
      // if (finalClosing > feeDate || finalClosing >= investmentDate) {
      //   onError('Final closing date must be after fee and investment dates.');
      //   return false;
      // }

      const response = await syndicatesClient.close({
        id,
        data: {
          managementFeeBase: feeBase,
          managementFeePerMember: feePerMember,
          managementFeePercent: feePercent,
          feeDate,
          finalClosing,
          investmentDate,
          investmentAmount,
          remainingAmount: cashRemaining,
        },
      });
      await minDelay;
      const { groupInvestment } = response.data;
      setInvestment(groupInvestment);
      setSyndicate(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
      const message = Array.isArray(error) ? error.join(', ') : error;
      setError(message);
      return false;
    }
  };
  const footer = (
    <Box padding={2}>
      {error && (
        <Box padding={3} textAlign="center">
          <Alert severity="error" onClose={() => setError(null)}>
            <div>There was an error: {error}</div>
          </Alert>
        </Box>
      )}
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid>
          <Button variant="contained" onClick={() => onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            onClick={prevStep}
            style={{ marginRight: '1rem' }}
            disabled={step === 0}
          >
            Back
          </Button>
          {step === 3 ? (
            <SaveFormButton
              onSave={handleClose}
              name="Close Syndicate"
              disabled={!canClose || cashRemaining < 0 || !checked}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={nextStep}
              disabled={step === 3 || loading || isLoading}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
  return (
    <DialogWithActions
      maxWidth="md"
      fullWidth
      {...{
        title: `Close Syndicate`,
        header: <CustomizedSteppers steps={steps} activeStep={step} />,
        footer,
        onClose,
      }}
    >
      {loading || isLoading ? (
        <>
          <LoadingSpinner />
        </>
      ) : hasInvestments && !canClose ? (
        <>
          <Box marginLeft={3}>
            <Grid container spacing={2}>
              <Grid>
                <Box fontSize={30} lineHeight={0}>
                  <WarningIcon fontSize="inherit" />
                </Box>
              </Grid>
              <Grid xs>
                <Typography>Warning</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box padding={3}>
            There are currently investments in process that have not been
            completed. To close the Syndicate, the investor or you must complete
            the investments, or you must void the investments.
          </Box>
        </>
      ) : hasInvestments && canClose ? (
        <Grid container spacing={3} alignItems="center">
          <Grid container>
            <Form initialValues={syndicate} setRef={setFormRef} validateOnBlur>
              <Grid
                container
                spacing={3}
                style={{ display: step === 0 ? '' : 'none' }}
              >
                <Grid xs={12}>
                  <Alert severity="info" variant="outlined">
                    Please enter the date of the "Final Close." This date
                    represents the last date the Syndicate would accept
                    investment from investors. You can enter this as the last
                    day that funds were collected from investors or the last day
                    that the Syndicate would accept funds from investors.
                  </Alert>
                </Grid>
                <Grid xs={3}>
                  <Box maxWidth="15rem">
                    <CustomCurrencyInput
                      variant="filled"
                      value={totalReceived}
                      label="Collected Amount"
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid xs={3}>
                  <Box maxWidth="15rem">
                    <FormikDatePicker
                      inputVariant="filled"
                      name="finalClosing"
                      label="Final Close Date"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ display: step === 1 ? '' : 'none' }}
              >
                <Grid xs={12}>
                  <Alert severity="info" variant="outlined">
                    Please input the fees that the Manager is charging the
                    Syndicate. As a reminder, these differ from SmartCapital
                    fees, which are charged directly to the Manager and not the
                    Syndicate. The Manager fees will be a one-time fee that the
                    Syndicate pays the Manager.
                  </Alert>
                </Grid>
                <Grid xs={12}>
                  <Box>
                    <FormFeesFields
                      managementFee={managementFee}
                      setManagementFee={setManagementFee}
                    />
                  </Box>
                </Grid>
                {managementFee && (
                  <Grid xs={12}>
                    <Box maxWidth="15rem">
                      <FormikDatePicker
                        inputVariant="filled"
                        name="feeDate"
                        label="Fee Paid Date"
                        required
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ display: step === 2 ? '' : 'none' }}
              >
                <Grid xs={12}>
                  <Alert severity="info" variant="outlined">
                    Please enter the investment amount sent to the company and
                    the date on which the investment was considered transacted.
                  </Alert>
                </Grid>
                <Grid>
                  <Box>
                    <FormikCurrencyInput
                      variant="filled"
                      name="investmentAmount"
                      label="Investment Amount"
                      required
                      inputProps={{
                        decimalScale: 2,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid>
                  <Box maxWidth="15rem">
                    <FormikDatePicker
                      inputVariant="filled"
                      name="investmentDate"
                      label="Date for Investment"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ display: step === 3 ? '' : 'none' }}
              >
                <Grid xs={12}>
                  <Box marginBottom={2}>
                    Based on your entries, the calculated remaining cash balance
                    in the Syndicate should be{' '}
                    <Box
                      component="span"
                      fontWeight="bold"
                      color={
                        parseFloat(cashRemaining) < 0
                          ? theme.palette.error.main
                          : 'inherit'
                      }
                    >
                      {preciseCurrency.format(cashRemaining)}
                    </Box>
                  </Box>
                  <Tooltip
                    title="If any numbers are incorrect, please correct them by hitting back in this guide. If you have questions, please reach out to your account manager with any questions before confirming."
                    arrow
                  >
                    <Alert
                      severity={
                        parseFloat(cashRemaining) < 0 ? 'error' : 'info'
                      }
                    >
                      <Box alignItems="center" display="flex">
                        <Box mx={0.5}>
                          {preciseCurrency.format(totalReceived)} total
                          collected
                        </Box>
                        <FaMinus />
                        <Box mx={0.5}>
                          {preciseCurrency.format(syndicateFee)} manager fees
                        </Box>
                        <FaMinus />
                        <Box mx={0.5}>
                          {preciseCurrency.format(investmentAmount)} investment
                        </Box>
                        <FaEquals />
                        <Box marginLeft={0.5} fontWeight="bold">
                          {preciseCurrency.format(cashRemaining)}
                        </Box>
                      </Box>
                    </Alert>
                  </Tooltip>
                  <Box>
                    <CustomCheckBox
                      name="confirmCashRemaining"
                      label={
                        <Box>
                          <Typography variant="subtitle1">
                            The amount collected, the management fee, the
                            investment fee, and the cash remaining is accurate
                            and complete.
                          </Typography>
                        </Box>
                      }
                      disabled={cashRemaining < 0}
                      checked={cashRemaining < 0 ? false : checked}
                      onChange={() =>
                        setChecked((x) => (x ? null : new Date()))
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box marginLeft={3}>
            <Grid container spacing={2}>
              <Grid>
                <Box fontSize={30} lineHeight={0}>
                  <WarningIcon fontSize="inherit" />
                </Box>
              </Grid>
              <Grid xs>
                <Typography>Warning</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box padding={3}>
            You are about to close the Syndicate without having completed any
            investments. Are you sure?
          </Box>
        </>
      )}
    </DialogWithActions>
  );
};

export default CloseSyndicateButton;
